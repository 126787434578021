import {
  Alert,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  removeWhitespaces,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
} from "../../../helpers/validationHelpers";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import PhoneNumberTextField from "../../../components/PhoneNumberTextField/PhoneNumberTextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewCompanyStaff } from "../../../hooks/useCompanyUsers";
import { addNewOrganizationAdmin } from "../../../hooks/useAdmins";
import OrganizationAutoComplete from "../../../components/OrganizationAutoComplete/OrganizationAutoComplete";

const AdminInviteModalWithOrganizationSelector = ({
  open,
  setOpen,
  accessToken,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accessToken: string;
}) => {
  const queryClient = useQueryClient();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("OPERATOR");
  const [submitErrors, setSubmitErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [organizationSearchText, setOrganizationSearchText] = useState("");
  const [organization, setOrganization] = useState<any>(null);

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    firstName,
    lastName,
    phoneNumber,
    email,
    role,
  });

  const formErrors = {
    organization: {
      empty: !organization?.id && "Please select an organization",
    },
    firstName: {
      empty:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      empty:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    phoneNumber: {
      empty:
        blurredFormFields?.phoneNumber &&
        !validatePresence(phoneNumber) &&
        "Please enter a phone number",
      invalid:
        blurredFormFields?.phoneNumber &&
        phoneNumber.length > 0 &&
        !validatePhoneNumber(phoneNumber) &&
        "Please enter a valid phone number",
    },
    email: {
      empty:
        blurredFormFields?.email &&
        !validatePresence(email) &&
        "Please enter an email",
      invalid:
        blurredFormFields?.email &&
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email",
    },
    role: {
      empty:
        blurredFormFields?.role &&
        !["OPERATOR", "SUPERADMIN"].includes(role) &&
        "Please select a user role",
    },
  };

  const firstNameErrors: any = filterObjectByValues(
    formErrors?.firstName,
    (key, value) => value
  );
  const lastNameErrors: any = filterObjectByValues(
    formErrors?.lastName,
    (key, value) => value
  );
  const phoneNumberErrors: any = filterObjectByValues(
    formErrors?.phoneNumber,
    (key, value) => value
  );

  const emailErrors: any = filterObjectByValues(
    formErrors?.email,
    (key, value) => value
  );

  const roleErrors: any = filterObjectByValues(
    formErrors?.role,
    (key, value) => value
  );

  const organizationErrors: any = filterObjectByValues(
    formErrors?.organization,
    (key, value) => value
  );

  const submitDisabled =
    !validatePresence(firstName) ||
    !validatePresence(lastName) ||
    !validatePresence(phoneNumber) ||
    !validatePhoneNumber(phoneNumber) ||
    !validatePresence(email) ||
    !validateEmail(email) ||
    !["OPERATOR", "SUPERADMIN"].includes(role);

  const resetFormFields = () => {
    resetBlurState();
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setRole("OPERATOR");
    setSubmitErrors("");
    setOrganization(null);
    setOrganizationSearchText("");
  };

  const { mutate: inviteNewAdmin, isLoading: isInviteNewUserLoading } =
    useMutation({
      mutationFn: addNewOrganizationAdmin,
      onSuccess: async (data: any) => {
        console.log("data after submit", data);
        await queryClient.cancelQueries({
          queryKey: ["admins"],
        });

        await queryClient.invalidateQueries({
          queryKey: ["admins"],
        });

        setOpen(false);
        // setAlert({
        //   type: "SUCCESS",
        //   message: "USER INVITE SENT",
        // });
        setSuccessMessage("Admin invite sent");
        resetFormFields();
      },
      onError: async (error: any) => {
        console.log("error", error);
        // setShowError(true);
        // setErrorMessage(error);
        setSubmitErrors(error);

        // setAlert({
        //   type: "ERROR",
        //   message: "FAILED TO INVITE USER",
        // });
      },
    });

  const handleSubmit = () => {
    setSubmitErrors("");
    inviteNewAdmin({
      accessToken,
      organizationId: organization?.id,
      firstName,
      lastName,
      email: removeWhitespaces(email),
      phoneNumber,
      role,
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
    // setSelectedCompanyVoucher(null);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitErrors)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
          setSubmitErrors("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitErrors("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitErrors}
        </Alert>
      </Snackbar>
      <Modal
        loading={isInviteNewUserLoading}
        open={open}
        onSubmit={handleSubmit}
        handleClose={handleClose}
        onCancel={handleClose}
        cancelButtonLabel="Cancel"
        submitButtonLabel="Confirm"
        title="Invite Admin"
        submitButtonDisabled={submitDisabled}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrganizationAutoComplete
              inputValue={organizationSearchText}
              setInputValue={setOrganizationSearchText}
              value={organization}
              setValue={setOrganization}
              accessToken={accessToken}
            />
            {mapObjectValues(organizationErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`admin-invite-organization-errors-${index}`}
                errorText={organizationErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={objectHasKeys(firstNameErrors)}
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("firstName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("firstName");
              }}
              required
              label="First Name"
              variant="outlined"
            />
            {mapObjectValues(firstNameErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`admin-invite-first-name-errors-${index}`}
                errorText={firstNameErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={objectHasKeys(lastNameErrors)}
              fullWidth
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("lastName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("lastName");
              }}
              required
              label="Last Name"
              variant="outlined"
            />
            {mapObjectValues(lastNameErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`admin-invite-last-name-errors-${index}`}
                errorText={lastNameErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <PhoneNumberTextField
              label="Phone Number"
              placeholder="Phone Number"
              error={objectHasKeys(phoneNumberErrors)}
              value={phoneNumber}
              setValue={setPhoneNumber}
              onFocus={() => {
                setFormFieldToFocused("phoneNumber");
              }}
              onBlur={() => {
                setFormFieldToBlurred("phoneNumber");
              }}
            />
            {mapObjectValues(phoneNumberErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`admin-invite-phone-number-errors-${index}`}
                errorText={phoneNumberErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={objectHasKeys(emailErrors)}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("email");
              }}
              onBlur={() => {
                setFormFieldToBlurred("email");
              }}
              required
              label="Email"
              variant="outlined"
            />
            {mapObjectValues(emailErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`admin-invite-emails-errors-${index}`}
                errorText={emailErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontSize={11}>Role</Typography>
              <Select
                error={objectHasKeys(roleErrors)}
                sx={{
                  "&.Mui-disabled": {
                    "&:before": {
                      borderBottomStyle: "solid",
                    },
                  },
                }}
                value={role}
                label="Role"
                onChange={(e: any) => {
                  setRole(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("role");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("role");
                }}
                variant="outlined"
              >
                <MenuItem value={"OPERATOR"}>Operator</MenuItem>
                <MenuItem value={"SUPERADMIN"}>Super Admin</MenuItem>
              </Select>
            </FormControl>
            {mapObjectValues(roleErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`admin-invite-role-errors-${index}`}
                errorText={roleErrors[key]}
              />
            ))}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AdminInviteModalWithOrganizationSelector;
