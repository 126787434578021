import { atom } from "recoil";

export const userAuthState = atom({
  key: "user-auth-state",
  default: {
    id: "",
    accessToken: "",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    authTime: "",
    expiresAt: null,
    phoneNumber: "",
    emailVerified: false,
    phoneNumberVerified: false,
    refreshToken: "",
    rememberMe: false,
    organization: null,
    organizationId: "",
    role: "",
  },
});

export const userAuthGatewayLoadingState = atom({
  key: "user-auth-gateway-loading",
  default: false,
});
