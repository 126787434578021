import { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import io, { ManagerOptions, Socket, SocketOptions } from "socket.io-client";

export const useSocket = (
  url?: string,
  options?: Partial<ManagerOptions & SocketOptions> | undefined
): {
  socket: Socket;
  isConnected: boolean;
  setIsConnected: Dispatch<SetStateAction<boolean>>;
  transport: string;
  setTransport: Dispatch<SetStateAction<string>>;
  resetConnectionState: () => void;
} => {
  const { current: socket } = useRef(io(url, options));
  const [isConnected, setIsConnected] = useState(false);
  const [transport, setTransport] = useState("N/A");

  const resetConnectionState = () => {
    setIsConnected(false);
    setTransport("N/A");
  };

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  return {
    socket,
    isConnected,
    setIsConnected,
    transport,
    setTransport,
    resetConnectionState,
  };
};
