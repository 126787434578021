import { GridSortModel } from "@mui/x-data-grid";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { convertDollarsToCents, getCsvFilename } from "../helpers/formatters";
import {
  CouponDeductionType,
  CouponDurationType,
  removeWhitespaces,
} from "../helpers/validationHelpers";
const getPromoCodes = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string,
  organizationId?: string
) => {
  try {
    if (accessToken) {
      console.log("sort", sort);
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/promo-codes?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}${
          organizationId ? `&organizationId=${organizationId}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getPromoCodeDetails = async (
  accessToken: string,
  promoCodeId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/promo-codes/${promoCodeId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const formatPromoCodeSubmissionData = ({
  organizationId,
  promoCodeId,
  displayCode,
  amount,
  couponDeductionType,
  couponDurationType,
  valid,
  maxRedemptionValue,
  maxTotalRedemptions,
  expirationDate,
}: {
  organizationId?: string;
  promoCodeId?: string;
  displayCode: string;
  amount: number;
  couponDeductionType: CouponDeductionType;
  couponDurationType: CouponDurationType;
  expirationDate?: Date | null;
  maxTotalRedemptions?: number | null;
  maxRedemptionValue?: number | null;
  valid: boolean;
}) => {
  return {
    organizationId,
    promoCodeId,
    displayCode: removeWhitespaces(displayCode.toUpperCase()),
    amount:
      couponDeductionType === CouponDeductionType.amountOffInCents
        ? amount && convertDollarsToCents(amount)
        : amount,
    couponDeductionType,
    couponDurationType,
    valid,
    maxRedemptionValue:
      couponDeductionType === CouponDeductionType.percentOff
        ? maxRedemptionValue && convertDollarsToCents(maxRedemptionValue)
        : null,
    maxTotalRedemptions:
      couponDurationType === CouponDurationType.forever
        ? maxTotalRedemptions
        : null,
    expirationDate:
      couponDurationType === CouponDurationType.repeating
        ? expirationDate
        : null,
  };
};

const createNewPromoCode = async ({
  accessToken,
  organizationId,
  displayCode,
  couponDeductionType,
  amount,
  maxRedemptionValue,
  couponDurationType,
  maxTotalRedemptions,
  expirationDate,
  valid,
}: {
  accessToken: string;
  organizationId?: string;
  displayCode: string;
  amount: number;
  couponDeductionType: CouponDeductionType;
  couponDurationType: CouponDurationType;
  expirationDate?: Date | null;
  maxTotalRedemptions?: number | null;
  maxRedemptionValue?: number | null;
  valid: boolean;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/promo-codes/${organizationId}`,
      {
        displayCode,
        amount,
        couponDeductionType,
        couponDurationType,
        expirationDate,
        maxTotalRedemptions,
        maxRedemptionValue,
        valid,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const updateExistingPromoCode = async ({
  accessToken,
  promoCodeId,
  amount,
  couponDeductionType,
  couponDurationType,
  expirationDate,
  maxTotalRedemptions,
  maxRedemptionValue,
  valid,
}: {
  accessToken: string;
  promoCodeId?: string;
  amount: number;
  couponDeductionType: CouponDeductionType;
  couponDurationType: CouponDurationType;
  expirationDate?: Date | null;
  maxTotalRedemptions?: number | null;
  maxRedemptionValue?: number | null;
  valid: boolean;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/promo-codes/${promoCodeId}`,
      {
        amount,
        couponDeductionType,
        couponDurationType,
        expirationDate,
        maxTotalRedemptions,
        maxRedemptionValue,
        valid,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const getPromoCodesCSV = async (
  accessToken: string,
  organizationId?: string
) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/promo-codes-csv${
        organizationId ? `?organizationId=${organizationId}` : ""
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const usePromoCodes = ({
  accessToken,
  page = 0,
  search,
  sort,
  organizationId,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: [
      "promo-codes",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
      ...(organizationId ? [organizationId] : []),
    ],
    queryFn: () =>
      getPromoCodes(
        accessToken,
        page,
        search,
        sort && sort?.length > 0
          ? qs.stringify({ sortBy: sort[0] })
          : undefined,
        organizationId
      ),
    keepPreviousData: true,
  });
};

const usePromoCodeDetails = (accessToken: string, promoCodeId?: string) => {
  return useQuery({
    queryKey: ["promo-code", promoCodeId],
    queryFn: () => getPromoCodeDetails(accessToken, promoCodeId),
  });
};

export {
  getPromoCodes,
  usePromoCodes,
  usePromoCodeDetails,
  getPromoCodeDetails,
  getPromoCodesCSV,
  createNewPromoCode,
  updateExistingPromoCode,
  formatPromoCodeSubmissionData,
};
