import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { Grid, IconButton, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useState } from "react";
import {
  convertCentsToDollars,
  convertToKilometers,
} from "../../../../helpers/formatters";
import { useBillingSettings } from "../../../../hooks/useBillingSettings";

const OrganizationBillingSettingsTab = ({
  organizationId,
}: {
  organizationId?: string;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const { data: billingSettingsData } = useBillingSettings(
    authDetails?.accessToken
  );
  const [open, setOpen] = useState(false);

  const formatPriceField = (priceField: any) => {
    return priceField ? `$${convertCentsToDollars(priceField)}` : "N/A";
  };

  console.log("billingSettingsData", billingSettingsData);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <IconButton
        sx={{ position: "absolute", top: "24px", right: "16px" }}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditOutlined sx={{ color: "#28a1fa" }} />
      </IconButton>
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid container item spacing={1} xs={12}>
          {/* Heading */}
          <Grid item xs={12}>
            <Typography fontSize={21} fontWeight={500}>
              Billing Settings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={500}>
              Base settings:
            </Typography>
          </Grid>
          {/* Actual settings */}
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Inside/threshold delivery fee:{" "}
              {formatPriceField(
                billingSettingsData?.insideThresholdDeliveryFee
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Customer cancellation grace period:{" "}
              {billingSettingsData?.gracePeriodInMinutes} minutes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Maximum service call trip distance:{" "}
              {convertToKilometers(
                billingSettingsData?.maximumServiceCallTripDistanceInMeters
              )}{" "}
              KM
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Time after drop-off completion to auto-charge customer:{" "}
              {
                billingSettingsData?.minutesAfterDropOffCompletedToTriggerCustomerAutoCharge
              }{" "}
              minutes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={500}>
              Scheduled service call settings:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Time before scheduled service call to notify drivers:{" "}
              {
                billingSettingsData?.minutesBeforeScheduledServiceCallToNotifyDrivers
              }{" "}
              minutes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Time before scheduled service call to trigger trip auto-start:{" "}
              {
                billingSettingsData?.minutesBeforeScheduledServiceCallToTriggerAutoStart
              }{" "}
              minutes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Maximum days in advance to allow scheduled service calls:{" "}
              {
                billingSettingsData?.maximumDaysInAdvanceToAllowScheduledServiceCalls
              }{" "}
              days
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Minimum hours in advance to allow scheduled service calls:{" "}
              {
                billingSettingsData?.minimumHoursInAdvanceToAllowScheduledServiceCalls
              }{" "}
              hour(s)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Scheduled service call retry interval:{" "}
              {billingSettingsData?.scheduledServiceCallsRetryIntervalInMinutes}{" "}
              minutes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Time in advance to notify admins of potential SSC auto-cancel (due
              to no available drivers):{" "}
              {
                billingSettingsData?.hoursBeforeScheduledServiceCallRetryAttemptToNotifyAdmin
              }{" "}
              hours
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganizationBillingSettingsTab;
