import { Box, Grid, Hidden, Typography } from "@mui/material";
import LeftLoginSection from "../Login/LeftLoginSection/LeftLoginSection";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { userAuthGatewayLoadingState, userAuthState } from "../../atom";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
const ForgotPassword = () => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [authGatewayLoading, setAuthGatewayLoading] = useRecoilState(
    userAuthGatewayLoadingState
  );

  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard" || "/";
  useEffect(() => {
    if (authDetails?.accessToken) {
      console.log("from", from);
      navigate(from, { replace: true });
    }
  }, [authDetails, navigate, from]);

  return (
    <Grid container height="100vh">
      <LoadingOverlay visible={authGatewayLoading} />
      <LeftLoginSection />
      <ForgotPasswordForm />
    </Grid>
  );
};

export default ForgotPassword;
