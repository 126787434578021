import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminSuperAdmin,
} from "../../../helpers/authHelpers";
import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useCustomerDetails } from "../../../hooks/useCustomers";
import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import moment from "moment";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import { useState } from "react";
//   import ServiceCallsTab from "./ServiceCallsTab";
//   import CustomerProfileEditModal from "./CustomerProfileEditModal";
//   import PaymentsTab from "./PaymentsTab";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import {
  useOrganizationDetails,
  useOrganizations,
} from "../../../hooks/useOrganizations";
import OrganizationProfilePanel from "../OrganizationDetails/OrganizationProfilePanel";
import OrganizationProfileEditModal from "../OrganizationDetails/OrganizationProfileEditModal";
import OrganizationBillingSettingsTab from "../OrganizationDetails/OrganizationBillingSettingsTab/OrganizationBillingSettingsTab";
import OrganizationDriversTab from "../OrganizationDetails/OrganizationDriversTab/OrganizationDriversTab";
import OrganizationCustomersTab from "../OrganizationDetails/OrganizationCustomersTab/OrganizationCustomersTab";
import OrganizationServiceCallsTab from "../OrganizationDetails/OrganizationServiceCallsTab/OrganizationServiceCallsTab";
import OrganizationScheduledServiceCallsTab from "../OrganizationDetails/OrganizationScheduledServiceCallsTab/OrganizationScheduledServiceCallsTab";
import OrganizationAdminManagementTab from "../OrganizationDetails/OrganizationAdminManagementTab/OrganizationAdminManagementTab";
import VehicleClassesTab from "../OrganizationDetails/VehicleClassesTab/VehicleClassesTab";
import AdditionalChargesTab from "../OrganizationDetails/AdditionalChargesTab/AdditionalChargesTab";
import UserSelectableServicesTab from "../OrganizationDetails/UserSelectableServicesTab/UserSelectableServicesTab";
import PromoCodesTab from "../OrganizationDetails/PromoCodesTab/PromoCodesTab";

const CurrentOrganization = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: organizationData } = useOrganizationDetails({
    accessToken: authDetails.accessToken,
    organizationId: authDetails?.organizationId,
  });
  console.log("organizationData", organizationData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
  };

  const primarySectionFields = [
    {
      label: "Created At",
      value: moment(organizationData?.createdAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
    {
      label: "Updated At",
      value: moment(organizationData?.updatedAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const tabPages = [
    ...(isCurrentAdminSuperAdmin(authDetails)
      ? [
          <OrganizationBillingSettingsTab
            organizationId={authDetails?.organizationId}
          />,
          <VehicleClassesTab
            organizationId={authDetails?.organizationId}
            isRootOrg={organizationData?.isRootOrganization}
          />,
          <AdditionalChargesTab organizationId={authDetails?.organizationId} />,
          <UserSelectableServicesTab
            organizationId={authDetails?.organizationId}
          />,
          <PromoCodesTab organizationId={authDetails?.organizationId} />,
        ]
      : []),
    <OrganizationDriversTab organizationId={authDetails?.organizationId} />,
    <OrganizationCustomersTab organizationId={authDetails?.organizationId} />,
    <OrganizationServiceCallsTab
      organizationId={authDetails?.organizationId}
    />,
    <OrganizationScheduledServiceCallsTab
      organizationId={authDetails?.organizationId}
    />,
    <OrganizationAdminManagementTab
      organizationId={authDetails?.organizationId}
    />,
  ];

  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: `/dashboard/current-organization`,
            title: `${organizationData?.name}`,
          },
        ]}
        pageTitle={`Organization`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/organizations"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        <OrganizationProfileEditModal
          accessToken={authDetails?.accessToken}
          organizationId={organizationData?.id}
          open={open}
          setOpen={setOpen}
          organizationName={organizationData?.name}
          organizationLogo={organizationData?.logo}
        />
        <OrganizationProfilePanel
          name={organizationData?.name}
          defaultOrganizationLocationSlug={
            organizationData?.organizationLocations[0].slug
          }
          organizationId={organizationData?.id}
          organizationLogo={organizationData?.logo}
          onEditClick={() => {
            setOpen(true);
          }}
          withEdit={isCurrentAdminSuperAdmin(authDetails)}
        >
          <Grid container spacing={2} direction={"column"}>
            {primarySectionFields.map((field) => (
              <ProfileListItem label={field.label} value={field.value} />
            ))}
          </Grid>
        </OrganizationProfilePanel>
        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={[
                ...(isCurrentAdminSuperAdmin(authDetails)
                  ? [
                      "Billing Settings",
                      "Vehicle Classes",
                      "Mandatory Service Charges",
                      "Optional Service Charges",
                      "Promo Codes",
                    ]
                  : []),
                "Drivers",
                "Customers",
                "Service Calls",
                "Scheduled Service Calls",
                "Team Members",
              ]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CurrentOrganization;
