import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
// import VirtualFleet from "../../assets/images/virtualfleet-logo-transparent-black.png";
import VirtualFleetHub from "../../assets/images/virtualfleethub-logo.png";
import { MailOutlined, LockOutlined, ChevronLeft } from "@mui/icons-material";
import { useState } from "react";
import {
  isAnyKeyValueTrue,
  validateEmail,
  validatePassword,
} from "../../helpers/validationHelpers";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { adminSendPasswordResetEmail } from "../../hooks/useAdminUser";
const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");

  const errors = {
    email: {
      empty: !email && "Please enter an email",
      invalidEmail:
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email address",
    },
  };

  const displaySuccessMessage = () => {
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 6000);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // navigate("/dashboard");
      const data = await adminSendPasswordResetEmail(email);

      console.log("data", data);
      displaySuccessMessage();
    } catch (error: any) {
      console.log("error", error);
      setError(error ?? "No user with that email found");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  const buttonDisabled = Boolean(isAnyKeyValueTrue(errors.email));
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      item
      xs={12}
      md={5}
      sx={{ background: "#fff" }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setOpenSnackbar(false);
          setError("");
        }}
        message={error}
        action={
          <Box
            onClick={() => {
              setOpenSnackbar(false);
              setError("");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: "#f26822" }}>Dismiss</Typography>
          </Box>
        }
      />
      <Grid item mt={"13vh"} mb={"14vh"}>
        <img
          src={VirtualFleetHub}
          style={{ maxHeight: "100%", height: "auto", maxWidth: 380 }}
        />
      </Grid>
      <Grid
        maxWidth={380}
        container
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item mb={4}>
          <Typography variant="h2" fontSize={36} fontWeight={700}>
            Forgot password?
          </Typography>
          <Typography color={"#868686"} fontSize={16} lineHeight={"28px"}>
            Fill the form to reset your password
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={3}
        >
          <Grid item width={"100%"}>
            <TextField
              fullWidth
              value={email}
              onFocus={() => {
                setEmailBlurred(false);
              }}
              onBlur={(e) => {
                setEmailBlurred(true);
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
              label="Email Address"
              helperText={emailBlurred && isAnyKeyValueTrue(errors.email)}
              error={emailBlurred && Boolean(isAnyKeyValueTrue(errors.email))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item width="100%">
            <Button
              disabled={buttonDisabled}
              loading={loading}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
          {success && (
            <Grid item width={"100%"}>
              <Alert severity="success">
                Password reset email sent. Please check your inbox for further
                instructions.
              </Alert>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          mt={3}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/login");
          }}
        >
          <ChevronLeft /> <Typography fontSize={14}>Return to Login</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordForm;
