import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { convertCentsToDollars } from "../../../../../helpers/formatters";

const UserSelectableServicesListitem = ({
  userSelectableServiceId,
  price,
  name,
  description,
  enabled,
  onEditClick,
  onDeleteClick,
}: {
  userSelectableServiceId: string;
  price: number;
  name: string;
  description: string;
  enabled: boolean;
  onEditClick: (userSelectableService: any) => void;
  onDeleteClick: (userSelectableService: any) => void;
}) => {
  return (
    <ListItem divider>
      <ListItemText
        primary={
          <Typography>
            {name}{" "}
            {enabled && (
              <Typography
                component="span"
                sx={{
                  marginLeft: 1,
                  backgroundColor: "green",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
                fontSize={10}
              >
                Enabled
              </Typography>
            )}
          </Typography>
        }
        secondary={
          <Grid container item>
            <Grid item xs={12}>
              <Typography>Price: ${convertCentsToDollars(price)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{description}</Typography>
            </Grid>
          </Grid>
        }
      />
      <ListItemSecondaryAction>
        <Grid container item spacing={1}>
          <Grid item>
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default UserSelectableServicesListitem;
