import { useRecoilState } from "recoil";
import { userAuthState } from "../atom";
import axios from "axios";

export const useAuth = () => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const getToken = async () => {
    const data = localStorage.getItem("userData");

    if (data) {
      return JSON.parse(data);
    }
  };

  const resetAuthState = () => {
    setAuthDetails({
      id: "",
      accessToken: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      authTime: "",
      expiresAt: null,
      phoneNumber: "",
      emailVerified: false,
      phoneNumberVerified: false,
      refreshToken: "",
      rememberMe: false,
      organization: null,
      organizationId: "",
      role: "",
    });
  };

  const login = async ({
    emailOrPhoneNumber,
    password,
  }: {
    emailOrPhoneNumber: string;
    password: string;
  }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
        {
          emailOrPhoneNumber,
          password,
        }
      );

      return data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  };

  const validateToken = async () => {
    try {
      const localToken = await getToken();

      const { accessToken } = localToken;

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("token data", data);
      //   just keep using the same token as before

      return {
        ...localToken,
        ...data,
      };
    } catch (error: any) {
      throw error?.response?.data;
    }
  };

  const logout = async () => {
    const localToken = await getToken();

    // if they're logging out from an active session
    // try to set them offline and clear their push token
    if (localToken && localToken?.accessToken) {
      try {
        const { accessToken } = localToken;

        // await driverSetOffline(accessToken);

        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/clear-token`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } catch (error) {
        // this will execute if they were in an active session but their token expired
        localStorage.removeItem("userData");
      }
    }
    localStorage.removeItem("userData");
  };

  const logoutAndResetAuthState = async () => {
    await logout();
    resetAuthState();
  };

  const clearUserCacheAndResetAuthState = async () => {
    localStorage.removeItem("userData");
    resetAuthState();
  };

  const setToken = async (tokenData: any, rememberMe = false) => {
    const dataToSet = {
      ...tokenData,
      rememberMe,
    };

    const data = localStorage.setItem("userData", JSON.stringify(dataToSet));

    return dataToSet;
  };

  const parseLoginData = (tokenData: any) => {
    // const dataToSet = {
    //   accessToken: accessToken?.jwtToken,
    //   idToken: idToken?.jwtToken,
    //   authTime: accessToken?.payload?.auth_time,
    //   username: idToken?.payload["cognito:username"],
    //   email: idToken?.payload?.email,
    //   cognitoId: idToken?.payload?.sub,
    //   expire: accessToken?.payload?.exp,
    //   phoneNumber: idToken?.payload?.phone_number,
    //   emailVerified: idToken?.payload?.email_verified,
    //   phoneNumberVerified: idToken?.payload?.phone_number_verified,
    //   refreshToken: refreshToken?.token,
    // };

    return tokenData;
  };

  const updateToken = async (dataToSet: any) => {
    const localToken = await getToken();
    const data = localStorage.setItem(
      "userData",
      JSON.stringify({ ...localToken, dataToSet })
    );
    return data;
  };

  const setRememberMe = async (rememberMe: boolean) => {
    const localToken = await getToken();
    const data = localStorage.setItem(
      "userData",
      JSON.stringify({ ...localToken, rememberMe })
    );

    setAuthDetails((prev) => ({ ...prev, rememberMe }));

    return {
      ...localToken,
      rememberMe,
    };
  };

  const refreshToken = async () => {
    const localToken = localStorage.getItem("userData");

    if (localToken) {
      console.log("refreshing token...", new Date());
      const { username, refreshToken } = JSON.parse(localToken);

      //   hit refresh endpoint here
      try {
        console.log("attempting refresh");
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/refresh`,
          {
            refreshToken: refreshToken,
          }
        );

        return data;
      } catch (error: any) {
        throw error?.response?.data;
      }
    }
  };

  const isExpired = (expireTime: number) => {
    return expireTime < Math.floor(new Date().getTime() / 1000);
  };

  return {
    getToken,
    logout,
    login,
    setToken,
    setRememberMe,
    validateToken,
    isExpired,
    refreshToken,
    resetAuthState,
    parseLoginData,
    logoutAndResetAuthState,
    clearUserCacheAndResetAuthState,
  };
};
