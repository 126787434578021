import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getCsvFilename } from "../helpers/formatters";
import qs from "qs";

const getAdminDashboardStats = async ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/dashboard-stats${
          organizationId ? `?organizationId=${organizationId}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getCumulativeOverviewXlsx = async ({
  accessToken,
  organizationId,
  resources,
}: {
  accessToken: string;
  organizationId?: string;
  resources: {
    admins?: boolean;
    organizations?: boolean;
    companies?: boolean;
    pickUpRequestSubmissionAttempts?: boolean;
    customers?: boolean;
    drivers?: boolean;
    pickUpRequests?: boolean;
    scheduledPickUpRequests?: boolean;
    upcomingScheduledPickUpRequests?: boolean;
  };
}) => {
  try {
    const resourceArray = Object.keys(resources).filter(
      (k: any) => resources[k as keyof typeof resources]
    );

    const params = qs.stringify({
      organizationId,
      resources: resourceArray,
    });

    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/cumulative-overview-xlsx?${params}`,
      {
        responseType: "arraybuffer",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log(
      `${process.env.REACT_APP_BACKEND_URL}/admin/cumulative-overview-xlsx?${params}`
    );

    const filename = getCsvFilename(headers);

    return { data, filename };
  } catch (error: any) {
    console.log("error getting cumulative overview", error?.response?.data);
  }
};

const useAdminDashboardStats = ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: [
      "admin-dashboard-stats",
      ...(organizationId ? [organizationId] : []),
    ],
    queryFn: () => getAdminDashboardStats({ accessToken, organizationId }),
  });
};

export {
  getAdminDashboardStats,
  useAdminDashboardStats,
  getCumulativeOverviewXlsx,
};
