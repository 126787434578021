import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import moment from "moment";
import { usePromoCodeDetails } from "../../../hooks/usePromoCodes";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import PromoCodeDetailsPanel from "./PromoCodeDetailsPanel";
import { isCurrentAdminSuperAdmin } from "../../../helpers/authHelpers";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import PromoCodeEditModal from "./PromoCodeEditModal";
import {
  CouponDeductionType,
  mappedCouponDeductionTypes,
  mappedCouponDurationTypes,
} from "../../../helpers/validationHelpers";
import { convertCentsToDollars } from "../../../helpers/formatters";

const PromoCodeDetails = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { promoCodeId } = useParams();

  // const accessToken = getAccessToken(user);
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: promoCodeData } = usePromoCodeDetails(
    authDetails?.accessToken,
    promoCodeId
  );

  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
  };

  console.log("promoCodeData", promoCodeData);

  const primarySectionFields = [
    {
      label: "Amount",
      value: promoCodeData?.amount,
    },
    {
      label: "Deduction Type",
      value:
        promoCodeData?.couponDeductionType &&
        mappedCouponDeductionTypes[promoCodeData?.couponDeductionType]
          ? mappedCouponDeductionTypes[promoCodeData?.couponDeductionType]
          : promoCodeData?.couponDeductionType,
    },
    {
      label: "Duration Type",
      value:
        promoCodeData?.couponDurationType &&
        mappedCouponDurationTypes[promoCodeData?.couponDurationType]
          ? mappedCouponDurationTypes[promoCodeData?.couponDurationType]
          : promoCodeData?.couponDurationType,
    },
    {
      label: "Expiration Date",
      value: promoCodeData?.expirationDate
        ? moment(promoCodeData?.expirationDate).format("Do MMMM  YYYY")
        : "N/A",
    },
    {
      label: "Max # of redemptions",
      value: promoCodeData?.maxTotalRedemptions ?? "Unlimited",
    },
    {
      label: "Max redemption value",
      value: promoCodeData?.maxRedemptionValue ?? "Unlimited",
    },
    {
      label: "Valid",
      value: promoCodeData?.valid ? "True" : "False",
    },
    {
      label: "Created At",
      value: moment(promoCodeData?.createdAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
    {
      label: "Updated At",
      value: moment(promoCodeData?.updatedAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const placeholderTabPage = <Box p={4}>Under construction</Box>;

  const tabPages = [placeholderTabPage];

  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/promo-codes",
            title: "Promo Codes",
          },
          {
            url: `/dashboard/promo-codes/${promoCodeId}`,
            title: `${promoCodeData?.displayCode}`,
          },
        ]}
        pageTitle={`Promo Codes`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/promo-codes"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        {/* <AdminEditProfileModal
          accessToken={authDetails?.accessToken}
          adminId={adminData?.id}
          adminFirstName={adminData?.firstName}
          adminLastName={adminData?.lastName}
          adminEmail={adminData?.email}
          adminPhoneNumber={adminData?.phoneNumber}
          adminRole={adminData?.role}
          open={open}
          setOpen={setOpen}
        /> */}
        <PromoCodeEditModal
          accessToken={authDetails.accessToken}
          promoCodeId={promoCodeData?.id}
          promoCodeDisplayCode={promoCodeData?.displayCode}
          promoCodeAmount={promoCodeData?.amount}
          promoCodeCouponDeductionType={promoCodeData?.couponDeductionType}
          promoCodeCouponDurationType={promoCodeData?.couponDurationType}
          promoCodeMaxTotalRedemptions={promoCodeData?.maxTotalRedemptions}
          promoCodeMaxRedemptionValue={promoCodeData?.maxRedemptionValue}
          promoCodeExpirationDate={promoCodeData?.expirationDate}
          promoCodeValid={promoCodeData?.valid}
          open={open}
          setOpen={setOpen}
        />
        <PromoCodeDetailsPanel
          promoCodeId={promoCodeData?.id}
          displayCode={promoCodeData?.displayCode}
          onEditClick={() => {
            setOpen(true);
          }}
          withEdit={isCurrentAdminSuperAdmin(authDetails)}
        >
          <Grid container spacing={2} direction={"column"}>
            {primarySectionFields.map((field) => (
              <ProfileListItem label={field.label} value={field.value} />
            ))}
          </Grid>
        </PromoCodeDetailsPanel>
        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={["Actions"]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default PromoCodeDetails;
