import { Divider, Grid, List } from "@mui/material";
import UserSelectableServicesListitem from "./UserSelectableServicesListItem";

const UserSelectableServicesList = ({
  pickUpRequestUserSelectableServicesData,
  onItemEditClick,
  onItemDeleteClick,
}: {
  pickUpRequestUserSelectableServicesData: any;
  onItemEditClick: (userSelectableService: any) => void;
  onItemDeleteClick: (userSelectableService: any) => void;
}) => {
  return (
    <List>
      {pickUpRequestUserSelectableServicesData?.map(
        (userSelectableService: any, index: number) => (
          <UserSelectableServicesListitem
            key={`user-selectable-services-list-item-${userSelectableService?.id}-${index}`}
            userSelectableServiceId={userSelectableService?.id}
            name={userSelectableService?.name}
            price={userSelectableService?.priceInCents}
            description={userSelectableService?.description}
            enabled={userSelectableService?.enabled}
            onEditClick={() => {
              onItemEditClick(userSelectableService);
            }}
            onDeleteClick={() => {
              onItemDeleteClick(userSelectableService);
            }}
          />
        )
      )}
    </List>
  );
};

export default UserSelectableServicesList;
