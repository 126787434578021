import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { Grid, IconButton, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  convertCentsToDollars,
  convertToKilometers,
} from "../../../../helpers/formatters";
import { useBillingSettings } from "../../../../hooks/useBillingSettings";
import { isCurrentAdminSuperAdmin } from "../../../../helpers/authHelpers";
import Button from "../../../../components/Button/Button";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { usePickUpRequestUserSelectableServices } from "../../../../hooks/usePickUpRequestUserSelectableServices";
import UserSelectableServiceModal from "./UserSelectableServiceModal/UserSelectableServiceModal";
import UserSelectableServiceDeleteModal from "./UserSelectableServiceDeleteModal/UserSelectableServiceDeleteModal";
import UserSelectableServicesList from "./UserSelectableServicesList/UserSelectableServicesList";

const UserSelectableServicesTab = ({
  organizationId,
}: {
  organizationId?: string;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: userSelectableServicesData } =
    usePickUpRequestUserSelectableServices({
      accessToken: authDetails?.accessToken,
      organizationId,
    });

  const [
    selectedPickUpRequestUserSelectableService,
    setSelectedPickUpRequestUserSelectableService,
  ] = useState<any>(null);

  const [
    showPickUpRequestUserSelectableServiceModal,
    setShowPickUpRequestUserSelectableServiceModal,
  ] = useState(false);
  const [
    showAdditionalPickUpChargeDeleteModal,
    setShowAdditionalPickUpChargeDeleteModal,
  ] = useState(false);

  console.log("user selectable services", userSelectableServicesData);

  useEffect(() => {
    const handleDataChange = () => {
      const isAdditionalChargePresentInData = userSelectableServicesData?.some(
        (additionalCharge: any) =>
          additionalCharge.id === selectedPickUpRequestUserSelectableService?.id
      );

      if (!isAdditionalChargePresentInData) {
        setShowPickUpRequestUserSelectableServiceModal(false);
        setShowAdditionalPickUpChargeDeleteModal(false);
        setSelectedPickUpRequestUserSelectableService(null);
      } else {
        const voucherInData = userSelectableServicesData?.find(
          (additionalCharge: any) =>
            additionalCharge.id ===
            selectedPickUpRequestUserSelectableService?.id
        );
        setSelectedPickUpRequestUserSelectableService(voucherInData);
      }
    };

    console.log(
      "selected charge changed",
      selectedPickUpRequestUserSelectableService
    );

    if (
      userSelectableServicesData &&
      userSelectableServicesData?.length &&
      selectedPickUpRequestUserSelectableService
    ) {
      handleDataChange();
    } else if (
      !userSelectableServicesData ||
      !userSelectableServicesData.length
    ) {
      setShowPickUpRequestUserSelectableServiceModal(false);
      setShowAdditionalPickUpChargeDeleteModal(false);
      setSelectedPickUpRequestUserSelectableService(null);
    }
  }, [userSelectableServicesData, selectedPickUpRequestUserSelectableService]);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid container item spacing={1} xs={12}>
          {/* Heading */}
          <Grid item xs={12}>
            <Typography fontSize={21} fontWeight={500}>
              Optional Service Charges
            </Typography>
          </Grid>

          {isCurrentAdminSuperAdmin(authDetails) && (
            <>
              <UserSelectableServiceDeleteModal
                accessToken={authDetails?.accessToken}
                open={showAdditionalPickUpChargeDeleteModal}
                setOpen={setShowAdditionalPickUpChargeDeleteModal}
                organizationId={organizationId}
                selectedPickUpRequestUserSelectableService={
                  selectedPickUpRequestUserSelectableService
                }
                setSelectedPickUpRequestUserSelectableService={
                  setSelectedPickUpRequestUserSelectableService
                }
              />
              <UserSelectableServiceModal
                accessToken={authDetails?.accessToken}
                open={showPickUpRequestUserSelectableServiceModal}
                editMode={Boolean(selectedPickUpRequestUserSelectableService)}
                setOpen={setShowPickUpRequestUserSelectableServiceModal}
                organizationId={organizationId}
                selectedPickUpRequestUserSelectableService={
                  selectedPickUpRequestUserSelectableService
                }
                setSelectedPickUpRequestUserSelectableService={
                  setSelectedPickUpRequestUserSelectableService
                }
              />
              <Grid container item justifyContent={"flex-end"}>
                <Grid item>
                  <Button
                    onClick={() => {
                      setShowPickUpRequestUserSelectableServiceModal(true);
                    }}
                    icon={<PriceChangeIcon sx={{ marginRight: 2 }} />}
                  >
                    Create new optional service charge
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <UserSelectableServicesList
              pickUpRequestUserSelectableServicesData={
                userSelectableServicesData
              }
              onItemEditClick={(additionalCharge: any) => {
                setSelectedPickUpRequestUserSelectableService(additionalCharge);
                setShowPickUpRequestUserSelectableServiceModal(true);
              }}
              onItemDeleteClick={(additionalCharge: any) => {
                setSelectedPickUpRequestUserSelectableService(additionalCharge);
                setShowAdditionalPickUpChargeDeleteModal(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserSelectableServicesTab;
