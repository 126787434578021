import {
  Alert,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
} from "../../../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import ErrorHelperText from "../../../../../components/ErrorHelperText/ErrorHelperText";
import {
  convertDollarsToCents,
  convertKilometersToMeters,
  convertToKilometers,
  roundToPrecision,
} from "../../../../../helpers/formatters";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createNewAdditionalPickUpRequestCharge,
  updateAdditionalPickUpRequestCharge,
} from "../../../../../hooks/useAdditionalPickUpRequestCharges";
import { updateVehicleClassMappings } from "../../../../../hooks/useVehicleClassMappings";
import ImageUploadSection from "../../../../../components/ImageUploadSection/ImageUploadSection";
import { uploadAssetToCloudinary } from "../../../../../utils/cloudinary";
import Button from "../../../../../components/Button/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
const VehicleClassMappingModal = ({
  open,
  setOpen,
  editMode = false,
  selectedVehicleClassMapping = null,
  setSelectedVehicleClassMapping,
  accessToken,
  organizationId,
}: {
  open: any;
  setOpen: any;
  editMode?: boolean;
  selectedVehicleClassMapping?: any;
  setSelectedVehicleClassMapping: any;
  organizationId?: string;
  accessToken: string;
}) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [rateInPercent, setRateInPercent] = useState(100);
  const [baseFeeInDollars, setBaseFeeInDollars] = useState(0);
  const [perKmRateInDollars, setPerKmRateInDollars] = useState(0);
  const [perMinuteRateInDollars, setPerMinuteRateInDollars] = useState(0);
  const [
    driverInitiatedCancellationChargePrePickupInDollars,
    setDriverInitiatedCancellationChargePrePickupInDollars,
  ] = useState(0);
  const [
    driverInitiatedCancellationChargePostPickupInDollars,
    setDriverInitiatedCancellationChargePostPickupInDollars,
  ] = useState(0);
  const [
    customerInitiatedCancellationChargePrePickupInDollars,
    setCustomerInitiatedCancellationChargePrePickupInDollars,
  ] = useState(0);
  const [
    customerInitiatedCancellationChargePostPickupInDollars,
    setCustomerInitiatedCancellationChargePostPickupInDollars,
  ] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [vehicleIconUpload, setVehicleIconUpload] = useState("");
  const [vehicleIconUploadLoading, setVehicleIconUploadLoading] =
    useState(false);
  // const [description, setDescription] = useState("");
  // const [enabled, setEnabled] = useState(false);
  //   const [priceInDollars, setPriceInDollars] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    name,
    //   description,
    rateInPercent,
    baseFeeInDollars,
    perKmRateInDollars,
    perMinuteRateInDollars,
    driverInitiatedCancellationChargePrePickupInDollars,
    driverInitiatedCancellationChargePostPickupInDollars,
    customerInitiatedCancellationChargePrePickupInDollars,
    customerInitiatedCancellationChargePostPickupInDollars,
  });

  useEffect(() => {
    if (editMode && selectedVehicleClassMapping) {
      setName(selectedVehicleClassMapping?.name);
      setBaseFeeInDollars(
        roundToPrecision(Number(selectedVehicleClassMapping?.baseFee) / 100, 2)
      );
      setRateInPercent(selectedVehicleClassMapping?.rateInPercent);
      setPerKmRateInDollars(
        roundToPrecision(
          Number(selectedVehicleClassMapping?.perKmRate) / 100,
          2
        )
      );
      setPerMinuteRateInDollars(
        roundToPrecision(
          Number(selectedVehicleClassMapping?.perMinuteRate) / 100,
          2
        )
      );
      setDriverInitiatedCancellationChargePrePickupInDollars(
        roundToPrecision(
          Number(
            selectedVehicleClassMapping?.driverInitiatedCancellationChargePrePickup
          ) / 100,
          2
        )
      );
      setDriverInitiatedCancellationChargePostPickupInDollars(
        roundToPrecision(
          Number(
            selectedVehicleClassMapping?.driverInitiatedCancellationChargePostPickup
          ) / 100,
          2
        )
      );
      setCustomerInitiatedCancellationChargePrePickupInDollars(
        roundToPrecision(
          Number(
            selectedVehicleClassMapping?.customerInitiatedCancellationChargePrePickup
          ) / 100,
          2
        )
      );
      setCustomerInitiatedCancellationChargePostPickupInDollars(
        roundToPrecision(
          Number(
            selectedVehicleClassMapping?.customerInitiatedCancellationChargePostPickup
          ) / 100,
          2
        )
      );
      setVehicleIconUpload(selectedVehicleClassMapping?.iconUrl);
      setEnabled(selectedVehicleClassMapping?.enabled);
      //   setEnabled(selectedAdditionalPickUpRequestCharge?.enabled);
    } else {
      resetFormFields();
    }
  }, [editMode, selectedVehicleClassMapping]);

  const formErrors: any = {
    name: {
      empty:
        blurredFormFields?.name &&
        !name &&
        "Please enter a name for this vehicle class",
    },
    // rateInPercent: {
    //   empty:
    //     blurredFormFields?.rateInPercent &&
    //     !rateInPercent &&
    //     "Please enter a rate percentage",
    //   lessThanOnePercent:
    //     blurredFormFields?.rateInPercent &&
    //     rateInPercent &&
    //     rateInPercent < 1 &&
    //     "Rate percentage must be at least 1 percent",
    // },
    // baseFeeInDollars: {
    //   empty:
    //     blurredFormFields?.baseFeeInDollars &&
    //     !baseFeeInDollars &&
    //     "Please enter a base fee for this vehicle class",
    //   lessThanOneDollar:
    //     blurredFormFields?.baseFeeInDollars &&
    //     baseFeeInDollars &&
    //     baseFeeInDollars < 1 &&
    //     "Base fee must be at least 1 dollar",
    // },
    // perKmRateInDollars: {
    //   empty:
    //     blurredFormFields?.perKmRateInDollars &&
    //     !perKmRateInDollars &&
    //     "Please enter a per KM rate for this vehicle class",
    //   lessThanOneCent:
    //     blurredFormFields?.perKmRateInDollars &&
    //     perKmRateInDollars &&
    //     perKmRateInDollars < 0.01 &&
    //     "Per KM rate must be at least 1 cent",
    // },
    // perMinuteRateInDollars: {
    //   empty:
    //     blurredFormFields?.perMinuteRateInDollars &&
    //     !perMinuteRateInDollars &&
    //     "Please enter a per minute rate for this vehicle class",
    //   lessThanOneCent:
    //     blurredFormFields?.perMinuteRateInDollars &&
    //     perMinuteRateInDollars &&
    //     perMinuteRateInDollars < 0.01 &&
    //     "Per minute rate be at least 1 cent",
    // },
    driverInitiatedCancellationChargePrePickupInDollars: {
      // empty:
      //   blurredFormFields?.driverInitiatedCancellationChargePrePickupInDollars &&
      //   !driverInitiatedCancellationChargePrePickupInDollars &&
      //   "Please enter a driver pre-pickup cancellation fee for this vehicle class",
      lessThanZero:
        blurredFormFields?.driverInitiatedCancellationChargePrePickupInDollars &&
        driverInitiatedCancellationChargePrePickupInDollars &&
        driverInitiatedCancellationChargePrePickupInDollars < 0 &&
        "Driver pre-pickup cancellation fee for this vehicle class must be a positive number",
    },
    driverInitiatedCancellationChargePostPickupInDollars: {
      // empty:
      //   blurredFormFields?.driverInitiatedCancellationChargePostPickupInDollars &&
      //   !driverInitiatedCancellationChargePostPickupInDollars &&
      //   "Please enter a driver post-pickup cancellation fee for this vehicle class",
      lessThanZero:
        blurredFormFields?.driverInitiatedCancellationChargePostPickupInDollars &&
        driverInitiatedCancellationChargePostPickupInDollars &&
        driverInitiatedCancellationChargePostPickupInDollars < 0 &&
        "Driver post-pickup cancellation fee for this vehicle class must be a positive number",
    },
    customerInitiatedCancellationChargePrePickupInDollars: {
      // empty:
      //   blurredFormFields?.customerInitiatedCancellationChargePrePickupInDollars &&
      //   !customerInitiatedCancellationChargePrePickupInDollars &&
      //   "Please enter a customer pre-pickup cancellation fee for this vehicle class",
      lessThanZero:
        blurredFormFields?.customerInitiatedCancellationChargePrePickupInDollars &&
        customerInitiatedCancellationChargePrePickupInDollars &&
        customerInitiatedCancellationChargePrePickupInDollars < 0 &&
        "Customer pre-pickup cancellation fee for this vehicle class must be a positive number",
    },
    customerInitiatedCancellationChargePostPickupInDollars: {
      // empty:
      //   blurredFormFields?.customerInitiatedCancellationChargePostPickupInDollars &&
      //   !customerInitiatedCancellationChargePostPickupInDollars &&
      //   "Please enter a customer post-pickup cancellation fee for this vehicle class",
      lessThanOneDollar:
        blurredFormFields?.customerInitiatedCancellationChargePostPickupInDollars &&
        customerInitiatedCancellationChargePostPickupInDollars &&
        customerInitiatedCancellationChargePostPickupInDollars < 0 &&
        "Customer post-pickup cancellation fee for this vehicle class must be a positive number",
    },
  };

  const nameErrors: any = filterObjectByValues(
    formErrors?.name,
    (key, value) => value
  );

  // const rateInPercentErrors: any = filterObjectByValues(
  //   formErrors?.rateInPercent,
  //   (key, value) => value
  // );

  // const baseFeeInDollarsErrors: any = filterObjectByValues(
  //   formErrors?.baseFeeInDollars,
  //   (key, value) => value
  // );
  // const perKmRateInDollarsErrors: any = filterObjectByValues(
  //   formErrors?.perKmRateInDollars,
  //   (key, value) => value
  // );
  // const perMinuteRateInDollarsErrors: any = filterObjectByValues(
  //   formErrors?.perMinuteRateInDollars,
  //   (key, value) => value
  // );
  const driverInitiatedCancellationChargePrePickupInDollarsErrors: any =
    filterObjectByValues(
      formErrors?.driverInitiatedCancellationChargePrePickupInDollars,
      (key, value) => value
    );
  const driverInitiatedCancellationChargePostPickupInDollarsErrors: any =
    filterObjectByValues(
      formErrors?.driverInitiatedCancellationChargePostPickupInDollars,
      (key, value) => value
    );
  const customerInitiatedCancellationChargePrePickupInDollarsErrors: any =
    filterObjectByValues(
      formErrors?.customerInitiatedCancellationChargePrePickupInDollars,
      (key, value) => value
    );
  const customerInitiatedCancellationChargePostPickupInDollarsErrors: any =
    filterObjectByValues(
      formErrors?.customerInitiatedCancellationChargePostPickupInDollars,
      (key, value) => value
    );

  const atLeastOneRateDefined = [
    baseFeeInDollars,
    perKmRateInDollars,
    perMinuteRateInDollars,
  ].some((value: any) => value >= 1);

  const notAtLeastOneRateDefinedError =
    !atLeastOneRateDefined &&
    [
      blurredFormFields?.baseFeeInDollars,
      blurredFormFields?.perKmRateInDollars,
      blurredFormFields?.perMinuteRateInDollars,
    ].every((blurred) => blurred) &&
    "At least one of these fields needs to have a value above 1 cent: Base Fee, Per KM rate, or Per minute rate";

  const submitButtonDisabled =
    !atLeastOneRateDefined ||
    Boolean(
      driverInitiatedCancellationChargePrePickupInDollars &&
        driverInitiatedCancellationChargePrePickupInDollars < 0
    ) ||
    Boolean(
      driverInitiatedCancellationChargePostPickupInDollars &&
        driverInitiatedCancellationChargePostPickupInDollars < 0
    ) ||
    Boolean(
      customerInitiatedCancellationChargePrePickupInDollars &&
        customerInitiatedCancellationChargePrePickupInDollars < 0
    ) ||
    Boolean(
      customerInitiatedCancellationChargePostPickupInDollars &&
        customerInitiatedCancellationChargePostPickupInDollars < 0
    );

  const handleNumberInputChange = (e: any, setValue: any) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setValue(e.target.value);
    }
  };

  const resetFormFields = () => {
    setName("");
    setRateInPercent(0);
    setBaseFeeInDollars(0);
    setPerKmRateInDollars(0);
    setPerMinuteRateInDollars(0);
    setDriverInitiatedCancellationChargePrePickupInDollars(0);
    setDriverInitiatedCancellationChargePostPickupInDollars(0);
    setCustomerInitiatedCancellationChargePrePickupInDollars(0);
    setCustomerInitiatedCancellationChargePostPickupInDollars(0);
    setSelectedVehicleClassMapping(null);
    setEnabled(false);
    resetBlurState();
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
  };

  const {
    mutate: handleAddNewAdditionalPickUpRequestCharge,
    isLoading: isAddNewAdditionalPickUpRequestChargeLoading,
  } = useMutation({
    mutationFn: createNewAdditionalPickUpRequestCharge,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage("New vehicle class successfully created");
    },
    onError: async (error: any) => {
      console.log("error adding new pickup request charge", error);
      setSubmitError(error);
    },
  });

  const {
    mutate: handleUpdateVehicleClassMapping,
    isLoading: isUpdateVehicleClassMappingLoading,
  } = useMutation({
    mutationFn: updateVehicleClassMappings,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["vehicle-class-mappings", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["vehicle-class-mappings", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["vehicle-class-mappings", organizationId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage("Vehicle class successfully updated");
    },
    onError: async (error: any) => {
      console.log("error adding updating pickup request charge", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    console.log("editMode", editMode);
    console.log("selectedVehicleClassMapping", selectedVehicleClassMapping);
    if (editMode && selectedVehicleClassMapping?.id) {
      // editMode logic
      handleUpdateVehicleClassMapping({
        accessToken,
        vehicleClassMappingId: selectedVehicleClassMapping?.id,
        formFields: {
          name,
          enabled,
          rateInPercent,
          iconUrl: vehicleIconUpload,
          baseFee: convertDollarsToCents(baseFeeInDollars),
          perKmRate: convertDollarsToCents(perKmRateInDollars),
          perMinuteRate: convertDollarsToCents(perMinuteRateInDollars),
          driverInitiatedCancellationChargePrePickup: convertDollarsToCents(
            driverInitiatedCancellationChargePrePickupInDollars
          ),
          driverInitiatedCancellationChargePostPickup: convertDollarsToCents(
            driverInitiatedCancellationChargePostPickupInDollars
          ),
          customerInitiatedCancellationChargePrePickup: convertDollarsToCents(
            customerInitiatedCancellationChargePrePickupInDollars
          ),
          customerInitiatedCancellationChargePostPickup: convertDollarsToCents(
            customerInitiatedCancellationChargePostPickupInDollars
          ),
        },
      });
    } else {
      // implement create handler if we get around to allowing that
      //   handleAddNewAdditionalPickUpRequestCharge({
      //     accessToken,
      //     organizationId,
      //     formFields: {
      //       name,
      //       description,
      //       enabled,
      //       priceInCents: convertDollarsToCents(priceInDollars),
      //     },
      //   });
    }
  };

  const handleVehicleIconUpload = async ({
    file,
    bucketName,
    setImage,
    setLoading,
  }: {
    file: any;
    bucketName: string;
    setImage: Dispatch<SetStateAction<string>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
  }) => {
    setLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, bucketName);
      setImage(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={
          isAddNewAdditionalPickUpRequestChargeLoading ||
          isUpdateVehicleClassMappingLoading
        }
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        submitButtonLabel={editMode ? "Update" : "Create"}
        title={editMode ? "Update vehicle class" : "Create vehicle class"}
        submitButtonDisabled={submitButtonDisabled}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(nameErrors)}
                fullWidth
                placeholder="Vehicle class name"
                label="Vehicle class name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("name");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("name");
                }}
                variant={"outlined"}
              />
              {mapObjectValues(nameErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`vehicle-class-change-name-errors-${index}`}
                  errorText={nameErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(notAtLeastOneRateDefinedError)}
                fullWidth
                placeholder="Base Fee"
                label="Base Fee"
                value={baseFeeInDollars}
                onFocus={() => {
                  setFormFieldToFocused("baseFeeInDollars");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setBaseFeeInDollars);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("baseFeeInDollars");
                  setBaseFeeInDollars((prev: any) => Number(prev));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                error={objectHasKeys(rateInPercentErrors)}
                fullWidth
                placeholder="Rate (In Percent)"
                label="Rate (In Percent)"
                value={rateInPercent}
                onFocus={() => {
                  setFormFieldToFocused("rateInPercent");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setRateInPercent);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("rateInPercent");
                  setRateInPercent((prev: any) => Number(prev));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(rateInPercentErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`vehicle-class-rate-in-percent-errors-${index}`}
                  errorText={rateInPercentErrors[key]}
                />
              ))}
            </Grid> */}
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={Boolean(notAtLeastOneRateDefinedError)}
                fullWidth
                placeholder="Per KM rate"
                label="Per KM rate"
                value={perKmRateInDollars}
                onFocus={() => {
                  setFormFieldToFocused("perKmRateInDollars");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setPerKmRateInDollars);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("perKmRateInDollars");
                  setPerKmRateInDollars((prev: any) => Number(prev));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {/* {mapObjectValues(
                perKmRateInDollarsErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`vehicle-class-per-km-rate-in-dollars-errors-${index}`}
                    errorText={perKmRateInDollarsErrors[key]}
                  />
                )
              )} */}
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(notAtLeastOneRateDefinedError)}
                fullWidth
                placeholder="Per minute rate"
                label="Per minute rate"
                value={perMinuteRateInDollars}
                onFocus={() => {
                  setFormFieldToFocused("perMinuteRateInDollars");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setPerMinuteRateInDollars);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("perMinuteRateInDollars");
                  setPerMinuteRateInDollars((prev: any) => Number(prev));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {/* {mapObjectValues(
                perMinuteRateInDollarsErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`vehicle-class-per-minute-rate-in-dollars-errors-${index}`}
                    errorText={perMinuteRateInDollarsErrors[key]}
                  />
                )
              )} */}
            </Grid>
          </Grid>
          {notAtLeastOneRateDefinedError && (
            <ErrorHelperText errorText={notAtLeastOneRateDefinedError} />
          )}
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(
                  driverInitiatedCancellationChargePrePickupInDollarsErrors
                )}
                fullWidth
                placeholder="Driver cancelled pre-pickup charge"
                label="Driver cancelled pre-pickup charge"
                value={driverInitiatedCancellationChargePrePickupInDollars}
                onFocus={() => {
                  setFormFieldToFocused(
                    "driverInitiatedCancellationChargePrePickupInDollars"
                  );
                }}
                onChange={(e) => {
                  handleNumberInputChange(
                    e,
                    setDriverInitiatedCancellationChargePrePickupInDollars
                  );
                }}
                onBlur={() => {
                  setFormFieldToBlurred(
                    "driverInitiatedCancellationChargePrePickupInDollars"
                  );
                  setDriverInitiatedCancellationChargePrePickupInDollars(
                    (prev: any) => Number(prev)
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(
                driverInitiatedCancellationChargePrePickupInDollarsErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`vehicle-class-driver-pre-pickup-cancel-in-dollars-errors-${index}`}
                    errorText={
                      driverInitiatedCancellationChargePrePickupInDollarsErrors[
                        key
                      ]
                    }
                  />
                )
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(
                  driverInitiatedCancellationChargePostPickupInDollarsErrors
                )}
                fullWidth
                placeholder="Driver cancelled post-pickup charge"
                label="Driver cancelled post-pickup charge"
                value={driverInitiatedCancellationChargePostPickupInDollars}
                onFocus={() => {
                  setFormFieldToFocused(
                    "driverInitiatedCancellationChargePostPickupInDollars"
                  );
                }}
                onChange={(e) => {
                  handleNumberInputChange(
                    e,
                    setDriverInitiatedCancellationChargePostPickupInDollars
                  );
                }}
                onBlur={() => {
                  setFormFieldToBlurred(
                    "driverInitiatedCancellationChargePostPickupInDollars"
                  );
                  setDriverInitiatedCancellationChargePostPickupInDollars(
                    (prev: any) => Number(prev)
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(
                driverInitiatedCancellationChargePostPickupInDollarsErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`vehicle-class-driver-post-pickup-cancel-in-dollars-errors-${index}`}
                    errorText={
                      driverInitiatedCancellationChargePostPickupInDollarsErrors[
                        key
                      ]
                    }
                  />
                )
              )}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(
                  customerInitiatedCancellationChargePrePickupInDollarsErrors
                )}
                fullWidth
                placeholder="Customer cancelled pre-pickup charge"
                label="Customer cancelled pre-pickup charge"
                value={customerInitiatedCancellationChargePrePickupInDollars}
                onFocus={() => {
                  setFormFieldToFocused(
                    "customerInitiatedCancellationChargePrePickupInDollars"
                  );
                }}
                onChange={(e) => {
                  handleNumberInputChange(
                    e,
                    setCustomerInitiatedCancellationChargePrePickupInDollars
                  );
                }}
                onBlur={() => {
                  setFormFieldToBlurred(
                    "customerInitiatedCancellationChargePrePickupInDollars"
                  );
                  setCustomerInitiatedCancellationChargePrePickupInDollars(
                    (prev: any) => Number(prev)
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(
                customerInitiatedCancellationChargePrePickupInDollarsErrors,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`vehicle-class-customer-pre-pickup-cancel-in-dollars-errors-${index}`}
                    errorText={
                      customerInitiatedCancellationChargePrePickupInDollarsErrors[
                        key
                      ]
                    }
                  />
                )
              )}
            </Grid>
            {/* This doesn't do anything right now so just get rid of it */}
            {/* <Grid item xs={6}>
              <TextField
                error={objectHasKeys(
                  customerInitiatedCancellationChargePostPickupInDollarsErrors
                )}
                fullWidth
                placeholder="Customer cancelled post-pickup charge"
                label="Customer cancelled post-pickup charge"
                value={customerInitiatedCancellationChargePostPickupInDollars}
                onFocus={() => {
                  setFormFieldToFocused(
                    "customerInitiatedCancellationChargePostPickupInDollars"
                  );
                }}
                onChange={(e) => {
                  handleNumberInputChange(
                    e,
                    setCustomerInitiatedCancellationChargePostPickupInDollars
                  );
                }}
                onBlur={() => {
                  setFormFieldToBlurred(
                    "customerInitiatedCancellationChargePostPickupInDollars"
                  );
                  setCustomerInitiatedCancellationChargePostPickupInDollars(
                    (prev: any) => Number(prev)
                  );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid> */}
          </Grid>
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Grid item>
              <Typography fontWeight={enabled ? 400 : 700}>Disabled</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={enabled}
                onChange={(event: any) => {
                  setEnabled(event.target.checked);
                }}
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={enabled ? 700 : 400}>Enabled</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item>
              <Typography>Vehicle Icon</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={12}>
                Please ensure you're using an icon with a transparent background
                for best results
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ImageUploadSection
                uploadedFile={vehicleIconUpload}
                loading={vehicleIconUploadLoading}
                label={"Vehicle icon"}
                handleUpload={(file: any) => {
                  handleVehicleIconUpload({
                    file,
                    bucketName: "vehicle_class_icons",
                    setImage: setVehicleIconUpload,
                    setLoading: setVehicleIconUploadLoading,
                  });
                }}
              />
            </Grid>
            {selectedVehicleClassMapping?.iconUrl && (
              <Grid item>
                <Button
                  onClick={() => {
                    setVehicleIconUpload("");
                  }}
                  icon={<RestartAltIcon sx={{ marginRight: 2 }} />}
                >
                  Reset icon to default
                </Button>
              </Grid>
            )}
            {mapObjectValues(
              customerInitiatedCancellationChargePostPickupInDollarsErrors,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`vehicle-class-customer-post-pickup-cancel-in-dollars-errors-${index}`}
                  errorText={
                    customerInitiatedCancellationChargePostPickupInDollarsErrors[
                      key
                    ]
                  }
                />
              )
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default VehicleClassMappingModal;
