import { Dispatch, SetStateAction } from "react";
import AdminInviteModal from "./AdminInviteModal";
import AdminInviteModalWithOrganizationSelector from "./AdminInviteModalWithOrganizationSelector";

const AddNewAdminModal = ({
  open,
  setOpen,
  organizationId,
  accessToken,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  organizationId?: string;
  accessToken: string;
}) => {
  return organizationId ? (
    <AdminInviteModal
      open={open}
      setOpen={setOpen}
      organizationId={organizationId}
      accessToken={accessToken}
    />
  ) : (
    <AdminInviteModalWithOrganizationSelector
      open={open}
      setOpen={setOpen}
      accessToken={accessToken}
    />
  );
};

export default AddNewAdminModal;
