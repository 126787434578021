import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminSuperAdmin,
} from "../../../helpers/authHelpers";
import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useCustomerDetails } from "../../../hooks/useCustomers";
import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import moment from "moment";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import { useState } from "react";
import ServiceCallsTab from "./ServiceCallsTab";
import CustomerProfileEditModal from "./CustomerProfileEditModal";
import PaymentsTab from "./PaymentsTab";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";

const CustomerProfile = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { userId } = useParams();

  // const accessToken = getAccessToken(user);
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  console.log("userId", userId);

  const { data: customerData } = useCustomerDetails(
    authDetails.accessToken,
    userId
  );
  console.log("customerData", customerData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
  };

  const primarySectionFields = [
    { label: "Email Address", value: customerData?.email },
    { label: "Phone No.", value: customerData?.phoneNumber },
    {
      label: "Created At",
      value: moment(customerData?.createdAt).format("Do MMMM  YYYY, h:mm:ss a"),
    },
    {
      label: "Updated At",
      value: moment(customerData?.updatedAt).format("Do MMMM  YYYY, h:mm:ss a"),
    },
  ];

  const placeholderTabPage = <Box>Tab page</Box>;

  const tabPages = [
    <ServiceCallsTab accessToken={authDetails?.accessToken} userId={userId} />,
    ...(isCurrentAdminSuperAdmin(authDetails)
      ? [<PaymentsTab accessToken={authDetails?.accessToken} userId={userId} />]
      : []),
  ];

  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/customers",
            title: "Customers",
          },
          {
            url: `/dashboard/customers/${userId}`,
            title: `${customerData?.firstName} ${customerData?.lastName}`,
          },
        ]}
        pageTitle={`Customers`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/customers"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        <CustomerProfileEditModal
          accessToken={authDetails?.accessToken}
          userId={userId}
          open={open}
          setOpen={setOpen}
          customerFirstName={customerData?.firstName}
          customerLastName={customerData?.lastName}
          customerEmail={customerData?.email}
          customerPhoneNumber={customerData?.phoneNumber}
        />
        <ProfilePanel
          accessToken={authDetails?.accessToken}
          firstName={customerData?.firstName}
          lastName={customerData?.lastName}
          avatar={customerData?.avatar}
          userId={customerData?.id}
          rating={customerData?.rating}
          isOnline={false}
          withEdit={isCurrentAdminSuperAdmin(authDetails)}
          onEditClick={() => {
            setOpen(true);
          }}
        >
          <Grid container spacing={2} direction={"column"}>
            {primarySectionFields.map((field) => (
              <ProfileListItem label={field.label} value={field.value} />
            ))}
          </Grid>
        </ProfilePanel>
        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={[
                "Service Calls",
                ...(isCurrentAdminSuperAdmin(authDetails) ? ["Payments"] : []),
              ]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CustomerProfile;
