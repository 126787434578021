import { Typography, Grid, Alert, Snackbar } from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { deletePickUpRequestUserSelectableService } from "../../../../../hooks/usePickUpRequestUserSelectableServices";

const UserSelectableServiceDeleteModal = ({
  accessToken,
  organizationId,
  open,
  setOpen,
  selectedPickUpRequestUserSelectableService,
  setSelectedPickUpRequestUserSelectableService,
}: {
  accessToken: string;
  organizationId?: string;
  selectedPickUpRequestUserSelectableService: any;
  setSelectedPickUpRequestUserSelectableService: any;
  open: boolean;
  setOpen: any;
}) => {
  const queryClient = useQueryClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const {
    mutate: handleDeletePickUpRequestUserSelectableService,
    isLoading: isDeletePickUpRequestUserSelectableServiceLoading,
  } = useMutation({
    mutationFn: deletePickUpRequestUserSelectableService,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });
      setOpen(false);
      setSelectedPickUpRequestUserSelectableService(null);
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    handleDeletePickUpRequestUserSelectableService({
      accessToken,
      userSelectableServiceId: selectedPickUpRequestUserSelectableService?.id,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPickUpRequestUserSelectableService(null);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Optional service charge service deleted
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={isDeletePickUpRequestUserSelectableServiceLoading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        title={"Confirm optional service charge service deletion"}
        submitButtonLabel="Delete"
      >
        <Grid item>
          <Typography>
            Are you sure you want to delete this optional service charge?{" "}
            <Typography fontWeight={700} component="span">
              This action is permanent and can result in data loss.
            </Typography>
          </Typography>
        </Grid>
      </Modal>
    </>
  );
};

export default UserSelectableServiceDeleteModal;
