import { Grid, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";

import { useDriverServiceCallHistory } from "../../../hooks/useDrivers";
import PaginationTable from "../../../components/PaginationTable/PaginationTable";
import moment from "moment";
import { roundToPrecision } from "../../../helpers/formatters";
import {
  PickUpStatus,
  cancelledStatuses,
  inProgressStatuses,
  pendingStatuses,
} from "../../../helpers/validationHelpers";
import { Link, useLocation } from "react-router-dom";
const ServiceCallsTab = ({
  accessToken,
  userId,
}: {
  accessToken?: string;
  userId?: string;
}) => {
  const location = useLocation();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const {
    data: serviceCallHistoryData,
    isLoading,
    isFetching,
  } = useDriverServiceCallHistory({
    accessToken,
    userId,
    page: paginationModel.page,
    sort: sortModel,
  });

  console.log("data", serviceCallHistoryData);

  const renderStatus = (pickUpStatus: PickUpStatus) => {
    if (cancelledStatuses.includes(pickUpStatus)) {
      return (
        <Typography sx={{ color: "#FD5554" }}>
          {pickUpStatus === PickUpStatus.scheduledDeclined
            ? "Declined"
            : "Cancelled"}
        </Typography>
      );
    } else if (pendingStatuses.includes(pickUpStatus)) {
      return <Typography sx={{ color: "#1162FB" }}>Pending</Typography>;
    } else if (inProgressStatuses.includes(pickUpStatus)) {
      return <Typography sx={{ color: "#1162FB" }}>In Progress</Typography>;
    } else {
      return <Typography sx={{ color: "#3fbf5d" }}>Completed</Typography>;
    }
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Service Call ID",
      width: 150,
      renderCell: (params) => (
        <Link
          to={
            params?.row?.scheduledPickUpRequest
              ? `/dashboard/scheduled-service-calls/${params.row.id}`
              : `/dashboard/service-calls/${params.row.id}`
          }
          state={{
            prevPage: location.pathname,
          }}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer",
      valueGetter: (params) =>
        params?.row?.customer
          ? `${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`
          : "N/A",
    },
    {
      field: "pickUpCode",
      headerName: "Pickup Code",
      width: 100,
      valueGetter: (params) => params.row.pickUpCode ?? "N/A",
    },
    {
      field: "distanceTravelledInMeters",
      headerName: "Total Dist.",
      // type: "number",
      // width: 200,
      valueGetter: (params) =>
        params.row.distanceTravelledInMeters
          ? roundToPrecision(params.row.distanceTravelledInMeters / 1000, 2)
          : "N/A",
    },
    // {
    //   field: "price",
    //   headerName: "Original fare",
    //   // width: 150,
    //   valueGetter: (params) =>
    //     roundToPrecision(
    //       (Number(params?.row?.price) + Number(params?.row?.taxAmountInCents)) /
    //         100,
    //       2
    //     ),
    // },
    // {
    //   field: "tipAmountInCents",
    //   headerName: "Tip Amount",
    //   // width: 150,
    //   valueGetter: (params) =>
    //     roundToPrecision(Number(params?.row?.tipAmountInCents) / 100, 2),
    // },
    // {
    //   field: "taxAmountInCents",
    //   headerName: "Tax Amount",
    //   // width: 150,
    //   valueGetter: (params) =>
    //     roundToPrecision(Number(params?.row?.taxAmountInCents) / 100, 2),
    // },
    {
      field: "totalFare",
      headerName: "Total Fare",
      valueGetter: (params) =>
        roundToPrecision(
          (params?.row?.price +
            params?.row?.taxAmountInCents +
            params?.row?.tipAmountInCents) /
            100,
          2
        ),
    },
    {
      field: "pickUpStatus",
      headerName: "Status",
      valueGetter: (params) => params.row.pickUpStatus,
      renderCell: (params) => renderStatus(params.row.pickUpStatus),
    },
  ];

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      <Grid item pb={2}>
        <Typography fontWeight={600} fontSize={16}>
          Service Calls
        </Typography>
      </Grid>
      <Grid container item>
        <PaginationTable
          columns={columns}
          loading={isLoading || isFetching}
          rows={serviceCallHistoryData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            setPaginationModel(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={serviceCallHistoryData?.totalCount}
          pageCount={serviceCallHistoryData?.totalPages}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceCallsTab;
