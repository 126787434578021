import { Autocomplete, TextField } from "@mui/material";
// import { useCompanyPickUpRequestVoucherTypes } from "../../../hooks/useCompanyPickUpRequestVoucherTypes";
import useDebounce from "../../hooks/useDebounce";
import { useOrganizations } from "../../hooks/useOrganizations";
// import {
//   mappedVehicleTypes,
//   toTitleCase,
// } from "../../../helpers/validationHelpers";
// import { convertToKilometers } from "../../../helpers/formatters";

const OrganizationAutoComplete = ({
  value,
  inputValue,
  setInputValue,
  setValue,
  accessToken,
  label = "Organization",
}: {
  value: any;
  inputValue: any;
  setInputValue: any;
  setValue: any;
  accessToken: string;
  label?: string;
}) => {
  const debouncedInputValue = useDebounce(inputValue, 1000);

  const { data: organizationsData, isLoading: isOrganizationsLoading } =
    useOrganizations({
      accessToken,
      search: debouncedInputValue,
      page: 0,
    });

  return (
    <Autocomplete
      getOptionLabel={(option) => `${option?.name} - orgID: ${option?.id}`}
      filterOptions={(x) => x}
      options={organizationsData?.data ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={label}
          fullWidth
          variant="outlined"
        />
      )}
      autoComplete
      value={value}
      noOptionsText="No matching organizations"
      onChange={(event: any, newValue: any) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        // console.log("newValue", newValue);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

export default OrganizationAutoComplete;
