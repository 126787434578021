import axios from "axios";

const addressSearch = async ({
  query,
  accessToken,
}: {
  query: string;
  accessToken: string;
}) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/address-search?query=${query}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log("error", error);
  }
};

export { addressSearch };
