import { Box, CircularProgress, Skeleton } from "@mui/material";
import MuveIt from "../../../assets/images/muveit.png";
import VirtualFleetHub from "../../../assets/images/virtualfleethub-logo.png";
import { useOrganizationDetails } from "../../../hooks/useOrganizations";

const LogoSection = ({
  accessToken,
  organizationId,
  isRootOrganization,
}: {
  accessToken: string;
  organizationId: string;
  isRootOrganization: boolean;
}) => {
  const { data: organizationData, isLoading } = useOrganizationDetails({
    accessToken: accessToken,
    organizationId: organizationId,
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          mr: 2,
          display: "flex",
          flexGrow: 1,
          // fontFamily: "monospace",
          // fontWeight: 700,
          // letterSpacing: ".3rem",
          // color: "inherit",
          // textDecoration: "none",
        }}
      >
        <Skeleton width={150} height={50} />
      </Box>
    );
  }

  if (organizationData?.isRootOrganization) {
    return (
      <Box
        sx={{
          mr: 2,
          display: "flex",
          flexGrow: 1,
          // fontFamily: "monospace",
          // fontWeight: 700,
          // letterSpacing: ".3rem",
          // color: "inherit",
          // textDecoration: "none",
        }}
      >
        <img src={MuveIt} style={{ height: "24px" }} />
      </Box>
    );
  } else {
    return !organizationData?.logo ? (
      <Box
        sx={{
          mr: 2,
          display: "flex",
          flexGrow: 1,
          // fontFamily: "monospace",
          // fontWeight: 700,
          // letterSpacing: ".3rem",
          // color: "inherit",
          // textDecoration: "none",
        }}
      >
        <img src={VirtualFleetHub} style={{ height: "30px" }} />
      </Box>
    ) : (
      <Box
        sx={{
          mr: 2,
          display: "flex",
          flexGrow: 1,
          // fontFamily: "monospace",
          // fontWeight: 700,
          // letterSpacing: ".3rem",
          // color: "inherit",
          // textDecoration: "none",
        }}
      >
        <img src={organizationData?.logo} style={{ height: "30px" }} />
      </Box>
    );
  }
};

export default LogoSection;
