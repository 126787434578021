import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import { useEffect } from "react";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Drivers from "./pages/Drivers/Drivers";
import Customers from "./pages/Customers/Customers";
import DriverProfile from "./pages/Drivers/DriverProfile/DriverProfile";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import CustomerProfile from "./pages/Customers/CustomerProfile/CustomerProfile";
import ServiceCalls from "./pages/ServiceCalls/ServiceCalls";
import ServiceCallDetails from "./pages/ServiceCalls/ServiceCallDetails/ServiceCallDetails";
import ScheduledServiceCalls from "./pages/ScheduledServiceCalls/ScheduledServiceCalls";
import ScheduledServiceCallDetails from "./pages/ScheduledServiceCalls/ScheduledServiceCallDetails/ScheduledServiceCallDetails";
import ServiceCallSubmissionAttempts from "./pages/ServiceCallSubmissionAttempts/ServiceCallSubmissionAttempts";
import ServiceCallSubmissionAttemptDetails from "./pages/ServiceCallSubmissionAttempts/ServiceCallSubmissionAttemptDetails/ServiceCallSubmissionAttemptDetails";
import useAccessToken from "./hooks/useAccessToken";
import { AuthContext } from "./context/authContext";
import { getCognitoAccessToken } from "./helpers/authHelpers";
import Companies from "./pages/Companies/Companies";
import CompanyDetails from "./pages/Companies/CompanyDetails/CompanyDetails";
import { RecoilRoot } from "recoil";
import useAuthGateway from "./hooks/useAuthGateway";
import Organizations from "./pages/Organizations/Organizations";
import OrganizationDetails from "./pages/Organizations/OrganizationDetails/OrganizationDetails";
import RequireRootAdmin from "./components/RequireRootAdmin/RequireRootAdmin";
import CurrentOrganization from "./pages/Organizations/CurrentOrganization/CurrentOrganization";
import Admins from "./pages/Admins/Admins";
import AdminProfile from "./pages/Admins/AdminProfile/AdminProfile";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Settings from "./pages/Settings/Settings";
import RequireSuperAdmin from "./components/RequireSuperAdmin/RequireSuperAdmin";
import PromoCodes from "./pages/PromoCodes/PromoCodes";
import PromoCodeDetails from "./pages/PromoCodes/PromoCodeDetails/PromoCodeDetails";
const AppRoutes = () => {
  useAuthGateway();
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="dashboard">
          <Route
            index
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="drivers"
            element={
              <RequireAuth>
                <Drivers />
              </RequireAuth>
            }
          />
          <Route
            path="drivers/:userId"
            element={
              <RequireAuth>
                <DriverProfile />
              </RequireAuth>
            }
          />
          <Route
            path="customers"
            element={
              <RequireAuth>
                <Customers />
              </RequireAuth>
            }
          />
          <Route
            path="customers/:userId"
            element={
              <RequireAuth>
                <CustomerProfile />
              </RequireAuth>
            }
          />
          <Route
            path="service-calls"
            element={
              <RequireAuth>
                <ServiceCalls />
              </RequireAuth>
            }
          />
          <Route
            path="service-calls/:serviceCallId"
            element={
              <RequireAuth>
                <ServiceCallDetails />
              </RequireAuth>
            }
          />
          <Route
            path="scheduled-service-calls"
            element={
              <RequireAuth>
                <ScheduledServiceCalls />
              </RequireAuth>
            }
          />
          <Route
            path="scheduled-service-calls/:serviceCallId"
            element={
              <RequireAuth>
                <ScheduledServiceCallDetails />
              </RequireAuth>
            }
          />
          <Route
            path="service-call-submission-attempts"
            element={
              <RequireAuth>
                <ServiceCallSubmissionAttempts />
              </RequireAuth>
            }
          />
          <Route
            path="service-call-submission-attempts/:serviceCallSubmissionAttemptId"
            element={
              <RequireAuth>
                <ServiceCallSubmissionAttemptDetails />
              </RequireAuth>
            }
          />
          <Route
            path="companies"
            element={
              <RequireAuth>
                <RequireRootAdmin>
                  <RequireSuperAdmin>
                    <Companies />
                  </RequireSuperAdmin>
                </RequireRootAdmin>
              </RequireAuth>
            }
          />
          <Route
            path="companies/:companyId"
            element={
              <RequireAuth>
                <RequireRootAdmin>
                  <RequireSuperAdmin>
                    <CompanyDetails />
                  </RequireSuperAdmin>
                </RequireRootAdmin>
              </RequireAuth>
            }
          />
          <Route
            path="current-organization"
            element={
              <RequireAuth>
                <CurrentOrganization />
              </RequireAuth>
            }
          />
          <Route
            path="organizations"
            element={
              <RequireAuth>
                <RequireRootAdmin>
                  <Organizations />
                </RequireRootAdmin>
              </RequireAuth>
            }
          />
          <Route
            path="organizations/:organizationId"
            element={
              <RequireAuth>
                <RequireRootAdmin>
                  <OrganizationDetails />
                </RequireRootAdmin>
              </RequireAuth>
            }
          />
          <Route
            path="admins"
            element={
              <RequireAuth>
                <Admins />
              </RequireAuth>
            }
          />
          <Route
            path="admins/:adminId"
            element={
              <RequireAuth>
                <AdminProfile />
              </RequireAuth>
            }
          />
          <Route
            path="promo-codes"
            element={
              <RequireAuth>
                <PromoCodes />
              </RequireAuth>
            }
          />
          <Route
            path="promo-codes/:promoCodeId"
            element={
              <RequireAuth>
                <PromoCodeDetails />
              </RequireAuth>
            }
          />
          <Route
            path="settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  const queryClient = new QueryClient();
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
