import {
  Alert,
  Divider,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DriverVehicleInformationVehicleTypes,
  getVehicleClassForVehicleType,
  getVehicleTypesForVehicleClass,
  isAnyKeyValueTrue,
  ServiceDriverTypes,
  validatePresence,
} from "../../../../../helpers/validationHelpers";
import { range } from "../../../../../helpers/formatters";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateDriverVehicleInformation } from "../../../../../hooks/useDrivers";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../../atom";
import { isCurrentAdminFromRootOrganization } from "../../../../../helpers/authHelpers";

const VehicleDetailsForm = ({
  isRootOrg,
  vehicleClassMappingsData,
  driverType,
  setDriverType,
  vehicleType,
  setVehicleType,
  makeOfCar,
  setMakeOfCar,
  licensePlateNumber,
  setLicensePlateNumber,
  carColor,
  setCarColor,
  carYear,
  setCarYear,
}: {
  isRootOrg?: boolean;
  vehicleClassMappingsData: any[];
  driverType: ServiceDriverTypes;
  setDriverType: any;
  vehicleType: DriverVehicleInformationVehicleTypes;
  setVehicleType: any;
  makeOfCar: string;
  setMakeOfCar: Dispatch<SetStateAction<string>>;
  licensePlateNumber: string;
  setLicensePlateNumber: Dispatch<SetStateAction<string>>;
  carColor: string;
  setCarColor: Dispatch<SetStateAction<string>>;
  carYear: any;
  setCarYear: Dispatch<SetStateAction<any>>;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const queryClient = useQueryClient();
  const currentYear = new Date().getFullYear();

  const yearRange = range(1960, currentYear).reverse();

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      driverType,
      vehicleType,
      licensePlateNumber,
      makeOfCar,
      carColor,
      carYear,
    });

  const formErrors = {
    licensePlateNumber: {
      error:
        blurredFormFields.licensePlateNumber &&
        !validatePresence(licensePlateNumber) &&
        "Please enter your license plate number",
    },
    makeOfCar: {
      error:
        blurredFormFields.makeOfCar &&
        !validatePresence(makeOfCar) &&
        "Please enter the make of your car",
    },
    carColor: {
      error:
        blurredFormFields.carColor &&
        !validatePresence(carColor) &&
        "Please enter the car's color",
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="driver-type-select-label">Driver Type</InputLabel>
            <Select
              value={driverType}
              label="Driver Type"
              onChange={(e) => {
                setDriverType(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("driverType");
              }}
              onBlur={() => {
                setFormFieldToBlurred("driverType");
              }}
            >
              <MenuItem value={ServiceDriverTypes.individual}>
                Individual
              </MenuItem>
              <MenuItem value={ServiceDriverTypes.team}>Team</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="vehicle-type-select-label">Vehicle Type</InputLabel>
            {isRootOrg ? (
              <Select
                value={vehicleType}
                label="Vehicle Type"
                onChange={(e) => {
                  setVehicleType(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("vehicleType");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("vehicleType");
                }}
              >
                <MenuItem
                  value={DriverVehicleInformationVehicleTypes.pickUpTruck}
                >
                  Pick Up Truck
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.cargoVan}>
                  Cargo Van
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.minivan}>
                  Minivan
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.suv}>
                  SUV
                </MenuItem>
                <MenuItem value={DriverVehicleInformationVehicleTypes.sedan}>
                  Sedan
                </MenuItem>
              </Select>
            ) : (
              <Select
                value={vehicleType}
                label="Vehicle Type"
                onChange={(e) => {
                  setVehicleType(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("vehicleType");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("vehicleType");
                }}
              >
                {vehicleClassMappingsData?.length &&
                  vehicleClassMappingsData?.map((vehicleClassMapping) => {
                    // Gets the first available vehicle type for this vehicle mapping
                    // Large = Pickup Truck
                    // Medium = Minivan
                    // Small = Sedan
                    const vehicleTypesForCurrentClass =
                      getVehicleTypesForVehicleClass(
                        vehicleClassMapping?.vehicleClass
                      );

                    if (vehicleTypesForCurrentClass?.length) {
                      return (
                        <MenuItem
                          key={`vehicle-details-vehicle-class-mapping-${vehicleClassMapping?.id}`}
                          value={vehicleTypesForCurrentClass[0]}
                        >
                          {vehicleClassMapping?.name}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={"License Plate #"}
            fullWidth
            value={licensePlateNumber}
            error={Boolean(isAnyKeyValueTrue(formErrors.licensePlateNumber))}
            helperText={isAnyKeyValueTrue(formErrors.licensePlateNumber)}
            onChange={(e) => {
              setLicensePlateNumber(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("licensePlateNumber");
            }}
            onBlur={() => {
              setFormFieldToBlurred("licensePlateNumber");
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={"Make of Car"}
            fullWidth
            value={makeOfCar}
            error={Boolean(isAnyKeyValueTrue(formErrors.makeOfCar))}
            helperText={isAnyKeyValueTrue(formErrors.makeOfCar)}
            onChange={(e) => {
              setMakeOfCar(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("makeOfCar");
            }}
            onBlur={() => {
              setFormFieldToBlurred("makeOfCar");
            }}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={"Car Color"}
            fullWidth
            value={carColor}
            error={Boolean(isAnyKeyValueTrue(formErrors.carColor))}
            helperText={isAnyKeyValueTrue(formErrors.carColor)}
            onChange={(e) => {
              setCarColor(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("carColor");
            }}
            onBlur={() => {
              setFormFieldToBlurred("carColor");
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Year of Make</InputLabel>
            <Select
              value={carYear}
              label="Year of Make"
              onChange={(e) => {
                setCarYear(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("carYear");
              }}
              onBlur={() => {
                setFormFieldToBlurred("carYear");
              }}
            >
              {yearRange.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleDetailsForm;
