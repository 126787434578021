import { useLocation, Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";

const RequireSuperAdmin = ({ admin = false, children }: any) => {
  const location = useLocation();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  if (authDetails?.role !== "SUPERADMIN") {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireSuperAdmin;
