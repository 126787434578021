import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getDefaultOrganizationLocation = async ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId: string;
}) => {
  try {
    if (!accessToken) {
      return null;
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organization/${organizationId}/default-organization-location`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error: any) {
    console.log("error fetching default organization location", error);
  }
};

const useDefaultOrganizationLocation = ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId: string;
}) => {
  return useQuery({
    queryKey: ["default-organization-location", organizationId],
    queryFn: () =>
      getDefaultOrganizationLocation({ accessToken, organizationId }),
  });
};

export { useDefaultOrganizationLocation };
