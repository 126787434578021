import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  filterFormErrorObject,
  filterObjectByValues,
  isAnyKeyValueTrue,
  mapObjectValues,
  objectHasKeys,
  validateDrivingLicenseNumber,
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validatePresence,
} from "../../../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import cities from "../../../../../utils/cities.json";
import provinces from "../../../../../utils/provinces.json";
import PhoneNumberTextField from "../../../../../components/PhoneNumberTextField/PhoneNumberTextField";
import ErrorHelperText from "../../../../../components/ErrorHelperText/ErrorHelperText";
import ImageUploadSection from "../../../../../components/ImageUploadSection/ImageUploadSection";
import { uploadAssetToCloudinary } from "../../../../../utils/cloudinary";
import moment from "moment";
import {
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const DriverProfileDetailsForm = ({
  currentDate,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  dateOfBirth,
  setDateOfBirth,
  address,
  setAddress,
  city,
  setCity,
  preferredCity,
  setPreferredCity,
  province,
  setProvince,
  driverPostalCode,
  setDriverPostalCode,
  country,
  setCountry,
  drivingLicenseNumber,
  setDrivingLicenseNumber,
  avatar,
  setAvatar,
  avatarUploadLoading,
  setAvatarUploadLoading,
}: any) => {
  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      firstName,
      lastName,
      email,
      dateOfBirth,
      phoneNumber,
      address,
      city,
      preferredCity,
      province,
      driverPostalCode,
      country,
      drivingLicenseNumber,
    });

  const formErrors = {
    firstName: {
      error:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      error:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    email: {
      error: !validateEmail(email) && "Please enter a valid email",
    },
    phoneNumber: {
      error:
        blurredFormFields.phoneNumber &&
        !validatePresence(phoneNumber) &&
        !validatePhoneNumber(phoneNumber) &&
        "Please enter a valid phone number",
    },
    address: {
      error:
        blurredFormFields.address &&
        !validatePresence(address) &&
        "Please enter an address",
    },
    city: {
      error:
        blurredFormFields.city &&
        !validatePresence(city) &&
        "Please enter a city",
    },
    preferredCity: {
      error:
        !validatePresence(preferredCity) && "Please enter a preferred city",
    },
    province: {
      error: !validatePresence(province) && "Please enter a province",
    },
    driverPostalCode: {
      empty:
        blurredFormFields.driverPostalCode &&
        !validatePresence(driverPostalCode),
      error:
        blurredFormFields.driverPostalCode &&
        !validatePostalCode(driverPostalCode) &&
        "Invalid postal code",
    },
    country: {
      error:
        blurredFormFields.country &&
        !validatePresence(country) &&
        "Please enter a country",
    },
    drivingLicenseNumber: {
      empty:
        blurredFormFields.drivingLicenseNumber &&
        !validatePresence(drivingLicenseNumber) &&
        "Please enter a driving license number",
      error:
        blurredFormFields.drivingLicenseNumber &&
        !validatePresence(drivingLicenseNumber) &&
        !validateDrivingLicenseNumber(drivingLicenseNumber) &&
        "Kindly enter the driver license number without any spaces or special characters",
    },
    dateOfBirth: {
      empty: !dateOfBirth && "Please enter your date of birth",
      tooYoung:
        dateOfBirth &&
        moment().diff(dateOfBirth, "years", true) < 18 &&
        "Driver must be at least 18 years of age",
      tooOld:
        dateOfBirth &&
        moment().diff(dateOfBirth, "years", true) > 120 &&
        "Driver must be younger than 120 years old",
    },
  };

  const parsedFormErrors = filterFormErrorObject(formErrors);

  // console.log("dateOfBirthErrors", dateOfBirthErrors);

  const handleAvatarUpload = async (file: any) => {
    setAvatarUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "profile_pictures");
      setAvatar(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setAvatarUploadLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"First Name"}
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.firstName))}
              onFocus={() => {
                setFormFieldToFocused("firstName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("firstName");
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.firstName,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-details-first-name-errors-${index}`}
                  errorText={parsedFormErrors?.firstName[key]}
                />
              )
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Last Name"}
              fullWidth
              value={lastName}
              error={Boolean(isAnyKeyValueTrue(formErrors.lastName))}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("lastName");
              }}
              onBlur={() => {
                setFormFieldToBlurred("lastName");
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.lastName,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-last-name-errors-${index}`}
                  errorText={parsedFormErrors?.lastName[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <PhoneNumberTextField
              label="Phone Number"
              placeholder="Phone Number"
              error={Boolean(isAnyKeyValueTrue(formErrors.phoneNumber))}
              value={phoneNumber}
              setValue={setPhoneNumber}
              onFocus={() => {
                setFormFieldToFocused("phoneNumber");
              }}
              onBlur={() => {
                setFormFieldToBlurred("phoneNumber");
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.phoneNumber,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-phone-number-errors-${index}`}
                  errorText={parsedFormErrors?.phoneNumber[key]}
                />
              )
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Email"}
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.email))}
              onFocus={() => {
                setFormFieldToFocused("email");
              }}
              onBlur={() => {
                setFormFieldToBlurred("email");
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.email,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-email-errors-${index}`}
                  errorText={parsedFormErrors?.email[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              label="Date of Birth"
              value={dateOfBirth}
              onChange={(newValue) => {
                setDateOfBirth(newValue);
              }}
              minDate={moment.utc(currentDate).subtract(120, "years")}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  onBlur: () => {
                    setFormFieldToBlurred("dateOfBirth");
                  },
                  onFocus: () => {
                    setFormFieldToFocused("dateOfBirth");
                  },
                  error: Boolean(objectHasKeys(parsedFormErrors.dateOfBirth)),
                },
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.dateOfBirth,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-details-date-of-birth-errors-${index}`}
                  errorText={parsedFormErrors?.dateOfBirth[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Address"}
              fullWidth
              value={address}
              error={Boolean(isAnyKeyValueTrue(formErrors.address))}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("address");
              }}
              onBlur={() => {
                setFormFieldToBlurred("address");
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.address,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-details-address-errors-${index}`}
                  errorText={parsedFormErrors?.address[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"City"}
              fullWidth
              value={city}
              error={Boolean(isAnyKeyValueTrue(formErrors.city))}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("city");
              }}
              onBlur={() => {
                setFormFieldToBlurred("city");
              }}
            />
            {mapObjectValues(parsedFormErrors?.city, (key: any, index: any) => (
              <ErrorHelperText
                key={`create-new-driver-driver-profile-city-errors-${index}`}
                errorText={parsedFormErrors?.city[key]}
              />
            ))}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Preferred City</InputLabel>
              <Select
                onChange={(e) => {
                  setPreferredCity(e.target.value);
                }}
                error={Boolean(isAnyKeyValueTrue(formErrors.preferredCity))}
                onFocus={() => {
                  setFormFieldToFocused("preferredCity");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("preferredCity");
                }}
                value={preferredCity}
                label="Preferred City"
              >
                {cities.map((city) => (
                  <MenuItem
                    key={`driver-profile-details-preferred-city-options${city?.name}`}
                    value={city.name}
                  >
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {mapObjectValues(
              parsedFormErrors?.preferredCity,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-preferred-city-errors-${index}`}
                  errorText={parsedFormErrors?.preferredCity[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Province</InputLabel>
              <Select
                onChange={(e) => {
                  setProvince(e.target.value);
                }}
                error={Boolean(isAnyKeyValueTrue(formErrors.province))}
                onFocus={() => {
                  setFormFieldToFocused("province");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("province");
                }}
                value={province}
                label="Province"
              >
                {provinces.map((province) => (
                  <MenuItem
                    key={`driver-profile-details-province-options-${province.name}`}
                    value={province.name}
                  >
                    {province.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {mapObjectValues(
              parsedFormErrors?.province,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-province-errors-${index}`}
                  errorText={parsedFormErrors?.province[key]}
                />
              )
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"Postal Code"}
              fullWidth
              value={driverPostalCode}
              error={Boolean(isAnyKeyValueTrue(formErrors.driverPostalCode))}
              onFocus={() => {
                setFormFieldToFocused("driverPostalCode");
              }}
              onBlur={() => {
                setFormFieldToBlurred("driverPostalCode");
              }}
              onChange={(e) => {
                setDriverPostalCode(e.target.value);
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.driverPostalCode,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-driver-postal-code-errors-${index}`}
                  errorText={parsedFormErrors?.driverPostalCode[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={"Country"}
              fullWidth
              error={Boolean(isAnyKeyValueTrue(formErrors.country))}
              onFocus={() => {
                setFormFieldToFocused("country");
              }}
              onBlur={() => {
                setFormFieldToBlurred("country");
              }}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.country,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-country-errors-${index}`}
                  errorText={parsedFormErrors?.country[key]}
                />
              )
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={"License Number"}
              fullWidth
              value={drivingLicenseNumber}
              error={Boolean(
                isAnyKeyValueTrue(formErrors.drivingLicenseNumber)
              )}
              onChange={(e) => {
                setDrivingLicenseNumber(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("drivingLicenseNumber");
              }}
              onBlur={() => {
                setFormFieldToBlurred("drivingLicenseNumber");
              }}
            />
            {mapObjectValues(
              parsedFormErrors?.drivingLicenseNumber,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-new-driver-driver-profile-driving-license-number-errors-${index}`}
                  errorText={parsedFormErrors?.drivingLicenseNumber[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography>Avatar (Required)</Typography>
          </Grid>
          <ImageUploadSection
            uploadedFile={avatar}
            loading={avatarUploadLoading}
            label={"Avatar"}
            handleUpload={handleAvatarUpload}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DriverProfileDetailsForm;
