import { Typography, Grid, Alert, Snackbar } from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { deleteCompanyPickUpRequestVoucherType } from "../../../../hooks/useCompanyPickUpRequestVoucherTypes";
import { useState } from "react";
import { removeCompanyUser } from "../../../../hooks/useCompanyUsers";
import { removeAdmin } from "../../../../hooks/useAdmins";
import { useLocation, useNavigate } from "react-router-dom";

const AdminDeleteModal = ({
  accessToken,
  adminId,
  open,
  setOpen,
}: {
  accessToken: string;
  adminId: string;
  open: boolean;
  setOpen: any;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const isAdminFetching = useIsFetching({ queryKey: ["admin", adminId] });

  const { mutate: handleRemoveAdmin } = useMutation({
    mutationFn: removeAdmin,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["admin", adminId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["admin", adminId],
      });
      setOpen(false);
      setShowSuccess(true);

      if (location?.state?.prevPage) {
        navigate(location.state.prevPage);
      } else {
        navigate("/dashboard/admins", {
          state: {
            prevPage: location.pathname,
          },
        });
      }
    },
    onError: async (error: any) => {
      console.log("error deleting admin", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    handleRemoveAdmin({
      accessToken,
      adminId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Admin Removed
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={Boolean(isAdminFetching)}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        title={"Confirm admin removal"}
        submitButtonLabel="Remove"
      >
        <Grid item>
          <Typography>Are you sure you want to remove this admin?</Typography>
        </Grid>
      </Modal>
    </>
  );
};

export default AdminDeleteModal;
