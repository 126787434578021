import { Grid } from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ImageUploadSection from "../../../components/ImageUploadSection/ImageUploadSection";
import LogoPlaceholder from "../../../assets/images/logo-placeholder.png";
import { uploadAssetToCloudinary } from "../../../utils/cloudinary";
import Button from "../../../components/Button/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCurrentAdminAvatar } from "../../../hooks/useAdmins";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
const AvatarUploadModal = ({
  open,
  setOpen,
  accessToken,
  adminId,
  adminAvatar,
  setSuccessMessage,
  setErrorMessage,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accessToken: string;
  adminAvatar: string;
  adminId: string;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const queryClient = useQueryClient();

  const [avatar, setAvatar] = useState("");
  const [adminAvatarUploadLoading, setAdminAvatarUploadLoading] =
    useState(false);

  useEffect(() => {
    if (open) {
      setAvatar(adminAvatar);
    }
  }, [open]);

  const handleCompanyLogoUpload = async (file: any) => {
    setAdminAvatarUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "company-logos");
      setAvatar(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setAdminAvatarUploadLoading(false);
    }
  };

  const {
    mutate: handleChangeAdminAvatar,
    isLoading: isAdminAvatarChangeLoading,
  } = useMutation({
    mutationFn: updateCurrentAdminAvatar,
    onSuccess: async (data: any) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["current-admin"],
      });

      queryClient.setQueryData(["current-admin"], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });

      await queryClient.cancelQueries({
        queryKey: ["admin", adminId],
      });

      queryClient.setQueryData(["admin", adminId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });

      setAuthDetails((prev: any) => ({
        ...prev,
        avatar: data?.avatar,
      }));

      setSuccessMessage("Admin avatar updated");
    },
    onError: async (error: any) => {
      console.log("error", error);

      setErrorMessage("Failed to update admin avatar");
    },
  });

  const handleSubmit = async () => {
    handleChangeAdminAvatar({
      accessToken,
      avatar,
    });
  };

  const submitButtonDisabled = adminAvatar === avatar;
  return (
    <Modal
      loading={isAdminAvatarChangeLoading || adminAvatarUploadLoading}
      open={open}
      onSubmit={handleSubmit}
      handleClose={() => {
        setOpen(false);
      }}
      onCancel={() => {
        setOpen(false);
      }}
      withCancelButton={false}
      submitButtonLabel="Confirm"
      title="Update Logo"
      submitButtonDisabled={submitButtonDisabled}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ImageUploadSection
            loading={adminAvatarUploadLoading}
            uploadedFile={avatar}
            handleUpload={handleCompanyLogoUpload}
          />
        </Grid>

        {adminAvatar && (
          <Grid item>
            <Button
              onClick={() => {
                setAvatar("");
              }}
              icon={<RestartAltIcon sx={{ marginRight: 2 }} />}
            >
              Reset Avatar to Default
            </Button>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default AvatarUploadModal;
