import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminSuperAdmin,
} from "../../../helpers/authHelpers";
import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useCustomerDetails } from "../../../hooks/useCustomers";
import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import moment from "moment";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import { useState } from "react";
//   import ServiceCallsTab from "./ServiceCallsTab";
//   import CustomerProfileEditModal from "./CustomerProfileEditModal";
//   import PaymentsTab from "./PaymentsTab";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import {
  useOrganizationDetails,
  useOrganizations,
} from "../../../hooks/useOrganizations";
import OrganizationProfilePanel from "./OrganizationProfilePanel";
import OrganizationProfileEditModal from "./OrganizationProfileEditModal";
import OrganizationBillingSettingsTab from "./OrganizationBillingSettingsTab/OrganizationBillingSettingsTab";
import OrganizationDriversTab from "./OrganizationDriversTab/OrganizationDriversTab";
import OrganizationCustomersTab from "./OrganizationCustomersTab/OrganizationCustomersTab";
import OrganizationServiceCallsTab from "./OrganizationServiceCallsTab/OrganizationServiceCallsTab";
import OrganizationScheduledServiceCallsTab from "./OrganizationScheduledServiceCallsTab/OrganizationScheduledServiceCallsTab";
import OrganizationAdminManagementTab from "./OrganizationAdminManagementTab/OrganizationAdminManagementTab";
import AdditionalChargesTab from "./AdditionalChargesTab/AdditionalChargesTab";
import VehicleClassesTab from "./VehicleClassesTab/VehicleClassesTab";
import UserSelectableServicesTab from "./UserSelectableServicesTab/UserSelectableServicesTab";
import PromoCodesTab from "./PromoCodesTab/PromoCodesTab";

const OrganizationDetails = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { organizationId } = useParams();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  //   const { data: companyData } = useCompanyDetails(
  //     authDetails?.accessToken,
  //     companyId
  //   );

  const { data: organizationData } = useOrganizationDetails({
    accessToken: authDetails.accessToken,
    organizationId,
  });
  console.log("organizationData", organizationData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
  };

  const defaultOrganizationLocation =
    organizationData?.organizationLocations[0];

  console.log("default organization location", defaultOrganizationLocation);

  const getDefaultOrganizationLocationCoordsData = () => {
    if (
      defaultOrganizationLocation?.latitude &&
      defaultOrganizationLocation?.longitude
    ) {
      return {
        name: `${defaultOrganizationLocation?.organizationBusinessDetails?.street}, ${defaultOrganizationLocation?.organizationBusinessDetails?.city}, ${defaultOrganizationLocation?.organizationBusinessDetails?.province}, ${defaultOrganizationLocation?.organizationBusinessDetails?.postalCode}`,
        latitude: Number(defaultOrganizationLocation?.latitude),
        longitude: Number(defaultOrganizationLocation?.longitude),
      };
    } else {
      return null;
    }
  };

  const primarySectionFields = [
    // {
    //   label: "Address Line #1",
    //   value: organizationData?.companyDetails?.addressLineOne
    //     ? organizationData?.companyDetails?.addressLineOne
    //     : "N/A",
    // },
    // {
    //   label: "Address Line #2",
    //   value: organizationData?.companyDetails?.addressLineTwo
    //     ? organizationData?.companyDetails?.addressLineTwo
    //     : "N/A",
    // },
    // {
    //   label: "City",
    //   value: organizationData?.companyDetails?.city
    //     ? organizationData?.companyDetails?.city
    //     : "N/A",
    // },
    // {
    //   label: "Province",
    //   value: organizationData?.companyDetails?.province
    //     ? organizationData?.companyDetails?.province
    //     : "N/A",
    // },
    // {
    //   label: "Country",
    //   value: organizationData?.companyDetails?.country
    //     ? organizationData?.companyDetails?.country
    //     : "N/A",
    // },
    {
      label: "Created At",
      value: moment(organizationData?.createdAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
    {
      label: "Updated At",
      value: moment(organizationData?.updatedAt).format(
        "Do MMMM  YYYY, h:mm:ss a"
      ),
    },
  ];

  const tabPages = [
    ...(isCurrentAdminSuperAdmin(authDetails)
      ? [
          <OrganizationBillingSettingsTab
            organizationId={authDetails?.organizationId}
          />,
          <VehicleClassesTab
            organizationId={organizationId}
            isRootOrg={organizationData?.isRootOrganization}
          />,
          <AdditionalChargesTab organizationId={organizationId} />,
          <UserSelectableServicesTab organizationId={organizationId} />,
          <PromoCodesTab organizationId={organizationId} />,
        ]
      : []),
    <OrganizationDriversTab organizationId={organizationId} />,
    <OrganizationCustomersTab organizationId={organizationId} />,
    <OrganizationServiceCallsTab organizationId={organizationId} />,
    <OrganizationScheduledServiceCallsTab organizationId={organizationId} />,
    <OrganizationAdminManagementTab organizationId={organizationId} />,
  ];

  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/organizations",
            title: "Organizations",
          },
          {
            url: `/dashboard/organizations/${organizationId}`,
            title: `${organizationData?.name}`,
          },
        ]}
        pageTitle={`Organization`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/organizations"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        <OrganizationProfileEditModal
          accessToken={authDetails?.accessToken}
          organizationId={organizationData?.id}
          open={open}
          setOpen={setOpen}
          organizationName={organizationData?.name}
          organizationLogo={organizationData?.logo}
          organizationLocationData={getDefaultOrganizationLocationCoordsData()}
        />
        <OrganizationProfilePanel
          name={organizationData?.name}
          defaultOrganizationLocationSlug={defaultOrganizationLocation?.slug}
          organizationId={organizationData?.id}
          organizationLogo={organizationData?.logo}
          onEditClick={() => {
            setOpen(true);
          }}
          withEdit={isCurrentAdminSuperAdmin(authDetails)}
        >
          <Grid container spacing={2} direction={"column"}>
            {primarySectionFields.map((field) => (
              <ProfileListItem label={field.label} value={field.value} />
            ))}
          </Grid>
        </OrganizationProfilePanel>
        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={[
                ...(isCurrentAdminSuperAdmin(authDetails)
                  ? [
                      "Billing Settings",
                      "Vehicle Classes",
                      "Mandatory Service Charges",
                      "Optional Service Charges",
                      "Promo Codes",
                    ]
                  : []),
                "Drivers",
                "Customers",
                "Service Calls",
                "Scheduled Service Calls",
                "Team Members",
              ]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default OrganizationDetails;
