import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import ErrorHelperText from "../ErrorHelperText/ErrorHelperText";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  filterObjectByValues,
  mapObjectValues,
  validatePassword,
  validatePresence,
} from "../../helpers/validationHelpers";
import { Dispatch, SetStateAction, useState } from "react";
import useFormFieldsBlurState from "../../hooks/useFormFieldsBlurState";
import Button from "../Button/Button";

const ChangePasswordForm = ({
  newPassword,
  setNewPassword,
  newPasswordConfirm,
  setNewPasswordConfirm,
  loading,
  handleSubmit,
  submitButtonLabel = "Login",
}: {
  newPassword: string;
  newPasswordConfirm: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setNewPasswordConfirm: Dispatch<SetStateAction<string>>;
  loading: boolean;
  handleSubmit: any;
  submitButtonLabel?: string;
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    newPassword,
    newPasswordConfirm,
  });

  const formErrors = {
    newPassword: {
      empty:
        blurredFormFields.newPassword &&
        !validatePresence(newPassword) &&
        "Please enter a password",
      invalidPassword:
        blurredFormFields.newPassword &&
        validatePresence(newPassword) &&
        !validatePassword(newPassword) &&
        "Password must have 1 uppercase character, 1 lowercase character, 1 digit, and 1 special character and be 8-99 characters long.",
      notMatching:
        blurredFormFields.newPassword &&
        newPassword !== newPasswordConfirm &&
        "Passwords don't match",
    },
    newPasswordConfirm: {
      empty:
        blurredFormFields.newPasswordConfirm &&
        !validatePresence(newPasswordConfirm) &&
        "Please enter a password",
      invalidPassword:
        blurredFormFields.newPasswordConfirm &&
        validatePresence(newPasswordConfirm) &&
        !validatePassword(newPasswordConfirm) &&
        "Password must have 1 uppercase character, 1 lowercase character, 1 digit, and 1 special character and be 8-99 characters long.",
      notMatching:
        blurredFormFields.newPasswordConfirm &&
        newPassword !== newPasswordConfirm &&
        "Passwords don't match",
    },
  };

  const newPasswordErrors: any = filterObjectByValues(
    formErrors?.newPassword,
    (key, value) => value
  );
  const newPasswordConfirmErrors: any = filterObjectByValues(
    formErrors?.newPasswordConfirm,
    (key, value) => value
  );

  const submitDisabled =
    !validatePresence(newPassword) ||
    !validatePassword(newPassword) ||
    !validatePresence(newPasswordConfirm) ||
    !validatePassword(newPasswordConfirm) ||
    newPassword !== newPasswordConfirm;

  return (
    <Grid
      maxWidth={380}
      container
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignContent={"center"}
      alignItems={"flex-start"}
    >
      <Grid item mb={6}>
        <Typography variant="h2" fontSize={30} fontWeight={500}>
          Change Password
        </Typography>
        <Typography>
          Create a strong password with at least eight characters, including
          numbers, letters, and symbols.
        </Typography>
      </Grid>
      <Grid
        container
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={2}
        mb={6}
      >
        <Grid item width={"100%"}>
          <TextField
            required
            type={showNewPassword ? "text" : "password"}
            label="New Password"
            fullWidth
            value={newPassword}
            onFocus={() => {
              setFormFieldToFocused("newPassword");
            }}
            onBlur={(e) => {
              setFormFieldToBlurred("newPassword");
            }}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            // helperText={blurredFormFields.oldPassword && isAnyKeyValueTrue(errors.password)}
            // error={
            //   passwordBlurred && Boolean(isAnyKeyValueTrue(errors.password))
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer", paddingBottom: "4px" }}
                  onClick={() => {
                    setShowNewPassword((prev) => !prev);
                  }}
                >
                  {showNewPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          {mapObjectValues(newPasswordErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`password-change-modal-new-password-errors-${index}`}
              errorText={newPasswordErrors[key]}
            />
          ))}
        </Grid>
        <Grid item width={"100%"}>
          <TextField
            required
            type={showNewPassword ? "text" : "password"}
            label="Confirm new password"
            fullWidth
            value={newPasswordConfirm}
            onFocus={() => {
              setFormFieldToFocused("newPasswordConfirm");
            }}
            onBlur={(e) => {
              setFormFieldToBlurred("newPasswordConfirm");
            }}
            onChange={(e) => {
              setNewPasswordConfirm(e.target.value);
            }}
            // helperText={blurredFormFields.oldPassword && isAnyKeyValueTrue(errors.password)}
            // error={
            //   passwordBlurred && Boolean(isAnyKeyValueTrue(errors.password))
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer", paddingBottom: "4px" }}
                  onClick={() => {
                    setShowNewPassword((prev) => !prev);
                  }}
                >
                  {showNewPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          {mapObjectValues(newPasswordConfirmErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`password-change-modal-new-password-confirm-errors-${index}`}
              errorText={newPasswordConfirmErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item width="100%">
        <Button
          loading={loading}
          disabled={submitDisabled}
          fullWidth
          onClick={handleSubmit}
        >
          {submitButtonLabel}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordForm;
