import { Divider, Grid, List } from "@mui/material";
import VehicleClassMappingsListItem from "./VehicleClassMappingsListItem";

const VehicleClassMappingsList = ({
  vehicleClassMappingsData,
  onItemEditClick,
  isRootOrg,
}: {
  vehicleClassMappingsData: any;
  onItemEditClick: (vehicleClassMapping: any) => void;
  isRootOrg?: boolean;
}) => {
  return (
    <Grid container item xs={12} spacing={2} mt={2}>
      {vehicleClassMappingsData?.map(
        (vehicleClassMapping: any, index: number) => (
          <Grid item xs={12}>
            <VehicleClassMappingsListItem
              isRootOrg={isRootOrg}
              iconUrl={vehicleClassMapping?.iconUrl}
              name={vehicleClassMapping?.name}
              enabled={vehicleClassMapping?.enabled}
              vehicleClass={vehicleClassMapping?.vehicleClass}
              rateInPercent={vehicleClassMapping?.rateInPercent}
              baseFee={vehicleClassMapping?.baseFee}
              perKmRate={vehicleClassMapping?.perKmRate}
              perMinuteRate={vehicleClassMapping?.perMinuteRate}
              driverInitiatedCancellationChargePrePickup={
                vehicleClassMapping?.driverInitiatedCancellationChargePrePickup
              }
              driverInitiatedCancellationChargePostPickup={
                vehicleClassMapping?.driverInitiatedCancellationChargePostPickup
              }
              customerInitiatedCancellationChargePrePickup={
                vehicleClassMapping?.customerInitiatedCancellationChargePrePickup
              }
              customerInitiatedCancellationChargePostPickup={
                vehicleClassMapping?.customerInitiatedCancellationChargePrePickup
              }
              onEditClick={() => {
                onItemEditClick(vehicleClassMapping);
              }}
            />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default VehicleClassMappingsList;
