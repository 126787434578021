import {
  Alert,
  Box,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import PhoneNumberTextField from "../../../components/PhoneNumberTextField/PhoneNumberTextField";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  removeWhitespaces,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AccountDetailsConfirmationModal from "./AccountDetailsConfirmationModal";
import ChangePasswordModal from "./ChangePasswordModal";
import {
  useAdminDetails,
  validateAdminEmailAndPhoneNumber,
} from "../../../hooks/useAdminUser";
import { updateCurrentAdminDetails } from "../../../hooks/useAdmins";
import AvatarUploadModal from "./AvatarUploadModal";

const AccountDetails = () => {
  const queryClient = useQueryClient();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const { data: userData } = useAdminDetails(authDetails?.accessToken);

  console.log("userData", userData);

  // Account Details section
  const [firstName, setFirstName] = useState(
    userData?.firstName || authDetails?.firstName
  );
  const [lastName, setLastName] = useState(
    userData?.lastName || authDetails?.lastName
  );
  const [email, setEmail] = useState(userData?.email || authDetails?.email);
  const [phoneNumber, setPhoneNumber] = useState(
    userData?.phoneNumber || authDetails?.phoneNumber
  );
  const [fieldValidationLoading, setFieldValidationLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [
    accountDetailsConfirmationModalOpen,
    setAccountDetailsConfirmationModalOpen,
  ] = useState(false);

  // Password change section
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [changeAvatarModalOpen, setChangeAvatarModalOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.firstName);
      setLastName(userData?.lastName);
      setEmail(userData?.email);
      setPhoneNumber(userData?.phoneNumber);
    }
  }, [userData]);

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      firstName,
      lastName,
      email,
      phoneNumber,
    });

  const formErrors = {
    firstName: {
      empty:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      empty:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    phoneNumber: {
      empty:
        blurredFormFields?.phoneNumber &&
        !validatePresence(phoneNumber) &&
        "Please enter a phone number",
      invalid:
        blurredFormFields?.phoneNumber &&
        phoneNumber.length > 0 &&
        !validatePhoneNumber(phoneNumber) &&
        "Please enter a valid phone number",
    },
    email: {
      empty:
        blurredFormFields?.email &&
        !validatePresence(email) &&
        "Please enter an email",
      invalid:
        blurredFormFields?.email &&
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email",
    },
  };

  const firstNameErrors: any = filterObjectByValues(
    formErrors?.firstName,
    (key, value) => value
  );
  const lastNameErrors: any = filterObjectByValues(
    formErrors?.lastName,
    (key, value) => value
  );
  const phoneNumberErrors: any = filterObjectByValues(
    formErrors?.phoneNumber,
    (key, value) => value
  );

  const emailErrors: any = filterObjectByValues(
    formErrors?.email,
    (key, value) => value
  );

  const accountDetailsUpdateDisabled =
    !validatePresence(firstName) ||
    !validatePresence(lastName) ||
    !validatePresence(phoneNumber) ||
    !validatePhoneNumber(phoneNumber) ||
    !validatePresence(email) ||
    !validateEmail(email);

  const handleAccountDetailsUpdateButtonClick = async () => {
    setSubmitError("");
    setFieldValidationLoading(true);
    try {
      const validation = await validateAdminEmailAndPhoneNumber({
        accessToken: authDetails?.accessToken,
        email,
        phoneNumber,
      });

      setAccountDetailsConfirmationModalOpen(true);

      console.log("validation response", validation);
    } catch (error: any) {
      console.log("validation errors", error);
      setSubmitError(error);
    } finally {
      setFieldValidationLoading(false);
    }
  };

  const { mutate: updateAdmin, isLoading: isAccountDetailsUpdateLoading } =
    useMutation({
      mutationFn: updateCurrentAdminDetails,
      onSuccess: async (data: any) => {
        console.log("data after submit", data);
        await queryClient.cancelQueries({
          queryKey: ["current-admin"],
        });

        queryClient.setQueryData(["current-admin"], (prev: any) => {
          return {
            ...prev,
            ...data,
          };
        });

        await queryClient.cancelQueries({
          queryKey: ["admin", authDetails.id],
        });

        queryClient.setQueryData(["admin", authDetails.id], (prev: any) => {
          return {
            ...prev,
            ...data,
          };
        });

        setAuthDetails((prev: any) => ({
          ...prev,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
        }));

        setAccountDetailsConfirmationModalOpen(false);
        setSuccessMessage("Admin successfully updated");
      },
      onError: async (error: any) => {
        console.log("error", error);
        // setShowError(true);
        // setErrorMessage(error);
        setSubmitError(error);
      },
    });

  const handleAccountDetailsConfirmationSubmit = () => {
    updateAdmin({
      accessToken: authDetails?.accessToken,
      firstName,
      lastName,
      email: removeWhitespaces(email),
      phoneNumber,
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          // console.log("reason", reason);
          // setOpenSnackbar(false);
          // setError("");
          setSubmitError("");
        }}
        message={submitError}
        action={
          <Box
            onClick={() => {
              setSubmitError("");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: "#f26822" }}>Dismiss</Typography>
          </Box>
        }
      />
      <AccountDetailsConfirmationModal
        loading={isAccountDetailsUpdateLoading}
        firstName={firstName}
        lastName={lastName}
        email={email}
        phoneNumber={phoneNumber}
        open={accountDetailsConfirmationModalOpen}
        setOpen={setAccountDetailsConfirmationModalOpen}
        onSubmit={handleAccountDetailsConfirmationSubmit}
        submitError={submitError}
      />
      <ChangePasswordModal
        open={changePasswordModalOpen}
        setOpen={setChangePasswordModalOpen}
        accessToken={authDetails?.accessToken}
        setSuccessMessage={setSuccessMessage}
      />
      <AvatarUploadModal
        adminId={authDetails?.id}
        adminAvatar={userData?.avatar}
        open={changeAvatarModalOpen}
        setOpen={setChangeAvatarModalOpen}
        accessToken={authDetails?.accessToken}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setSubmitError}
      />
      <Grid
        my={8}
        item
        sx={{
          borderRadius: "15px",
          py: 8,
          px: { xs: 6, sm: 4, md: 6, lg: 8 },
          backgroundColor: "white",
        }}
      >
        <Grid container item spacing={6} justifyContent={"flex-start"}>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <Typography fontSize={24} fontWeight={500}>
                Account Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color={"#B6B6B6"}>
                Manage your VirtualFleethub account details
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                error={objectHasKeys(firstNameErrors)}
                fullWidth
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("firstName");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("firstName");
                }}
                required
                label="First Name"
                variant="outlined"
              />
              {mapObjectValues(firstNameErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`account-details-first-name-errors-${index}`}
                  errorText={firstNameErrors[key]}
                />
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={objectHasKeys(lastNameErrors)}
                fullWidth
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("lastName");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("lastName");
                }}
                required
                label="Last Name"
                variant="outlined"
              />
              {mapObjectValues(lastNameErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`account-details-last-name-errors-${index}`}
                  errorText={lastNameErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("email");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("email");
                }}
                required
                disabled
                label="Email"
                variant="outlined"
              />
              {mapObjectValues(emailErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`account-details-emails-errors-${index}`}
                  errorText={emailErrors[key]}
                />
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneNumberTextField
                label="Phone Number"
                error={objectHasKeys(phoneNumberErrors)}
                value={phoneNumber}
                setValue={setPhoneNumber}
                onFocus={() => {
                  setFormFieldToFocused("phoneNumber");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("phoneNumber");
                }}
                disabled
              />
              {mapObjectValues(phoneNumberErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`account-details-phone-number-errors-${index}`}
                  errorText={phoneNumberErrors[key]}
                />
              ))}
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent={"flex-end"}>
            <Grid item xs={6} sm={6} md={3}>
              <Button
                disabled={accountDetailsUpdateDisabled}
                loading={fieldValidationLoading}
                fullWidth
                onClick={handleAccountDetailsUpdateButtonClick}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container item> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* </Grid> */}
          <Grid
            container
            item
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              container
              item
              spacing={1}
              direction={"column"}
              xs={12}
              sm={6}
              mb={2}
            >
              <Grid item>
                <Typography fontSize={24} fontWeight={500}>
                  Password
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={"#B6B6B6"}>
                  Update your login information
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Button
                fullWidth
                onClick={() => {
                  setChangePasswordModalOpen(true);
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            container
            item
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid
              container
              item
              spacing={1}
              direction={"column"}
              xs={12}
              sm={6}
              mb={2}
            >
              <Grid item>
                <Typography fontSize={24} fontWeight={500}>
                  Avatar
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={"#B6B6B6"}>
                  Update your account's avatar
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Button
                fullWidth
                onClick={() => {
                  setChangeAvatarModalOpen(true);
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountDetails;
