import { Dispatch, SetStateAction } from "react";
import CreatePromoCodeModal from "./CreatePromoCodeModal";
import CreatePromoCodeModalWithOrganizationSelector from "./CreatePromoCodeModalWithOrganizationSelector";
// import AdminInviteModal from "./AdminInviteModal";
// import AdminInviteModalWithOrganizationSelector from "./AdminInviteModalWithOrganizationSelector";

const AddNewPromoCodeModal = ({
  open,
  setOpen,
  organizationId,
  accessToken,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  organizationId?: string;
  accessToken: string;
}) => {
  return organizationId ? (
    <CreatePromoCodeModal
      open={open}
      setOpen={setOpen}
      organizationId={organizationId}
      accessToken={accessToken}
    />
  ) : (
    <CreatePromoCodeModalWithOrganizationSelector
      open={open}
      setOpen={setOpen}
      accessToken={accessToken}
    />
  );
};

export default AddNewPromoCodeModal;
