import { Dispatch, SetStateAction, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { getCumulativeOverviewXlsx } from "../../../hooks/useAdminDashboardStats";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import { isCurrentAdminFromRootOrganization } from "../../../helpers/authHelpers";
import useCSVDownloadState from "../../../hooks/useCSVDownloadState";
import { saveAs } from "file-saver";

const CumulativeOverviewSpreadsheetDownloadModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  //   const {
  //     csvDownloadLoading: cumulativeOverviewSpreadsheetDownloadLoading,
  //     setCsvDownloadLoading: setCumulativeOverviewSpreadsheetDownloadLoading,
  //     csvData: cumulativeOverviewSpreadsheetData,
  //     setCsvData: setCumulativeOverviewSpreadsheetData,
  //     setCsvFilename: setCumulativeOverviewSpreadsheetFilename,
  //     csvLinkRef: cumulativeOverviewSpreadsheetLinkRef,
  //     setShouldDownload: setShouldDownloadCumulativeOverviewSpreadsheet,
  //   } = useCSVDownloadState();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [admins, setAdmins] = useState(false);
  const [organizations, setOrganizations] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [pickUpRequestSubmissionAttempts, setPickUpRequestSubmissionAttempts] =
    useState(false);
  const [customers, setCustomers] = useState(false);
  const [drivers, setDrivers] = useState(false);
  const [pickUpRequests, setPickUpRequests] = useState(false);
  const [scheduledPickUpRequests, setScheduledPickUpRequests] = useState(false);
  const [upcomingScheduledPickUpRequests, setUpcomingScheduledPickUpRequests] =
    useState(false);
  const handleSubmit = async () => {
    await downloadCumulativeOverviewSpreadsheet();
    // setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadCumulativeOverviewSpreadsheet = async () => {
    setShowSuccess(false);
    // setCumulativeOverviewSpreadsheetDownloadLoading(true);
    setReportDownloadLoading(true);
    try {
      const result = await getCumulativeOverviewXlsx({
        accessToken: authDetails?.accessToken,
        organizationId: isCurrentAdminFromRootOrganization(authDetails)
          ? undefined
          : authDetails?.organizationId,
        resources: {
          admins,
          organizations,
          companies,
          pickUpRequestSubmissionAttempts,
          customers,
          drivers,
          pickUpRequests,
          scheduledPickUpRequests,
          upcomingScheduledPickUpRequests,
        },
      });
      console.log("result", result);
      const blob = new Blob([result?.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${result?.filename}.xlsx`);
      setShowSuccess(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setReportDownloadLoading(false);
    }
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Report downloaded
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={reportDownloadLoading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        title={"Download dashboard report"}
        submitButtonLabel="Download"
        submitButtonDisabled={
          !admins &&
          !organizations &&
          !pickUpRequestSubmissionAttempts &&
          !companies &&
          !customers &&
          !drivers &&
          !pickUpRequests &&
          !scheduledPickUpRequests &&
          !upcomingScheduledPickUpRequests
        }
      >
        <Grid container spacing={2}>
          <Grid container item xs={12}>
            <Grid item>
              <Typography>
                Please select the data you'd like to have included in the report
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={admins}
                    onChange={(e) => {
                      setAdmins(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Team Members"
              />
            </Grid>
          </Grid>
          {isCurrentAdminFromRootOrganization(authDetails) && (
            <Grid container item>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={organizations}
                      onChange={(e) => {
                        setOrganizations(e.target.checked);
                      }}
                    />
                  }
                  sx={{ color: "#868686" }}
                  label="Organizations"
                />
              </Grid>
            </Grid>
          )}

          {isCurrentAdminFromRootOrganization(authDetails) && (
            <Grid container item>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={companies}
                      onChange={(e) => {
                        setCompanies(e.target.checked);
                      }}
                    />
                  }
                  sx={{ color: "#868686" }}
                  label="Companies (BPP)"
                />
              </Grid>
            </Grid>
          )}
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={pickUpRequestSubmissionAttempts}
                    onChange={(e) => {
                      setPickUpRequestSubmissionAttempts(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Service Call Submission Attempts"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={customers}
                    onChange={(e) => {
                      setCustomers(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Customers"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={drivers}
                    onChange={(e) => {
                      setDrivers(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Drivers"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={pickUpRequests}
                    onChange={(e) => {
                      setPickUpRequests(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Service Calls"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={scheduledPickUpRequests}
                    onChange={(e) => {
                      setScheduledPickUpRequests(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Scheduled Service Calls"
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={upcomingScheduledPickUpRequests}
                    onChange={(e) => {
                      setUpcomingScheduledPickUpRequests(e.target.checked);
                    }}
                  />
                }
                sx={{ color: "#868686" }}
                label="Upcoming Scheduled Scheduled Service Calls"
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CumulativeOverviewSpreadsheetDownloadModal;
