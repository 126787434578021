import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import { getCustomersCSV, useCustomers } from "../../hooks/useCustomers";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import moment from "moment";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import { AuthContext } from "../../context/authContext";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import { isCurrentAdminFromRootOrganization } from "../../helpers/authHelpers";

const Customers = () => {
  const {
    csvDownloadLoading: customersCsvDownloadLoading,
    setCsvDownloadLoading: setCustomersCsvDownloadLoading,
    csvData: customersCsvData,
    setCsvData: setCustomersCsvData,
    setCsvFilename: setCustomersCsvFilename,
    csvLinkRef: customersCsvLinkRef,
    setShouldDownload: setShouldDownloadCustomersCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const location = useLocation();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const navigate = useNavigate();

  const {
    data: customersData,
    isLoading,
    isFetching,
    isPreviousData,
  } = useCustomers({
    accessToken: authDetails?.accessToken,
    organizationId: isCurrentAdminFromRootOrganization(authDetails)
      ? undefined
      : authDetails?.organizationId,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    // if (
    //   model.page > customersData?.pages[paginationModel.page]?.currentPage &&
    //   hasNextPage
    // ) {
    //   await fetchNextPage();
    // } else if (
    //   model.page < customersData?.pages[paginationModel.page]?.currentPage &&
    //   hasPreviousPage
    // ) {
    //   await fetchPreviousPage();
    // }
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  console.log("customersData", customersData);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .Mui-odd": {
      backgroundColor: "#EFF3F5",
      // "&:hover": {
      //   backgroundColor: "#EFF3F5",
      // },
      // "&.Mui-selected": {
      //   backgroundColor: "#EFF3F5",
      //   "&:hover": {
      //     backgroundColor: "#EFF3F5",
      //   },
      // },
    },
  }));

  const renderRating = (rating: any) => {
    if (Number(rating) === 0) {
      return <Typography>{rating}</Typography>;
    } else if (Number(rating) >= 4) {
      return <Typography sx={{ color: "#3fbf5d" }}>{rating}</Typography>;
    } else if (Number(rating) < 4) {
      return <Typography sx={{ color: "#f09500" }}>{rating}</Typography>;
    } else if (Number(rating) < 2) {
      return <Typography sx={{ color: "#ff0e0e" }}>{rating}</Typography>;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Customer ID",
      width: 300,
      renderCell: (params) => (
        <Link
          to={`/dashboard/customers/${params?.row?.id}`}
          state={{
            prevPage: location.pathname,
          }}
        >
          {params?.row?.id}
        </Link>
      ),
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 100,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // type: "number",
      width: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 250,
      renderCell: (params) => renderRating(params.row.rating),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/customers/${params.row.id}`, {
              state: {
                prevPage: location.pathname,
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadCustomersCsv = async () => {
    setCustomersCsvDownloadLoading(true);
    try {
      const result = await getCustomersCSV(
        authDetails?.accessToken,
        isCurrentAdminFromRootOrganization(authDetails)
          ? undefined
          : authDetails?.organizationId
      );
      setCustomersCsvData(result?.data);
      setCustomersCsvFilename(result?.filename);
      setShouldDownloadCustomersCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setCustomersCsvDownloadLoading(false);
    }
  };
  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/customers",
            title: "Customers",
          },
        ]}
        pageTitle="Customers"
      />
      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={customersData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          exportButtonLoading={customersCsvDownloadLoading}
          onExportButtonClick={downloadCustomersCsv}
          totalCount={customersData?.totalCount}
          pageCount={customersData?.totalPages}
        />
        {/* <StyledDataGrid
        sx={{ backgroundColor: "white", mt: 2 }}
        columns={columns}
        loading={isLoading}
        rows={customersData?.pages[paginationModel.page]?.data ?? []}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
        // @ts-ignore
        rowModel="server"
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        rowCount={customersData?.pages[paginationModel.page]?.totalCount}
        on
      /> */}
      </Grid>

      {customersCsvData && (
        <a
          style={{ display: "none" }}
          ref={customersCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(customersCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </DashboardLayout>
  );
};

export default Customers;
