import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../../../components/Modal/Modal";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
  validateDateOfBirth,
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
  validatePresence,
} from "../../../../../helpers/validationHelpers";
import DriverProfileDetailsForm from "./DriverProfileDetailsForm";
import { Alert, Snackbar } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNewOrganizationDriver } from "../../../../../hooks/useDrivers";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../../atom";
import DriverDocumentUploadForm from "./DriverDocumentUploadForm";
import {
  useOrganizationDetails,
  validateOrganizationUserSubmission,
} from "../../../../../hooks/useOrganizations";
import VehicleDetailsForm from "./VehicleDetailsForm";
import moment from "moment";
import { useOrganizationVehicleClassMappings } from "../../../../../hooks/useVehicleClassMappings";

const AddNewDriverModal = ({
  open,
  setOpen,
  organizationId,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  organizationId?: string;
}) => {
  // shared form state
  const queryClient = useQueryClient();
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [page, setPage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // page 1
  const currentDate = moment(new Date(), true).set({ seconds: 0 });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarUploadLoading, setAvatarUploadLoading] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(moment());
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [preferredCity, setPreferredCity] = useState("");
  const [province, setProvince] = useState("");
  const [driverPostalCode, setDriverPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [drivingLicenseNumber, setDrivingLicenseNumber] = useState("");
  const [creationValidationLoading, setCreationValidationLoading] =
    useState(false);
  // page 2
  const currentYear = new Date().getFullYear();
  const [driverType, setDriverType] = useState(ServiceDriverTypes.individual);
  const [vehicleType, setVehicleType] = useState(
    DriverVehicleInformationVehicleTypes.pickUpTruck
  );
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [makeOfCar, setMakeOfCar] = useState("");
  const [carColor, setCarColor] = useState("");
  const [carYear, setCarYear] = useState(currentYear);

  // page 3
  const [drivingLicenseFrontUpload, setDrivingLicenseFrontUpload] =
    useState("");
  const [
    drivingLicenseFrontUploadLoading,
    setDrivingLicenseFrontUploadLoading,
  ] = useState(false);
  const [drivingLicenseBackUpload, setDrivingLicenseBackUpload] = useState("");
  const [drivingLicenseBackUploadLoading, setDrivingLicenseBackUploadLoading] =
    useState(false);
  const [vehicleSideUpload, setVehicleSideUpload] = useState("");
  const [vehicleSideUploadLoading, setVehicleSideUploadLoading] =
    useState(false);
  const [vehicleCargoBedUpload, setVehicleCargoBedUpload] = useState("");
  const [vehicleCargoBedUploadLoading, setVehicleCargoBedUploadLoading] =
    useState(false);
  const [vehicleProofOfOwnershipUpload, setVehicleProofOfOwnershipUpload] =
    useState("");
  const [
    vehicleProofOfOwnershipUploadLoading,
    setVehicleProofOfOwnershipUploadLoading,
  ] = useState(false);
  const [vehicleInsuranceUpload, setVehicleInsuranceUpload] = useState("");
  const [vehicleInsuranceUploadLoading, setVehicleInsuranceUploadLoading] =
    useState(false);

  // fetch so we can figure out if current org is root org
  const { data: organizationData, isLoading: isOrganizationDetailsLoading } =
    useOrganizationDetails({
      accessToken: authDetails?.accessToken,
      organizationId: organizationId,
    });

  // fetch so we can get the vehicle type labels on page 2
  const {
    data: vehicleClassMappingsData,
    isLoading: isVehicleClassMappingsLoading,
  } = useOrganizationVehicleClassMappings({
    accessToken: authDetails?.accessToken,
    organizationId,
  });

  const handleModalClose = () => {
    setOpen(false);
    setPage(0);
    resetFormFields();
  };

  const resetFormFields = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setAvatar("");
    setEmail("");
    setDateOfBirth(moment());
    setAddress("");
    setCity("");
    setPreferredCity("");
    setProvince("");
    setDriverPostalCode("");
    setCountry("");
    setDrivingLicenseNumber("");
    setDriverType(ServiceDriverTypes.individual);
    setVehicleType(DriverVehicleInformationVehicleTypes.pickUpTruck);
    setLicensePlateNumber("");
    setMakeOfCar("");
    setCarColor("");
    setCarYear(currentYear);
    setDrivingLicenseFrontUpload("");
    setDrivingLicenseBackUpload("");
    setVehicleSideUpload("");
    setVehicleCargoBedUpload("");
    setVehicleProofOfOwnershipUpload("");
    setVehicleInsuranceUpload("");
  };

  useEffect(() => {
    if (!open) {
      resetFormFields();
    }
  }, [open]);

  const validateNewDriverCreate = async () => {
    setCreationValidationLoading(true);
    try {
      await validateOrganizationUserSubmission({
        accessToken: authDetails.accessToken,
        organizationId: organizationId!,
        email,
        phoneNumber,
        dateOfBirth: dateOfBirth.toDate(),
      });
      setPage((prev) => prev + 1);
    } catch (error: any) {
      setShowError(true);
      setErrorMessage(error);
    } finally {
      setCreationValidationLoading(false);
    }
  };

  const {
    mutate: handleNewOrganizationDriverCreate,
    isLoading: isNewOrganizationDriverCreateLoading,
  } = useMutation({
    mutationFn: createNewOrganizationDriver,
    onSuccess: async (data: any) => {
      console.log("data after submit", data);
      queryClient.setQueryData(["driver", data.userId], (prev: any) => {
        return {
          ...prev,
          ...data,
        };
      });
      await queryClient.invalidateQueries({ queryKey: ["drivers"] });
      setShowSuccess(true);
      handleModalClose();
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  const handleFinalSubmit = async () => {
    setShowError(false);
    setErrorMessage("");
    handleNewOrganizationDriverCreate({
      accessToken: authDetails.accessToken,
      organizationId,
      driverFields: {
        firstName,
        lastName,
        avatar,
        email,
        phoneNumber,
        address,
        driverType,
        city,
        dateOfBirth: dateOfBirth.toDate(),
        preferredCity,
        province,
        postalCode: driverPostalCode,
        country,
        drivingLicenseNumber,
        drivingLicenseFrontUpload,
        drivingLicenseBackUpload,
        vehicleType,
        licensePlateNumber,
        makeOfCar,
        carColor,
        carYear: carYear.toString(),
        vehicleSideUpload,
        vehicleCargoBedUpload,
        vehicleProofOfOwnershipUpload,
        vehicleInsuranceUpload,
      },
    });
  };

  const pages = [
    {
      title: "Add New Driver - Profile Details",
      submitButtonLabel: "Next",
      cancelButtonLabel: "Cancel",
      component: (
        <DriverProfileDetailsForm
          currentDate={currentDate}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          avatar={avatar}
          setAvatar={setAvatar}
          avatarUploadLoading={avatarUploadLoading}
          setAvatarUploadLoading={setAvatarUploadLoading}
          email={email}
          setEmail={setEmail}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          preferredCity={preferredCity}
          setPreferredCity={setPreferredCity}
          province={province}
          setProvince={setProvince}
          driverPostalCode={driverPostalCode}
          setDriverPostalCode={setDriverPostalCode}
          country={country}
          setCountry={setCountry}
          drivingLicenseNumber={drivingLicenseNumber}
          setDrivingLicenseNumber={setDrivingLicenseNumber}
        />
      ),
      loading:
        creationValidationLoading ||
        isOrganizationDetailsLoading ||
        isVehicleClassMappingsLoading,
      onSubmit: validateNewDriverCreate,
      onCancel: () => {
        setOpen(false);
      },
      handleBackPress: () => {
        setPage((prev) => prev - 1);
      },
      submitButtonDisabled:
        !validatePresence(firstName) ||
        !validatePresence(lastName) ||
        !validateEmail(email) ||
        !validateDateOfBirth(dateOfBirth) ||
        !validatePresence(avatar) ||
        !validatePhoneNumber(phoneNumber) ||
        !validatePresence(address) ||
        !validatePresence(city) ||
        !validatePresence(preferredCity) ||
        !validatePresence(province) ||
        !validatePostalCode(driverPostalCode) ||
        !validatePresence(country) ||
        !validatePresence(drivingLicenseNumber),
    },
    {
      title: "Add New Driver - Vehicle details",
      submitButtonLabel: "Next",
      cancelButtonLabel: "Back",
      component: (
        <VehicleDetailsForm
          isRootOrg={organizationData?.isRootOrg}
          vehicleClassMappingsData={vehicleClassMappingsData}
          driverType={driverType}
          setDriverType={setDriverType}
          vehicleType={vehicleType}
          setVehicleType={setVehicleType}
          makeOfCar={makeOfCar}
          setMakeOfCar={setMakeOfCar}
          licensePlateNumber={licensePlateNumber}
          setLicensePlateNumber={setLicensePlateNumber}
          carColor={carColor}
          setCarColor={setCarColor}
          carYear={carYear}
          setCarYear={setCarYear}
        />
      ),
      loading: isOrganizationDetailsLoading || isVehicleClassMappingsLoading,
      onSubmit: () => {
        setPage((prev) => prev + 1);
      },
      onCancel: () => {
        setPage((prev) => prev - 1);
      },
      submitButtonDisabled:
        !validatePresence(driverType) ||
        !validatePresence(vehicleType) ||
        !validatePresence(licensePlateNumber) ||
        !validatePresence(makeOfCar) ||
        !validatePresence(carColor),
    },
    {
      title: "Add New Driver - Document Upload",
      submitButtonLabel: "Next",
      cancelButtonLabel: "Back",
      component: (
        <DriverDocumentUploadForm
          drivingLicenseFrontUpload={drivingLicenseFrontUpload}
          setDrivingLicenseFrontUpload={setDrivingLicenseFrontUpload}
          drivingLicenseFrontUploadLoading={drivingLicenseFrontUploadLoading}
          setDrivingLicenseFrontUploadLoading={
            setDrivingLicenseFrontUploadLoading
          }
          drivingLicenseBackUpload={drivingLicenseBackUpload}
          setDrivingLicenseBackUpload={setDrivingLicenseBackUpload}
          drivingLicenseBackUploadLoading={drivingLicenseBackUploadLoading}
          setDrivingLicenseBackUploadLoading={
            setDrivingLicenseBackUploadLoading
          }
          vehicleSideUpload={vehicleSideUpload}
          setVehicleSideUpload={setVehicleSideUpload}
          vehicleSideUploadLoading={vehicleSideUploadLoading}
          setVehicleSideUploadLoading={setVehicleSideUploadLoading}
          vehicleCargoBedUpload={vehicleCargoBedUpload}
          setVehicleCargoBedUpload={setVehicleCargoBedUpload}
          vehicleCargoBedUploadLoading={vehicleCargoBedUploadLoading}
          setVehicleCargoBedUploadLoading={setVehicleCargoBedUploadLoading}
          vehicleProofOfOwnershipUpload={vehicleProofOfOwnershipUpload}
          setVehicleProofOfOwnershipUpload={setVehicleProofOfOwnershipUpload}
          vehicleProofOfOwnershipUploadLoading={
            vehicleProofOfOwnershipUploadLoading
          }
          setVehicleProofOfOwnershipUploadLoading={
            setVehicleProofOfOwnershipUploadLoading
          }
          vehicleInsuranceUpload={vehicleInsuranceUpload}
          setVehicleInsuranceUpload={setVehicleInsuranceUpload}
          vehicleInsuranceUploadLoading={vehicleInsuranceUploadLoading}
          setVehicleInsuranceUploadLoading={setVehicleInsuranceUploadLoading}
        />
      ),
      loading:
        drivingLicenseFrontUploadLoading ||
        drivingLicenseBackUploadLoading ||
        isNewOrganizationDriverCreateLoading,
      onSubmit: handleFinalSubmit,
      onCancel: () => {
        setPage((prev) => prev - 1);
      },
      submitButtonDisabled:
        !validatePresence(firstName) ||
        !validatePresence(lastName) ||
        !validateEmail(email) ||
        !validatePhoneNumber(phoneNumber),
    },
  ];
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          New driver successfully added
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => {
          setShowError(false);
          setErrorMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={pages[page].loading}
        onSubmit={pages[page].onSubmit}
        onCancel={pages[page].onCancel}
        handleClose={handleModalClose}
        submitButtonLabel={pages[page].submitButtonLabel}
        cancelButtonLabel={pages[page].cancelButtonLabel}
        title={pages[page].title}
        submitButtonDisabled={pages[page].submitButtonDisabled}
      >
        {pages[page].component}
      </Modal>
    </>
  );
};

export default AddNewDriverModal;
