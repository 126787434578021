import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { addressSearch } from "../../helpers/addressSearchHelpers";
import useDebounce from "../../hooks/useDebounce";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";

const GoogleMapsAutocomplete = ({
  value,
  inputValue,
  setInputValue,
  setValue,
  accessToken,
  label = "Add a location",
  error = false,
  onBlur = () => {},
  onFocus = () => {},
}: {
  value: any;
  inputValue: any;
  setInputValue: any;
  setValue: any;
  accessToken: string;
  label?: string;
  error?: boolean;
  onBlur?: any;
  onFocus?: any;
}) => {
  // const [value, setValue] = useState<any>(null);

  const debouncedInputValue = useDebounce(inputValue, 1000);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const searchForAddress = async () => {
      try {
        const data = await addressSearch({
          query: debouncedInputValue,
          accessToken: accessToken,
        });

        setOptions(data);
      } catch (error) {
        console.log("error", error);
      }
    };

    if (debouncedInputValue) {
      searchForAddress();
    }
  }, [debouncedInputValue]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.formatted_address || option.name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={(event: any, newValue: any) => {
        // console.log("event", event);
        setOptions(newValue ? [newValue, ...options] : options);
        console.log("newValue", newValue);

        setValue(
          newValue
            ? {
                id: newValue?.place_id,
                name: newValue?.formatted_address,
                latitude: Number(newValue?.geometry?.location?.lat),
                longitude: Number(newValue?.geometry?.location?.lng),
              }
            : newValue
        );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          placeholder={label}
          fullWidth
          variant="outlined"
        />
      )}
      renderOption={(props, option) => {
        // const matches =
        //   option.structured_formatting.main_text_matched_substrings || [];
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box key={option.place_id} component="span">
                  {option?.name}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option?.formatted_address}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GoogleMapsAutocomplete;
