import { FormControl, Grid, Select, Typography } from "@mui/material";
import RoleSelector from "./RoleSelector";
import AdminDeleteModal from "./AdminDeleteModal";
import { useState } from "react";
import Button from "../../../../components/Button/Button";
import { useIsFetching } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { isCurrentAdminSuperAdmin } from "../../../../helpers/authHelpers";

const AdminProfileActionsTab = ({
  adminData,
  accessToken,
  adminId,
}: {
  adminData: any;
  accessToken: string;
  adminId: string;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [showDeleteAdminModal, setShowDeleteAdminModal] = useState(false);
  const isAdminFetching = useIsFetching({ queryKey: ["admin", adminId] });

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      {/* <Grid container item xs={6}> */}
      <RoleSelector
        adminId={adminId}
        accessToken={accessToken}
        adminRole={adminData?.role}
      />
      <AdminDeleteModal
        open={showDeleteAdminModal}
        setOpen={setShowDeleteAdminModal}
        adminId={adminId}
        accessToken={accessToken}
      />
      <Grid container item pt={2}>
        <Button
          isLoading={isAdminFetching}
          disabled={
            adminId === authDetails.id ||
            !isCurrentAdminSuperAdmin(authDetails) ||
            isAdminFetching
          }
          onClick={() => {
            setShowDeleteAdminModal(true);
          }}
        >
          Delete admin
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdminProfileActionsTab;
