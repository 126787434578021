import { Grid, Typography } from "@mui/material";
import { uploadAssetToCloudinary } from "../../../../../utils/cloudinary";
import { Dispatch, SetStateAction } from "react";
import ImageUploadSection from "../../../../../components/ImageUploadSection/ImageUploadSection";

const DriverDocumentUploadForm = ({
  drivingLicenseFrontUpload,
  setDrivingLicenseFrontUpload,
  drivingLicenseFrontUploadLoading,
  setDrivingLicenseFrontUploadLoading,
  drivingLicenseBackUpload,
  setDrivingLicenseBackUpload,
  drivingLicenseBackUploadLoading,
  setDrivingLicenseBackUploadLoading,
  vehicleSideUpload,
  setVehicleSideUpload,
  vehicleSideUploadLoading,
  setVehicleSideUploadLoading,
  vehicleCargoBedUpload,
  setVehicleCargoBedUpload,
  vehicleCargoBedUploadLoading,
  setVehicleCargoBedUploadLoading,
  vehicleProofOfOwnershipUpload,
  setVehicleProofOfOwnershipUpload,
  vehicleProofOfOwnershipUploadLoading,
  setVehicleProofOfOwnershipUploadLoading,
  vehicleInsuranceUpload,
  setVehicleInsuranceUpload,
  vehicleInsuranceUploadLoading,
  setVehicleInsuranceUploadLoading,
}: {
  drivingLicenseFrontUpload: string;
  setDrivingLicenseFrontUpload: Dispatch<SetStateAction<string>>;
  drivingLicenseFrontUploadLoading: boolean;
  setDrivingLicenseFrontUploadLoading: Dispatch<SetStateAction<boolean>>;
  drivingLicenseBackUpload: string;
  setDrivingLicenseBackUpload: Dispatch<SetStateAction<string>>;
  drivingLicenseBackUploadLoading: boolean;
  setDrivingLicenseBackUploadLoading: Dispatch<SetStateAction<boolean>>;
  vehicleSideUpload: string;
  setVehicleSideUpload: Dispatch<SetStateAction<string>>;
  vehicleSideUploadLoading: boolean;
  setVehicleSideUploadLoading: Dispatch<SetStateAction<boolean>>;
  vehicleCargoBedUpload: string;
  setVehicleCargoBedUpload: Dispatch<SetStateAction<string>>;
  vehicleCargoBedUploadLoading: boolean;
  setVehicleCargoBedUploadLoading: Dispatch<SetStateAction<boolean>>;
  vehicleProofOfOwnershipUpload: string;
  setVehicleProofOfOwnershipUpload: Dispatch<SetStateAction<string>>;
  vehicleProofOfOwnershipUploadLoading: boolean;
  setVehicleProofOfOwnershipUploadLoading: Dispatch<SetStateAction<boolean>>;
  vehicleInsuranceUpload: string;
  setVehicleInsuranceUpload: Dispatch<SetStateAction<string>>;
  vehicleInsuranceUploadLoading: boolean;
  setVehicleInsuranceUploadLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  //   const [drivingLicenseFront, setDrivingLicenseFront] = useState("");
  const handleDocumentUpload = async ({
    file,
    bucketName,
    setImage,
    setLoading,
  }: {
    file: any;
    bucketName: string;
    setImage: Dispatch<SetStateAction<string>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
  }) => {
    setLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, bucketName);
      setImage(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} xs={6}>
        <Grid item>
          <Typography>Driver License Front</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={drivingLicenseFrontUpload}
          loading={drivingLicenseFrontUploadLoading}
          label={"Upload driving license (front)"}
          handleUpload={(file: any) => {
            handleDocumentUpload({
              file,
              bucketName: "driving_licenses_front",
              setImage: setDrivingLicenseFrontUpload,
              setLoading: setDrivingLicenseFrontUploadLoading,
            });
          }}
        />
      </Grid>
      <Grid container item spacing={2} xs={6}>
        <Grid item>
          <Typography>Driver License Back</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={drivingLicenseBackUpload}
          loading={drivingLicenseBackUploadLoading}
          label={"Upload driving license (back)"}
          handleUpload={(file: any) => {
            handleDocumentUpload({
              file,
              bucketName: "driving_licenses_back",
              setImage: setDrivingLicenseBackUpload,
              setLoading: setDrivingLicenseBackUploadLoading,
            });
          }}
        />
      </Grid>
      <Grid container item spacing={2} xs={6}>
        <Grid item>
          <Typography>Vehicle Side</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={vehicleSideUpload}
          loading={vehicleSideUploadLoading}
          label={"Vehicle side"}
          handleUpload={(file: any) => {
            handleDocumentUpload({
              file,
              bucketName: "vehicle_side_photos",
              setImage: setVehicleSideUpload,
              setLoading: setVehicleSideUploadLoading,
            });
          }}
        />
      </Grid>
      <Grid container item spacing={2} xs={6}>
        <Grid item>
          <Typography>Vehicle Cargo Bed</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={vehicleCargoBedUpload}
          loading={vehicleCargoBedUploadLoading}
          label={"Vehicle Cargo Bed"}
          handleUpload={(file: any) => {
            handleDocumentUpload({
              file,
              bucketName: "vehicle_cargo_bed_photos",
              setImage: setVehicleCargoBedUpload,
              setLoading: setVehicleCargoBedUploadLoading,
            });
          }}
        />
      </Grid>
      <Grid container item spacing={2} xs={6}>
        <Grid item>
          <Typography>Vehicle Insurance</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={vehicleInsuranceUpload}
          loading={vehicleInsuranceUploadLoading}
          label={"Vehicle insurance"}
          handleUpload={(file: any) => {
            handleDocumentUpload({
              file,
              bucketName: "vehicle_insurance_documents",
              setImage: setVehicleInsuranceUpload,
              setLoading: setVehicleInsuranceUploadLoading,
            });
          }}
        />
      </Grid>
      <Grid container item spacing={2} xs={6}>
        <Grid item>
          <Typography>Vehicle Proof of Ownership</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={vehicleProofOfOwnershipUpload}
          loading={vehicleProofOfOwnershipUploadLoading}
          label={"Vehicle Proof of Ownership"}
          handleUpload={(file: any) => {
            handleDocumentUpload({
              file,
              bucketName: "vehicle_ownership_documents",
              setImage: setVehicleProofOfOwnershipUpload,
              setLoading: setVehicleProofOfOwnershipUploadLoading,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DriverDocumentUploadForm;
