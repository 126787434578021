import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../../../components/PaginationTable/PaginationTable";
import {
  getServiceCallsCSV,
  useServiceCalls,
} from "../../../../hooks/useServiceCalls";
import {
  PickUpStatus,
  cancelledStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  inProgressStatuses,
  mappedVehicleTypes,
  pendingStatuses,
  renderVehicleTypesForServiceCall,
} from "../../../../helpers/validationHelpers";
import { roundToPrecision } from "../../../../helpers/formatters";
import moment from "moment";
import useCSVDownloadState from "../../../../hooks/useCSVDownloadState";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { isCurrentAdminSuperAdmin } from "../../../../helpers/authHelpers";
import { useOrganizationVehicleClassMappings } from "../../../../hooks/useVehicleClassMappings";

const OrganizationServiceCallsTab = ({
  organizationId,
}: {
  organizationId?: string;
}) => {
  const {
    csvDownloadLoading: serviceCallsCsvDownloadLoading,
    setCsvDownloadLoading: setServiceCallsCsvDownloadLoading,
    csvData: serviceCallsCsvData,
    setCsvData: setServiceCallsCsvData,
    setCsvFilename: setServiceCallsCsvFilename,
    csvLinkRef: serviceCallsCsvLinkRef,
    setShouldDownload: setShouldDownloadServiceCallsCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);
  const location = useLocation();
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: serviceCallsData,
    isLoading,
    isFetching,
  } = useServiceCalls({
    accessToken: authDetails?.accessToken,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
    organizationId,
  });

  const { data: vehicleClassMappingsData } =
    useOrganizationVehicleClassMappings({
      accessToken: authDetails.accessToken,
      organizationId,
    });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const renderStatus = (pickUpStatus: PickUpStatus) => {
    if (cancelledStatuses.includes(pickUpStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#FD5554" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {pickUpStatus === PickUpStatus.scheduledDeclined
                ? "Declined"
                : "Cancelled"}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (pendingStatuses.includes(pickUpStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#1162FB" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>Pending</Typography>
          </Grid>
        </Grid>
      );
    } else if (
      [
        PickUpStatus.accepted,
        PickUpStatus.inProgress,
        PickUpStatus.dropOffArrival,
        PickUpStatus.pickUpArrival,
      ].includes(pickUpStatus)
    ) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#1162FB" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>In Progress</Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#87C43B" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>Completed</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "pickUpStatus",
      headerName: "Status",
      width: 150,
      renderCell: (params) => renderStatus(params.row.pickUpStatus),
    },
    {
      field: "id",
      headerName: "Service Call ID",
      width: 250,
      renderCell: (params) => (
        <Link
          to={`/dashboard/service-calls/${params.row.id}`}
          state={{ prevPage: location.pathname }}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 125,

      valueGetter: (params) =>
        `${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`,
      renderCell: (params) =>
        params.row.customer ? (
          <Link
            to={`/dashboard/customers/${params?.row?.customer?.id}`}
            state={{ prevPage: location.pathname }}
          >
            {params?.row?.customer?.firstName} {params?.row?.customer?.lastName}
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "driverName",
      headerName: "Driver Name",
      // type: "number",
      width: 125,
      valueGetter: (params) =>
        params?.row?.driver
          ? `${params?.row?.driver?.firstName} ${params?.row?.driver?.lastName}`
          : "N/A",
      renderCell: (params) =>
        params?.row?.driver ? (
          <Link
            to={`/dashboard/drivers/${params?.row?.driver?.id}`}
            state={{ prevPage: location.pathname }}
          >
            {params?.row?.driver?.firstName} {params?.row?.driver?.lastName}
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "pickUpCode",
      headerName: "Pick Up Code",
      width: 150,
      valueGetter: (params) => params.row.pickUpCode ?? "N/A",
    },
    {
      field: "distanceTravelledInMeters",
      headerName: "Total Dist. (KM)",
      // type: "number",
      // width: 200,
      valueGetter: (params) =>
        params.row.distanceTravelledInMeters
          ? roundToPrecision(params.row.distanceTravelledInMeters / 1000, 2)
          : "N/A",
    },
    {
      field: "totalFare",
      headerName: "Total Fare ($)",
      valueGetter: (params) =>
        roundToPrecision(
          (params?.row?.price +
            params?.row?.taxAmountInCents +
            params?.row?.tipAmountInCents) /
            100,
          2
        ),
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Types",
      valueGetter: (params) => {
        // const vehicleTypes = getVehicleTypesForServiceCall(
        // params.row.pickUpRequestDetails?.requestedVehicleClass,
        // params.row.pickUpRequestDetails?.requestedVehicleType,
        // authDetails
        // );

        // return vehicleTypes.length > 0
        //   ? formatVehicleTypesForServiceCall(vehicleTypes)
        //   : "N/A";

        return renderVehicleTypesForServiceCall({
          adminData: authDetails,
          vehicleClassMappingsData,
          requestedVehicleClass:
            params.row.pickUpRequestDetails?.requestedVehicleClass,
          requestedVehicleType:
            params.row.pickUpRequestDetails?.requestedVehicleType,
          organizationId: params?.row?.organizationId,
        });
      },
    },
    {
      field: "stripeReceiptUrl",
      headerName: "Receipt",
      width: 60,
      renderCell: (params: GridCellParams) =>
        params?.row?.PreauthorizedPayment?.stripeReceiptUrl ? (
          <Link to={params?.row?.PreauthorizedPayment?.stripeReceiptUrl}>
            Stripe
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/service-calls/${params.row.id}`, {
              state: {
                prevPage: location.pathname,
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadServiceCallsCSV = async () => {
    setServiceCallsCsvDownloadLoading(true);
    try {
      const result = await getServiceCallsCSV(
        authDetails?.accessToken,
        organizationId
      );
      setServiceCallsCsvData(result?.data);
      setServiceCallsCsvFilename(result?.filename);
      setShouldDownloadServiceCallsCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setServiceCallsCsvDownloadLoading(false);
    }
  };
  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      <Grid container item pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={serviceCallsData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          exportButtonLoading={serviceCallsCsvDownloadLoading}
          onExportButtonClick={downloadServiceCallsCSV}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={serviceCallsData?.totalCount}
          pageCount={serviceCallsData?.totalPages}
        />
      </Grid>
      {serviceCallsCsvData && (
        <a
          style={{ display: "none" }}
          ref={serviceCallsCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(serviceCallsCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </Grid>
  );
};

export default OrganizationServiceCallsTab;
