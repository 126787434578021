import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminSuperAdmin,
} from "../../../helpers/authHelpers";
import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useCustomerDetails } from "../../../hooks/useCustomers";
import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import moment from "moment";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import { useAdminProfile } from "../../../hooks/useAdmins";
import AdminProfilePanel from "./AdminProfilePanel";
import AdminProfileActionsTab from "./AdminProfileActionsTab/AdminProfileActionsTab";
import AdminEditProfileModal from "./AdminEditProfileModal";

const AdminProfile = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { adminId } = useParams();

  // const accessToken = getAccessToken(user);
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: adminData } = useAdminProfile({
    accessToken: authDetails?.accessToken,
    adminId,
  });

  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
  };

  const primarySectionFields = [
    { label: "Email Address", value: adminData?.email },
    { label: "Phone No.", value: adminData?.phoneNumber },
    {
      label: "Created At",
      value: moment(adminData?.createdAt).format("Do MMMM  YYYY, h:mm:ss a"),
    },
    {
      label: "Updated At",
      value: moment(adminData?.updatedAt).format("Do MMMM  YYYY, h:mm:ss a"),
    },
  ];

  const placeholderTabPage = <Box>Tab page</Box>;

  const tabPages = [
    <AdminProfileActionsTab
      adminId={adminData?.id}
      adminData={adminData}
      accessToken={authDetails?.accessToken}
    />,
  ];

  return (
    <DashboardLayout>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/admins",
            title: "Team Members",
          },
          {
            url: `/dashboard/admins/${adminId}`,
            title: `${adminData?.firstName} ${adminData?.lastName}`,
          },
        ]}
        pageTitle={`Team Members`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/admins"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        <AdminEditProfileModal
          accessToken={authDetails?.accessToken}
          adminId={adminData?.id}
          adminFirstName={adminData?.firstName}
          adminLastName={adminData?.lastName}
          adminEmail={adminData?.email}
          adminPhoneNumber={adminData?.phoneNumber}
          adminRole={adminData?.role}
          open={open}
          setOpen={setOpen}
        />
        <AdminProfilePanel
          firstName={adminData?.firstName}
          lastName={`${adminData?.lastName}`}
          currentAdminId={authDetails?.id}
          avatar={adminData?.avatar}
          adminId={adminData?.id}
          onEditClick={() => {
            setOpen(true);
          }}
          withEdit={isCurrentAdminSuperAdmin(authDetails)}
        >
          <Grid container spacing={2} direction={"column"}>
            {primarySectionFields.map((field) => (
              <ProfileListItem label={field.label} value={field.value} />
            ))}
          </Grid>
        </AdminProfilePanel>
        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={["Actions"]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default AdminProfile;
