const getAccessToken = (user: any) => {
  return user?.getSignInUserSession()?.getAccessToken().getJwtToken();
};

const getCognitoAccessToken = async () => {
  try {
    // const { accessToken: cognitoAccessToken } =
    //   (await fetchAuthSession()).tokens ?? {};

    // return cognitoAccessToken;
    return;
  } catch (err) {
    console.log(err);
  }
};

const isCurrentAdminFromRootOrganization = (adminData: any) => {
  return adminData?.organization?.isRootOrganization;
};

const isCurrentAdminSuperAdmin = (adminData: any) => {
  return adminData?.role === "SUPERADMIN";
};

export {
  getAccessToken,
  getCognitoAccessToken,
  isCurrentAdminFromRootOrganization,
  isCurrentAdminSuperAdmin,
};
