import { Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useCustomerPayments } from "../../../hooks/useCustomers";
import _ from "lodash";
import moment from "moment";
import { toTitleCase } from "../../../helpers/validationHelpers";
import { roundToPrecision } from "../../../helpers/formatters";
import { darken, lighten, styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
const PaymentsTab = ({
  accessToken,
  userId,
}: {
  accessToken?: string;
  userId?: string;
}) => {
  const location = useLocation();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
    startingAfter: null,
    endingBefore: null,
  });

  const {
    data: customerPaymentsData,
    isLoading,
    isFetching,
    isInitialLoading,
    isRefetching,
    refetch,
  } = useCustomerPayments({
    accessToken,
    userId,
    model: paginationModel,
  });

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .Mui-odd": {
      backgroundColor: "#EFF3F5",
      // "&:hover": {
      //   backgroundColor: "#EFF3F5",
      // },
      // "&.Mui-selected": {
      //   backgroundColor: "#EFF3F5",
      //   "&:hover": {
      //     backgroundColor: "#EFF3F5",
      //   },
      // },
    },
  }));

  const columns: GridColDef[] = [
    {
      field: "serviceCallId",
      headerName: "Service Call ID",
      width: 150,
      renderCell: (params) => (
        <Link
          to={`/dashboard/service-calls/${params?.row?.metadata?.serviceCallId}`}
          state={{ prevPage: location.pathname }}
        >
          {params?.row?.metadata?.serviceCallId}
        </Link>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment.unix(params?.row?.created).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "cardType",
      headerName: "Card Type",
      width: 100,
      renderCell: (params) => (
        <Typography>
          {params?.row?.payment_method
            ? toTitleCase(params?.row?.payment_method.card?.brand)
            : "N/A"}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Price",
      width: 100,
      renderCell: (params) => (
        <Typography>
          {params?.row?.amount
            ? roundToPrecision(params?.row?.amount / 100, 2)
            : "N?A"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <Typography>{`${
          params?.row?.description &&
          params?.row?.description?.includes("Preauth")
            ? "Preauth - "
            : ""
        }${toTitleCase(
          params?.row?.status?.split("_").join(" ")
        )}`}</Typography>
      ),
    },
  ];

  const handlePaginationModelChange = async (model: any) => {
    if (
      model.page > customerPaymentsData?.currentPage &&
      customerPaymentsData?.hasMore
    ) {
      setPaginationModel((prev) => ({
        ...prev,
        page: prev.page + 1,
        startingAfter:
          customerPaymentsData?.data[customerPaymentsData?.data.length - 1].id,
        endingBefore: null,
      }));
    } else if (model.page < customerPaymentsData?.currentPage) {
      setPaginationModel((prev) => ({
        ...prev,
        page: prev.page - 1,
        startingAfter: null,
        endingBefore: model.page === 0 ? 0 : customerPaymentsData?.data[0].id,
      }));
    }
  };

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      <Grid item pb={2}>
        <Typography fontWeight={600} fontSize={16}>
          Service Calls
        </Typography>
      </Grid>
      <Grid container item>
        <StyledDataGrid
          sx={{ backgroundColor: "white", mt: 2 }}
          columns={columns}
          loading={isLoading || isFetching || isInitialLoading || isRefetching}
          //   rows={driversData?.pages[paginationModel.page]?.data ?? []}
          rows={customerPaymentsData?.data || []}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
          // @ts-ignore
          rowModel="server"
          pageSizeOptions={[10]}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          paginationMode="server"
          rowCount={customerPaymentsData?.totalCount}
          on
        />
      </Grid>
    </Grid>
  );
};

export default PaymentsTab;
