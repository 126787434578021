import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAdditionalPickUpRequestCharges = async ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/additional-pickup-request-charges/${organizationId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const createNewAdditionalPickUpRequestCharge = async ({
  accessToken,
  organizationId,
  formFields,
}: {
  accessToken: string;
  organizationId?: string;
  formFields: {
    name: string;
    priceInCents: number;
    description?: string;
    enabled?: boolean;
    shouldIgnorePromo?: boolean;
  };
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/additional-pickup-request-charges/${organizationId}`,
      formFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const updateAdditionalPickUpRequestCharge = async ({
  accessToken,
  additionalChargeId,
  formFields,
}: {
  accessToken: string;
  additionalChargeId: string;
  formFields: {
    name: string;
    priceInCents: number;
    description?: string;
    enabled?: boolean;
    shouldIgnorePromo?: boolean;
  };
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/additional-pickup-request-charges/${additionalChargeId}`,
      formFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const deleteAdditionalPickUpRequestCharge = async ({
  accessToken,
  additionalChargeId,
}: {
  accessToken: string;
  additionalChargeId: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/admin/additional-pickup-request-charges/${additionalChargeId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const useAdditionalPickUpRequestCharges = ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: ["additional-pickup-request-charges", organizationId],
    queryFn: () =>
      getAdditionalPickUpRequestCharges({ accessToken, organizationId }),
  });
};

export {
  useAdditionalPickUpRequestCharges,
  getAdditionalPickUpRequestCharges,
  createNewAdditionalPickUpRequestCharge,
  updateAdditionalPickUpRequestCharge,
  deleteAdditionalPickUpRequestCharge,
};
