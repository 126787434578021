import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface Props {
  crumbs: {
    url: string;
    title: string;
  }[];
  pageTitle: string;
  withBackNav?: boolean;
  backNavLabel?: string;
  backNavUrl?: string;
}

const BreadcrumbSection = ({
  crumbs,
  pageTitle,
  withBackNav = false,
  backNavLabel,
  backNavUrl,
}: Props) => {
  const location = useLocation();
  // const location.pathname

  return (
    <Grid container paddingTop={2} justifyContent={"space-between"}>
      <Grid item>
        <Breadcrumbs>
          {crumbs.map((crumb, index) =>
            index === crumbs.length - 1 ? (
              <Typography
                key={`breadcrumb-${crumb.title}-${index}`}
                sx={{ color: "black" }}
              >
                {crumb.title}
              </Typography>
            ) : (
              <NavLink
                key={`breadcrumb-${crumb.title}-${index}`}
                to={`${crumb.url}`}
                style={{ color: "#9a9a9a" }}
                state={{ prevPage: location.pathname }}
              >
                <Typography fontSize={14}>{crumb.title}</Typography>
              </NavLink>
            )
          )}
        </Breadcrumbs>
        <Typography fontSize={20} fontWeight={700}>
          {pageTitle}
        </Typography>
      </Grid>
      {withBackNav && (
        <NavLink
          to={`${backNavUrl}`}
          state={{ prevPage: location.pathname }}
          style={{ color: "#9a9a9a" }}
        >
          <Typography fontSize={14}>
            {"<"} {backNavLabel}
          </Typography>
        </NavLink>
      )}
    </Grid>
  );
};

export default BreadcrumbSection;
