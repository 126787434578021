import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { Grid, IconButton, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  convertCentsToDollars,
  convertToKilometers,
} from "../../../../helpers/formatters";
import { useBillingSettings } from "../../../../hooks/useBillingSettings";
import { isCurrentAdminSuperAdmin } from "../../../../helpers/authHelpers";
import Button from "../../../../components/Button/Button";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import VehicleClassMappingsList from "./VehicleClassMappingsList/VehicleClassMappingsList";
import { useOrganizationVehicleClassMappings } from "../../../../hooks/useVehicleClassMappings";
import VehicleClassMappingModal from "./VehicleClassMappingModal/VehicleClassMappingModal";

const VehicleClassesTab = ({
  organizationId,
  isRootOrg,
}: {
  organizationId?: string;
  isRootOrg?: boolean;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: vehicleClassMappingsData } =
    useOrganizationVehicleClassMappings({
      accessToken: authDetails?.accessToken,
      organizationId,
    });

  const [selectedVehicleClassMapping, setSelectedVehicleClassMapping] =
    useState<any>(null);

  const [showVehicleClassMappingModal, setShowVehicleClassMappingModal] =
    useState(false);

  console.log("vehicleclassmappings", vehicleClassMappingsData);

  useEffect(() => {
    const handleDataChange = () => {
      const isVehicleClassMappingPresentInData = vehicleClassMappingsData?.some(
        (additionalCharge: any) =>
          additionalCharge.id === selectedVehicleClassMapping?.id
      );

      if (!isVehicleClassMappingPresentInData) {
        setShowVehicleClassMappingModal(false);
        // setShowAdditionalPickUpChargeDeleteModal(false);
        setSelectedVehicleClassMapping(null);
      } else {
        const voucherInData = vehicleClassMappingsData?.find(
          (additionalCharge: any) =>
            additionalCharge.id === selectedVehicleClassMapping?.id
        );
        setSelectedVehicleClassMapping(voucherInData);
      }
    };

    if (
      vehicleClassMappingsData &&
      vehicleClassMappingsData?.length &&
      selectedVehicleClassMapping
    ) {
      handleDataChange();
    } else if (!vehicleClassMappingsData || !vehicleClassMappingsData.length) {
      setShowVehicleClassMappingModal(false);
      //   setShowAdditionalPickUpChargeDeleteModal(false);
      setSelectedVehicleClassMapping(null);
    }
  }, [vehicleClassMappingsData, selectedVehicleClassMapping]);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid container item spacing={1} xs={12}>
          {/* Heading */}
          <Grid item xs={12}>
            <Typography fontSize={21} fontWeight={500}>
              Vehicle Classes
            </Typography>
          </Grid>

          {isCurrentAdminSuperAdmin(authDetails) && (
            <>
              {/* <AdditionalPickUpRequestChargeDeleteModal
                accessToken={authDetails?.accessToken}
                open={showAdditionalPickUpChargeDeleteModal}
                setOpen={setShowAdditionalPickUpChargeDeleteModal}
                organizationId={organizationId}
                selectedAdditionalPickUpRequestCharge={
                  selectedAdditionalPickUpRequestCharge
                }
                setSelectedAdditionalPickUpRequestCharge={
                  setSelectedAdditionalPickUpRequestCharge
                }
              /> */}
              <VehicleClassMappingModal
                accessToken={authDetails?.accessToken}
                open={showVehicleClassMappingModal}
                editMode={Boolean(selectedVehicleClassMapping)}
                setOpen={setShowVehicleClassMappingModal}
                organizationId={organizationId}
                selectedVehicleClassMapping={selectedVehicleClassMapping}
                setSelectedVehicleClassMapping={setSelectedVehicleClassMapping}
              />
              {/* <Grid container item justifyContent={"flex-end"}>
                <Grid item>
                  <Button
                    onClick={() => {
                      setShowAdditionalPickUpChargeModal(true);
                    }}
                    icon={<PriceChangeIcon sx={{ marginRight: 2 }} />}
                  >
                    Create new mandatory service charge
                  </Button>
                </Grid>
              </Grid> */}
            </>
          )}

          <VehicleClassMappingsList
            isRootOrg={isRootOrg}
            vehicleClassMappingsData={vehicleClassMappingsData}
            onItemEditClick={(vehicleClassMapping) => {
              setSelectedVehicleClassMapping(vehicleClassMapping);
              setShowVehicleClassMappingModal(true);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleClassesTab;
