import { GridSortModel } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";
const getAdmins = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string,
  organizationId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/admins?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}${
          organizationId ? `&organizationId=${organizationId}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const useAdmins = ({
  accessToken,
  page = 0,
  search,
  sort,
  organizationId,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: [
      "admins",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
      ...(organizationId ? [organizationId] : []),
    ],
    queryFn: () =>
      getAdmins(
        accessToken,
        page,
        search,
        sort && sort?.length > 0
          ? qs.stringify({ sortBy: sort[0] })
          : undefined,
        organizationId
      ),
    keepPreviousData: true,
  });
};

const useAdminProfile = ({
  accessToken,
  adminId,
}: {
  accessToken: string;
  adminId?: string;
}) => {
  return useQuery({
    queryKey: ["admin", adminId],
    queryFn: () => getAdminProfile(accessToken, adminId),
  });
};

const getAdminProfile = async (accessToken: string, adminId?: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/admin/${adminId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getAdminsCSV = async (accessToken: string, organizationId?: string) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admins-csv${
        organizationId ? `?organizationId=${organizationId}` : ""
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const updateOrganizationAdminDetails = async ({
  accessToken,
  adminId,
  firstName,
  lastName,
  // email,
  // phoneNumber,
  role,
}: {
  accessToken: string;
  adminId: string;
  firstName: string;
  lastName: string;
  // email: string;
  // phoneNumber: string;
  role: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admin/update-admin-details/${adminId}`,
      {
        firstName,
        lastName,
        // email,
        // phoneNumber,
        role,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("data", data);

    return data;
  } catch (error: any) {
    throw error?.response?.data || error;
  }
};

const addNewOrganizationAdmin = async ({
  accessToken,
  organizationId,
  firstName,
  lastName,
  email,
  phoneNumber,
  role,
}: {
  accessToken: string;
  organizationId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admin/invite-new-admin`,
      {
        organizationId,
        firstName,
        lastName,
        email,
        phoneNumber,
        role,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("data", data);

    return data;
  } catch (error: any) {
    throw error?.response?.data || error;
  }
};

const modifyAdminRole = async ({
  adminId,
  accessToken,
  role,
}: {
  adminId: string;
  accessToken: string;
  role: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admin/modify-admin-role/${adminId}`,
      { role },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const removeAdmin = async ({
  adminId,
  accessToken,
}: {
  adminId: string;
  accessToken: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admin/remove-admin/${adminId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const updateCurrentAdminDetails = async ({
  accessToken,
  firstName,
  lastName,
  email,
  phoneNumber,
}: {
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admin/account-details`,
      {
        firstName,
        lastName,
        email,
        phoneNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const updateCurrentAdminAvatar = async ({
  accessToken,
  avatar,
}: {
  accessToken: string;
  avatar?: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/admin/update-current-admin-avatar`,
      {
        avatar,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export {
  getAdmins,
  useAdmins,
  useAdminProfile,
  getAdminsCSV,
  addNewOrganizationAdmin,
  modifyAdminRole,
  removeAdmin,
  updateOrganizationAdminDetails,
  updateCurrentAdminDetails,
  updateCurrentAdminAvatar,
};
