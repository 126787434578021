import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getPickUpRequestUserSelectableServices = async ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/pickup-request-user-selectable-services/${organizationId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const createNewPickUpRequestUserSelectableService = async ({
  accessToken,
  organizationId,
  formFields,
}: {
  accessToken: string;
  organizationId?: string;
  formFields: {
    name: string;
    priceInCents: number;
    description?: string;
    enabled?: boolean;
  };
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/pickup-request-user-selectable-services/${organizationId}`,
      formFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const updatePickUpRequestUserSelectableService = async ({
  accessToken,
  userSelectableServiceId,
  formFields,
}: {
  accessToken: string;
  userSelectableServiceId: string;
  formFields: {
    name: string;
    priceInCents: number;
    description?: string;
    enabled?: boolean;
  };
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/pickup-request-user-selectable-services/${userSelectableServiceId}`,
      formFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const deletePickUpRequestUserSelectableService = async ({
  accessToken,
  userSelectableServiceId,
}: {
  accessToken: string;
  userSelectableServiceId: string;
}) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/admin/pickup-request-user-selectable-services/${userSelectableServiceId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const usePickUpRequestUserSelectableServices = ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: ["pickup-request-user-selectable-services", organizationId],
    queryFn: () =>
      getPickUpRequestUserSelectableServices({ accessToken, organizationId }),
  });
};

export {
  usePickUpRequestUserSelectableServices,
  getPickUpRequestUserSelectableServices,
  createNewPickUpRequestUserSelectableService,
  updatePickUpRequestUserSelectableService,
  deletePickUpRequestUserSelectableService,
};
