import moment, { Moment } from "moment";
import { isCurrentAdminFromRootOrganization } from "./authHelpers";

export const validatePassword = (password: string) => {
  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,99}$/;
  return passwordRegex.test(password);
};

export const validateEmail = (email: string) => {
  const emailRegex = /.+\@.+\..+/;

  return emailRegex.test(email);
};

export const isAnyKeyValueTrue = (object: any) => {
  const key = Object.keys(object).find((k) => object[k]);
  return key && object[key];
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export enum PickUpStatus {
  pending = "PENDING",
  scheduled = "SCHEDULED",
  scheduledPending = "SCHEDULEDPENDING",
  scheduledAccepted = "SCHEDULEDACCEPTED",
  scheduledDeclined = "SCHEDULEDDECLINED",
  pendingTransfer = "PENDINGTRANSFER",
  accepted = "ACCEPTED",
  pickUpArrival = "PICKUPARRIVAL",
  inProgress = "INPROGRESS",
  dropOffArrival = "DROPOFFARRIVAL",
  completed = "COMPLETED",
  dropOffCompleted = "DROPOFFCOMPLETED",
  driverCancelled = "DRIVERCANCELLED",
  driverCancelledDueToNoShowAtPickup = "DRIVERCANCELLEDDUETONOSHOWATPICKUP",
  driverCancelledAtInspection = "DRIVERCANCELLEDATINSPECTION",
  // no charge
  customerCancelledWithinGracePeriod = "CUSTOMERCANCELLEDWITHINGRACEPERIOD",
  // small charge
  customerCancelledBeforePickup = "CUSTOMERCANCELLEDBEFOREPICKUP",
  // full charge
  customerCancelled = "CUSTOMERCANCELLED",
  customerPaymentFailed = "CUSTOMERPAYMENTFAILED",
}

export enum ServiceDriverTypes {
  none = "",
  individual = "INDIVIDUAL",
  team = "TEAM",
}

export enum DriverVehicleInformationVehicleTypes {
  none = "",
  pickUpTruck = "PICKUPTRUCK",
  cargoVan = "CARGOVAN",
  minivan = "MINIVAN",
  suv = "SUV",
  sedan = "SEDAN",
}

export enum VehicleClassTypes {
  none = "",
  large = "LARGE",
  medium = "MEDIUM",
  small = "SMALL",
}

export enum CouponDeductionType {
  amountOffInCents = "AMOUNTOFFINCENTS",
  percentOff = "PERCENTOFF",
}

export enum CouponDurationType {
  once = "ONCE",
  oncePerCustomer = "ONCEPERCUSTOMER",
  repeating = "REPEATING",
  forever = "FOREVER",
}

export const VehicleClasses = {
  [VehicleClassTypes.large]: [
    DriverVehicleInformationVehicleTypes.pickUpTruck,
    DriverVehicleInformationVehicleTypes.cargoVan,
  ],
  [VehicleClassTypes.medium]: [
    DriverVehicleInformationVehicleTypes.minivan,
    DriverVehicleInformationVehicleTypes.suv,
  ],
  [VehicleClassTypes.small]: [DriverVehicleInformationVehicleTypes.sedan],
  [VehicleClassTypes.none]: [],
};

export const inProgressStatuses = [
  PickUpStatus.accepted,
  PickUpStatus.pending,
  PickUpStatus.pendingTransfer,
  PickUpStatus.inProgress,
  PickUpStatus.dropOffArrival,
  PickUpStatus.pickUpArrival,
];

export const pendingStatuses = [
  PickUpStatus.pending,
  PickUpStatus.pendingTransfer,
  PickUpStatus.scheduled,
  PickUpStatus.scheduledPending,
  PickUpStatus.scheduledAccepted,
];

export const driverCancelStatuses = [
  PickUpStatus.driverCancelled,
  PickUpStatus.driverCancelledDueToNoShowAtPickup,
  PickUpStatus.driverCancelledAtInspection,
  PickUpStatus.scheduledDeclined,
];

export const customerCancelledStatuses = [
  PickUpStatus.customerCancelledWithinGracePeriod,
  PickUpStatus.customerCancelledBeforePickup,
  PickUpStatus.customerCancelled,
  PickUpStatus.customerPaymentFailed,
];

export const cancelledStatuses = [
  ...driverCancelStatuses,
  ...customerCancelledStatuses,
];

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  return phoneRegex.test(phoneNumber);
};

export const validateDateOfBirth = (dateOfBirth: Moment) => {
  return (
    dateOfBirth &&
    moment().diff(dateOfBirth, "years", true) > 18 &&
    moment().diff(dateOfBirth, "years", true) < 120
  );
};

export const removeWhitespaces = (text: string) => {
  return text.replace(/\s/g, "");
};

export const validatePostalCode = (postalCode: string) => {
  const postalCodeRegex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

  return postalCodeRegex.test(postalCode);
};

export const validateDrivingLicenseNumber = (drivingLicenseNumber: string) => {
  const drivingLicenseNumberRegex = /^[a-zA-Z0-9-]*$/;

  return drivingLicenseNumberRegex.test(drivingLicenseNumber);
};

export const validatePresence = (text: string) => {
  const whiteSpacesRemoved = removeWhitespaces(text);

  return Boolean(whiteSpacesRemoved && whiteSpacesRemoved.length > 0);
};

export const validateStripeApiKey = ({
  keyType,
  environment,
  apiKey,
}: {
  keyType: "publishable" | "secret";
  environment: "test" | "prod";
  apiKey: string;
}) => {
  return Boolean(
    validatePresence(apiKey) &&
      apiKey.length >= 32 &&
      apiKey.startsWith(
        `${keyType === "publishable" ? "pk" : "sk"}_${
          environment === "prod" ? "live" : "test"
        }_`
      )
  );
};

export const getDifference = (a: any, b: any) =>
  Object.entries(a).reduce(
    (ac: any, [k, v]) => (b[k] && b[k] !== v ? ((ac[k] = b[k]), ac) : ac),
    {}
  );

export const getVehicleTypesForVehicleClass = (
  requestedVehicleClass: VehicleClassTypes
) => {
  const selectedVehicleClass: any = VehicleClasses[requestedVehicleClass];

  return selectedVehicleClass;
};

export const getVehicleTypesForServiceCall = (
  requestedVehicleClass: VehicleClassTypes,
  requestedVehicleType: DriverVehicleInformationVehicleTypes,
  adminData: any
) => {
  if (isCurrentAdminFromRootOrganization(adminData)) {
    return getVehicleTypesForRootOrg(
      requestedVehicleClass,
      requestedVehicleType
    );
  } else {
    return getVehicleTypesForNonRootOrg(requestedVehicleClass);
  }
};

export const renderVehicleTypesForServiceCall = ({
  adminData,
  vehicleClassMappingsData,
  requestedVehicleClass,
  requestedVehicleType,
  organizationId,
}: {
  requestedVehicleClass: VehicleClassTypes;
  requestedVehicleType: DriverVehicleInformationVehicleTypes;
  adminData: any;
  vehicleClassMappingsData: any;
  organizationId: string;
}) => {
  if (
    isCurrentAdminFromRootOrganization(adminData) &&
    adminData?.organizationId === organizationId
  ) {
    const vehicleTypes = getVehicleTypesForServiceCall(
      requestedVehicleClass,
      requestedVehicleType,
      adminData
    );
    return vehicleTypes?.length > 0
      ? formatVehicleTypesForServiceCall(vehicleTypes)
      : "N/A";
  } else {
    // return requestedVehicleClass ? toTitleCase(requestedVehicleClass) : "N/A";
    if (!vehicleClassMappingsData || !vehicleClassMappingsData?.length) {
      return "N/A";
    }

    const matchingVehicleClass = vehicleClassMappingsData.find(
      (vehicleClassMapping: any) =>
        vehicleClassMapping?.organizationId === organizationId &&
        vehicleClassMapping?.vehicleClass === requestedVehicleClass
    );

    return matchingVehicleClass ? matchingVehicleClass?.name : "N/A";
  }
};

export const getVehicleTypesForRootOrg = (
  requestedVehicleClass: VehicleClassTypes,
  requestedVehicleType: DriverVehicleInformationVehicleTypes
) => {
  // if they select large just default to the requested vehicle type
  if (requestedVehicleClass === VehicleClassTypes.large) {
    return [requestedVehicleType];
  } else {
    // otherwise use the values from the VehicleClasses array
    return getVehicleTypesForVehicleClass(requestedVehicleClass);
  }
};

export const getVehicleTypesForNonRootOrg = (
  requestedVehicleClass: VehicleClassTypes
) => {
  return getVehicleTypesForVehicleClass(requestedVehicleClass);
};

export const prettyFormatArray = (arr: any[]) => {
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(", ") + " and " + last;
};

export const formatVehicleTypesForServiceCall = (
  vehicleTypes: DriverVehicleInformationVehicleTypes[]
) => {
  const vehicleTypesMapped = vehicleTypes.map(
    (vehicleType) => mappedVehicleTypes[vehicleType]
  );

  return prettyFormatArray(vehicleTypesMapped);
};

export const filterObjectByValues = (
  object: any,
  callback: (key: string, value: any) => any
) => {
  const entries = Object.entries(object);

  const filteredEntries = entries.filter(([key, value]) => {
    return callback(key, value);
  });

  const filteredObject = Object.fromEntries(filteredEntries);

  return filteredObject;
};

export const filterFormErrorObject = (object: { [key: string]: any }) => {
  const finalObject: { [key: string]: any } = {};

  for (let key in object) {
    finalObject[key] = filterObjectByValues(object[key], (key, value) => value);
  }

  return finalObject;
};

export const objectHasKeys = (object: any) => {
  return Boolean(Object.keys(object).length);
};

export const mapObjectValues = (
  object: any,
  callback: (key: string, value: any) => any
) => {
  return Object.keys(object).map((key: any, index: any) => {
    return callback(key, index);
  });
};

const luhnCheck = (val: string) => {
  let checksum = 0; // running checksum total
  let j = 1; // takes value of 1 or 2
  // Process each digit one by one starting from the last
  for (let i = val.length - 1; i >= 0; i--) {
    let calc = 0;
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(val.charAt(i)) * j;
    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1;
      calc = calc - 10;
    }
    // Add the units element to the checksum total
    checksum = checksum + calc;
    // Switch the value of j
    if (j == 1) {
      j = 2;
    } else {
      j = 1;
    }
  }
  //Check if it is divisible by 10 or not.
  return checksum % 10 == 0;
};

export const validateCardNumber = (cardNumber: string) => {
  //Check if the number contains only numeric value
  //and is of between 13 to 19 digits
  const regex = new RegExp("^[0-9]{13,19}$");
  if (!regex.test(cardNumber)) {
    return false;
  }

  return luhnCheck(cardNumber);
};

export const validateCouponDeductionType = (couponDeductionType: any) => {
  return [
    CouponDeductionType.amountOffInCents,
    CouponDeductionType.percentOff,
  ].includes(couponDeductionType);
};

export const validateCouponDurationType = (couponDurationType: any) => {
  return [
    CouponDurationType.once,
    CouponDurationType.oncePerCustomer,
    CouponDurationType.forever,
    CouponDurationType.repeating,
  ].includes(couponDurationType);
};

export const mappedCouponDeductionTypes: any = {
  [CouponDeductionType.amountOffInCents]: "Deducted in cents",
  [CouponDeductionType.percentOff]: "Percent off",
};

export const mappedCouponDurationTypes: any = {
  [CouponDurationType.once]: "Single Time Use",
  [CouponDurationType.oncePerCustomer]: "Once Per Customer",
  [CouponDurationType.repeating]: "Repeating",
  [CouponDurationType.forever]: "Forever",
};

export const mappedVehicleTypes: any = {
  CARGOVAN: "Cargo Van",
  PICKUPTRUCK: "Pick Up Truck",
  MINIVAN: "Minivan",
  SUV: "SUV",
  SEDAN: "Sedan",
};

export const getVehicleClassForVehicleType = (
  vehicleType?: DriverVehicleInformationVehicleTypes
) => {
  const vehicleClass = Object.keys(VehicleClasses).find((classes: any) => {
    return VehicleClasses[classes as keyof typeof VehicleClasses].some(
      (type) => type === vehicleType
    );
  });

  // typescript is a headache
  return vehicleClass as VehicleClassTypes;
};

export const handleVehicleTypeRender = ({
  adminData,
  vehicleClassMappingsData,
  vehicleType,
  organizationId,
}: {
  adminData: any;
  vehicleClassMappingsData: any;
  vehicleType: DriverVehicleInformationVehicleTypes;
  organizationId: string;
}) => {
  const isRootAdmin = isCurrentAdminFromRootOrganization(adminData);
  const mappedVehicleType = mappedVehicleTypes[vehicleType];
  const mappedVehicleClass = getVehicleClassForVehicleType(vehicleType);

  // exit early if admin is a root org admin looking at their own organization
  if (isRootAdmin && organizationId === adminData?.organizationId) {
    return mappedVehicleType ?? "N/A";
  }

  if (!vehicleClassMappingsData || !vehicleClassMappingsData?.length) {
    return null;
  }

  const matchingVehicleClass = vehicleClassMappingsData.find(
    (vehicleClassMapping: any) =>
      vehicleClassMapping?.organizationId === organizationId &&
      vehicleClassMapping?.vehicleClass === mappedVehicleClass
  );

  return matchingVehicleClass ? matchingVehicleClass?.name : "N/A";

  // if (isRootAdmin) {
  //   return mappedVehicleType;
  // } else {
  // const mappedVehicleClass = getVehicleClassForVehicleType(vehicleType);

  //   return mappedVehicleClass
  //     ? toTitleCase(mappedVehicleClass)
  //     : mappedVehicleType;
  // }
};

export const mappedStatuses: any = {
  PENDING: "Pending",
  PENDINGTRANSFER: "Pending Transfer",
  ACCEPTED: "Accepted",
  PICKUPARRIVAL: "Pick Up Arrival",
  INPROGRESS: "In Progress",
  DROPOFFARRIVAL: "Drop-off Arrival",
  COMPLETED: "Completed",
  DROPOFFCOMPLETED: "Drop-off Completed",
  DRIVERCANCELLED: "Driver Cancelled",
  SCHEDULEDDECLINED: "Declined due to no matching drivers",
  DRIVERCANCELLEDDUETONOSHOWATPICKUP:
    "Driver cancelled due to customer no-show",
  DRIVERCANCELLEDATINSPECTION: "Driver cancelled at inspection",
  CUSTOMERCANCELLEDWITHINGRACEPERIOD: "Customer cancelled within grace period",
  CUSTOMERCANCELLEDBEFOREPICKUP: "Customer cancelled before pickup",
  CUSTOMERCANCELLED: "Customer cancelled",
  CUSTOMERPAYMENTFAILED: "Customer payment method failed",
};
