import { Divider, Grid, List } from "@mui/material";
import AdditionalChargesListitem from "./AdditionalChargesListItem";

const AdditionalChargesList = ({
  additionalPickUpRequestChargesData,
  onItemEditClick,
  onItemDeleteClick,
}: {
  additionalPickUpRequestChargesData: any;
  onItemEditClick: (additionalCharge: any) => void;
  onItemDeleteClick: (additionalCharge: any) => void;
}) => {
  return (
    <List>
      {additionalPickUpRequestChargesData?.map(
        (additionalCharge: any, index: number) => (
          <AdditionalChargesListitem
            key={`additional-charge-list-item-${additionalCharge?.id}-${index}`}
            additionalChargeId={additionalCharge?.id}
            name={additionalCharge?.name}
            price={additionalCharge?.priceInCents}
            description={additionalCharge?.description}
            enabled={additionalCharge?.enabled}
            onEditClick={() => {
              onItemEditClick(additionalCharge);
            }}
            onDeleteClick={() => {
              onItemDeleteClick(additionalCharge);
            }}
          />
        )
      )}
    </List>
  );
};

export default AdditionalChargesList;
