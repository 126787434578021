import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { useContext, useState } from "react";
import Button from "../../components/Button/Button";
import { PeopleAlt, Person } from "@mui/icons-material";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import {
  convertToKilometers,
  roundToPrecision,
} from "../../helpers/formatters";
import {
  mappedVehicleTypes,
  toTitleCase,
} from "../../helpers/validationHelpers";
import { ReactComponent as PickUpTruckIcon } from "../../assets/icons/pickup-truck-icon.svg";
import { ReactComponent as CargoVanIcon } from "../../assets/icons/cargo-van-icon.svg";
import TabSection from "../../components/TabSection/TabSection";
import AccountDetails from "./AccountDetails/AccountDetails";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
// import { AuthContext } from "../../context/authContext";

const Settings = () => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <DashboardLayout noGutters loading={false}>
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        mt={4}
        mb={2}
        px={{ xs: 0, sm: 10, md: 20, lg: 30 }}
      >
        <Grid item>
          <Typography fontSize={30} fontWeight={500}>
            Settings
          </Typography>
        </Grid>
        <AccountDetails />
      </Grid>
    </DashboardLayout>
  );
};

export default Settings;
