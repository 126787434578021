import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  createTheme,
  Box,
} from "@mui/material";
import VirtualFleetHub from "../../../assets/images/virtualfleethub-logo.png";
import {
  MailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import {
  isAnyKeyValueTrue,
  validateEmail,
  validatePassword,
} from "../../../helpers/validationHelpers";
import Button from "../../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { getCognitoAccessToken } from "../../../helpers/authHelpers";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import { useAuth } from "../../../hooks/useAuth";
import { isNetworkError } from "../../../utils/network";
const RightLoginSection = () => {
  const { login, parseLoginData, setToken } = useAuth();
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordBlurred, setPasswordBlurred] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");

  const errors = {
    email: {
      empty: !email && "Please enter an email",
      invalidEmail:
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email address",
    },
    password: {
      empty: !password && "Please enter a password",
      invalidPassword:
        password.length > 0 &&
        !validatePassword(password) &&
        "Password must have 1 uppercase character, 1 lowercase character, 1 digit, and 1 special character and be 8-99 characters long.",
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = await login({ emailOrPhoneNumber: email, password });
      const parsedLoginData = parseLoginData(data);

      const tokenData = await setToken(data, rememberMe);

      setAuthDetails((prev) => ({
        ...prev,
        ...tokenData,
      }));

      // navigate("/dashboard");
    } catch (error: any) {
      console.log("error", error);
      isNetworkError(error)
        ? setError("No internet connection")
        : setError(error ?? "Unable to login");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const buttonDisabled =
    Boolean(isAnyKeyValueTrue(errors.password)) ||
    Boolean(isAnyKeyValueTrue(errors.email));

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      item
      xs={12}
      md={5}
      sx={{ background: "#fff" }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setOpenSnackbar(false);
          setError("");
        }}
        message={error}
        action={
          <Box
            onClick={() => {
              setOpenSnackbar(false);
              setError("");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: "#f26822" }}>Dismiss</Typography>
          </Box>
        }
      />
      <Grid item mt={"13vh"} mb={"14vh"}>
        <img
          src={VirtualFleetHub}
          style={{ maxHeight: "100%", height: "auto", maxWidth: 380 }}
        />
      </Grid>
      <Grid
        maxWidth={380}
        container
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item>
          <Typography variant="h2" fontSize={36} fontWeight={700} mb={4}>
            Sign in
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={2}
        >
          <Grid item width={"100%"}>
            <TextField
              fullWidth
              value={email}
              onFocus={() => {
                setEmailBlurred(false);
              }}
              onBlur={(e) => {
                setEmailBlurred(true);
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
              label="Email Address"
              helperText={emailBlurred && isAnyKeyValueTrue(errors.email)}
              error={emailBlurred && Boolean(isAnyKeyValueTrue(errors.email))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>
          <Grid item width={"100%"}>
            <TextField
              required
              type={showPassword ? "text" : "password"}
              label="Password"
              fullWidth
              value={password}
              onFocus={() => {
                setPasswordBlurred(false);
              }}
              onBlur={(e) => {
                setPasswordBlurred(true);
              }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              helperText={passwordBlurred && isAnyKeyValueTrue(errors.password)}
              error={
                passwordBlurred && Boolean(isAnyKeyValueTrue(errors.password))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
          </Grid>

          <Grid
            container
            item
            alignContent={"center"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(242, 104, 34, 0.04)",
                    },
                    "&.Mui-checked": {
                      color: "#f26822",
                    },
                  }}
                  value={rememberMe}
                  onChange={(e) => {
                    setRememberMe(e.target.checked);
                  }}
                />
              }
              sx={{ color: "#868686" }}
              label="Remember me"
            />
            <Grid item>
              <Link to="/forgot-password">Forgot password?</Link>
            </Grid>
          </Grid>

          <Grid item width="100%">
            <Button disabled={buttonDisabled} fullWidth onClick={handleSubmit}>
              Sign In
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightLoginSection;
