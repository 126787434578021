import { Grid, TextField, Typography } from "@mui/material";
import ImageUploadSection from "../../../components/ImageUploadSection/ImageUploadSection";
import { Dispatch, SetStateAction, useState } from "react";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  validatePresence,
  validateStripeApiKey,
} from "../../../helpers/validationHelpers";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { uploadAssetToCloudinary } from "../../../utils/cloudinary";
import GoogleMapsAutocomplete from "../../../components/GoogleMapsAutoComplete/GoogleMapsAutoComplete";

const OrganizationDetailsForm = ({
  name,
  setName,
  logo,
  setLogo,
  logoUploadLoading,
  setLogoUploadLoading,
  companyLocation,
  setCompanyLocation,
  stripeTestPublishableKey,
  setStripeTestPublishableKey,
  stripeProdPublishableKey,
  setStripeProdPublishableKey,
  stripeTestApiKey,
  setStripeTestApiKey,
  stripeProdApiKey,
  setStripeProdApiKey,
  accessToken,
}: {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  logo: string;
  setLogo: Dispatch<SetStateAction<string>>;
  logoUploadLoading: boolean;
  setLogoUploadLoading: Dispatch<SetStateAction<boolean>>;
  companyLocation: any;
  setCompanyLocation: Dispatch<SetStateAction<any>>;
  stripeTestPublishableKey: string;
  setStripeTestPublishableKey: Dispatch<SetStateAction<string>>;
  stripeProdPublishableKey: string;
  setStripeProdPublishableKey: Dispatch<SetStateAction<string>>;
  stripeTestApiKey: string;
  setStripeTestApiKey: Dispatch<SetStateAction<string>>;
  stripeProdApiKey: string;
  setStripeProdApiKey: Dispatch<SetStateAction<string>>;
  accessToken: string;
}) => {
  const [companyLocationText, setCompanyLocationText] = useState("");

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      name,
      companyLocation,
      stripeTestPublishableKey,
      stripeProdPublishableKey,
      stripeTestApiKey,
      stripeProdApiKey,
    });

  "skssk".startsWith("sk_test");

  const formErrors = {
    name: {
      error:
        blurredFormFields.name &&
        !validatePresence(name) &&
        "Please enter an organization name",
    },
    companyLocation: {
      error:
        blurredFormFields.companyLocation &&
        !companyLocation &&
        "Please enter an address for your organization",
    },
    stripeTestPublishableKey: {
      error:
        blurredFormFields.stripeTestPublishableKey &&
        !validatePresence(stripeTestPublishableKey) &&
        "Please enter your Stripe test publishable key",
      invalidKey:
        blurredFormFields.stripeTestPublishableKey &&
        validatePresence(stripeTestPublishableKey) &&
        !validateStripeApiKey({
          keyType: "publishable",
          environment: "test",
          apiKey: stripeTestPublishableKey,
        }) &&
        "A valid Stripe test publishable key must start with pk_test_ and is at least 32 characters long",
    },
    stripeProdPublishableKey: {
      error:
        blurredFormFields.stripeProdPublishableKey &&
        !validatePresence(stripeProdPublishableKey) &&
        "Please enter your Stripe production publishable key",
      invalidKey:
        blurredFormFields.stripeProdPublishableKey &&
        validatePresence(stripeProdPublishableKey) &&
        !validateStripeApiKey({
          keyType: "publishable",
          environment: "prod",
          apiKey: stripeProdPublishableKey,
        }) &&
        "A valid Stripe production publishable key must start with pk_live_ and is at least 32 characters long",
    },
    stripeTestApiKey: {
      error:
        blurredFormFields.stripeTestApiKey &&
        !validatePresence(stripeTestApiKey) &&
        "Please enter your Stripe test secret key",
      invalidKey:
        blurredFormFields.stripeTestApiKey &&
        validatePresence(stripeTestApiKey) &&
        !validateStripeApiKey({
          keyType: "secret",
          environment: "test",
          apiKey: stripeTestApiKey,
        }) &&
        "A valid Stripe test secret key must start with sk_test_ and is at least 32 characters long",
    },
    stripeProdApiKey: {
      error:
        blurredFormFields.stripeProdApiKey &&
        !validatePresence(stripeProdApiKey) &&
        "Please enter your Stripe production secret key",
      invalidKey:
        blurredFormFields.stripeProdApiKey &&
        validatePresence(stripeProdApiKey) &&
        !validateStripeApiKey({
          keyType: "secret",
          environment: "prod",
          apiKey: stripeProdApiKey,
        }) &&
        "A valid Stripe production secret key must start with sk_live_ and is at least 32 characters long",
    },
  };
  const nameErrors: any = filterObjectByValues(
    formErrors?.name,
    (key, value) => value
  );
  const companyLocationErrors: any = filterObjectByValues(
    formErrors?.companyLocation,
    (key, value) => value
  );
  const stripeTestPublishableKeyErrors: any = filterObjectByValues(
    formErrors?.stripeTestPublishableKey,
    (key, value) => value
  );
  const stripeProdPublishableKeyErrors: any = filterObjectByValues(
    formErrors?.stripeProdPublishableKey,
    (key, value) => value
  );

  const stripeTestApiKeyErrors: any = filterObjectByValues(
    formErrors?.stripeTestApiKey,
    (key, value) => value
  );
  const stripeProdApiKeyErrors: any = filterObjectByValues(
    formErrors?.stripeProdApiKey,
    (key, value) => value
  );
  const handleOrganizationLogoUpload = async (file: any) => {
    setLogoUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "organization-logos");
      setLogo(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLogoUploadLoading(false);
    }
  };
  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"Organization Name"}
            fullWidth
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("name");
            }}
            onBlur={() => {
              setFormFieldToBlurred("name");
            }}
            error={objectHasKeys(nameErrors)}
          />
          {mapObjectValues(nameErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`organization-create-name-errors-${index}`}
              errorText={nameErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <GoogleMapsAutocomplete
            error={objectHasKeys(companyLocationErrors)}
            onFocus={() => {
              setFormFieldToFocused("companyLocation");
            }}
            onBlur={() => {
              setFormFieldToBlurred("companyLocation");
            }}
            inputValue={companyLocationText}
            setInputValue={setCompanyLocationText}
            value={companyLocation}
            setValue={setCompanyLocation}
            accessToken={accessToken}
            label={"Company address"}
          />
          {mapObjectValues(companyLocationErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`organization-create-address-errors-${index}`}
              errorText={companyLocationErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"Stripe test publishable key"}
            placeholder="pk_test_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            fullWidth
            required
            value={stripeTestPublishableKey}
            onChange={(e) => {
              setStripeTestPublishableKey(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("stripeTestPublishableKey");
            }}
            onBlur={() => {
              setFormFieldToBlurred("stripeTestPublishableKey");
            }}
            error={objectHasKeys(stripeTestPublishableKeyErrors)}
          />
          {mapObjectValues(
            stripeTestPublishableKeyErrors,
            (key: any, index: any) => (
              <ErrorHelperText
                key={`organization-create-stripe-test-publishable-key-errors-${index}`}
                errorText={stripeTestPublishableKeyErrors[key]}
              />
            )
          )}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"Stripe production publishable key"}
            placeholder="pk_live_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            fullWidth
            required
            value={stripeProdPublishableKey}
            onChange={(e) => {
              setStripeProdPublishableKey(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("stripeProdPublishableKey");
            }}
            onBlur={() => {
              setFormFieldToBlurred("stripeProdPublishableKey");
            }}
            error={objectHasKeys(stripeProdPublishableKeyErrors)}
          />
          {mapObjectValues(
            stripeProdPublishableKeyErrors,
            (key: any, index: any) => (
              <ErrorHelperText
                key={`organization-create-stripe-prod-publishable-key-errors-${index}`}
                errorText={stripeProdPublishableKeyErrors[key]}
              />
            )
          )}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"Stripe test secret key"}
            placeholder="sk_test_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            fullWidth
            required
            value={stripeTestApiKey}
            onChange={(e) => {
              setStripeTestApiKey(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("stripeTestApiKey");
            }}
            onBlur={() => {
              setFormFieldToBlurred("stripeTestApiKey");
            }}
            error={objectHasKeys(stripeTestApiKeyErrors)}
          />
          {mapObjectValues(stripeTestApiKeyErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`organization-create-stripe-test-api-key-errors-${index}`}
              errorText={stripeTestApiKeyErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={"Stripe production secret key"}
            placeholder="sk_live_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            fullWidth
            required
            value={stripeProdApiKey}
            onChange={(e) => {
              setStripeProdApiKey(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("stripeProdApiKey");
            }}
            onBlur={() => {
              setFormFieldToBlurred("stripeProdApiKey");
            }}
            error={objectHasKeys(stripeProdApiKeyErrors)}
          />
          {mapObjectValues(stripeProdApiKeyErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`organization-stripe-prod-api-key-errors-${index}`}
              errorText={stripeProdApiKeyErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item>
          <Typography>Organization Logo:</Typography>
        </Grid>
        <ImageUploadSection
          uploadedFile={logo}
          loading={logoUploadLoading}
          label={"Upload company logo"}
          handleUpload={handleOrganizationLogoUpload}
        />
      </Grid>
    </>
  );
};

export default OrganizationDetailsForm;
