import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";

const TabSection = ({
  tabIndex,
  tabHeaders,
  handleTabChange,
  children,
}: {
  tabIndex: number;
  tabHeaders: string[];
  handleTabChange: (index: any) => void;
  children: ReactElement;
}) => {
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#f26822",
      },
    },
    tab: {
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#f26822",
      },
    },
  };

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(event, value) => {
          handleTabChange(value);
        }}
        sx={styles.tabs}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabHeaders.map((label, index) => (
          <Tab sx={styles.tab} label={label} value={index} />
        ))}
      </Tabs>
      {children}
    </>
  );
};

export default TabSection;
