import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { useDrivers } from "../../hooks/useDrivers";

import {
  getAccessToken,
  isCurrentAdminFromRootOrganization,
  isCurrentAdminSuperAdmin,
} from "../../helpers/authHelpers";
import { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import {
  getScheduledServiceCallsCSV,
  getUpcomingScheduledServiceCallsCSV,
  useScheduledServiceCalls,
} from "../../hooks/useScheduledServiceCalls";
import {
  PickUpStatus,
  cancelledStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  mappedVehicleTypes,
  pendingStatuses,
  renderVehicleTypesForServiceCall,
} from "../../helpers/validationHelpers";
import { roundToPrecision } from "../../helpers/formatters";
import moment from "moment";
import Button from "../../components/Button/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useDefaultOrganizationLocation } from "../../hooks/useOrganizationLocations";
import { useAllVehicleClassMappings } from "../../hooks/useVehicleClassMappings";

const ScheduledServiceCalls = () => {
  const {
    csvDownloadLoading: scheduledServiceCallsCsvDownloadLoading,
    setCsvDownloadLoading: setScheduledServiceCallsCsvDownloadLoading,
    csvData: scheduledServiceCallsCsvData,
    setCsvFilename: setScheduledServiceCallsCsvFilename,
    setCsvData: setScheduledServiceCallsCsvData,
    csvLinkRef: scheduledServiceCallsCsvLinkRef,
    setShouldDownload: setShouldDownloadScheduledServiceCallsCsv,
  } = useCSVDownloadState();
  const {
    csvDownloadLoading: upcomingScheduledServiceCallsCsvDownloadLoading,
    setCsvDownloadLoading: setUpcomingScheduledServiceCallsCsvDownloadLoading,
    csvData: upcomingScheduledServiceCallsCsvData,
    setCsvData: setUpcomingScheduledServiceCallsCsvData,
    setCsvFilename: setUpcomingScheduledServiceCallsCsvFilename,
    csvLinkRef: upcomingScheduledServiceCallsCsvLinkRef,
    setShouldDownload: setShouldDownloadUpcomingScheduledServiceCallsCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: serviceCallsData,
    isLoading,
    isFetching,
  } = useScheduledServiceCalls({
    accessToken: authDetails?.accessToken,
    organizationId: isCurrentAdminFromRootOrganization(authDetails)
      ? undefined
      : authDetails?.organizationId,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const { data: defaultOrganizationLocation } = useDefaultOrganizationLocation({
    accessToken: authDetails?.accessToken,
    organizationId: authDetails?.organizationId,
  });

  const {
    data: vehicleClassMappingsData,
    isLoading: isVehicleClassMappingsLoading,
  } = useAllVehicleClassMappings({
    accessToken: authDetails.accessToken,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    console.log("model", model);
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const renderStatus = (pickUpStatus: PickUpStatus) => {
    if (cancelledStatuses.includes(pickUpStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#FD5554" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {pickUpStatus === PickUpStatus.scheduledDeclined
                ? "Declined"
                : "Cancelled"}
            </Typography>
          </Grid>
        </Grid>
      );
    } else if (pendingStatuses.includes(pickUpStatus)) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#1162FB" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>Pending</Typography>
          </Grid>
        </Grid>
      );
    } else if (
      [
        PickUpStatus.accepted,
        PickUpStatus.inProgress,
        PickUpStatus.dropOffArrival,
        PickUpStatus.pickUpArrival,
      ].includes(pickUpStatus)
    ) {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#1162FB" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>In Progress</Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography sx={{ color: "#87C43B" }}>●</Typography>
          </Grid>
          <Grid item>
            <Typography>Completed</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "pickUpStatus",
      headerName: "Status",
      width: 150,
      renderCell: (params) => renderStatus(params.row.pickUpStatus),
    },
    {
      field: "id",
      headerName: "Service Call ID",
      width: 100,
      renderCell: (params) => (
        <Link
          to={`/dashboard/scheduled-service-calls/${params.row.id}`}
          state={{ prevPage: location.pathname }}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 125,

      valueGetter: (params) =>
        `${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`,
      renderCell: (params) =>
        params.row.customer ? (
          <Link
            to={`/dashboard/customers/${params?.row?.customer?.id}`}
            state={{ prevPage: location.pathname }}
          >
            {params?.row?.customer?.firstName} {params?.row?.customer?.lastName}
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "driverName",
      headerName: "Driver Name",
      // type: "number",
      width: 125,
      valueGetter: (params) =>
        params?.row?.driver
          ? `${params?.row?.driver?.firstName} ${params?.row?.driver?.lastName}`
          : "N/A",
      renderCell: (params) =>
        params?.row?.driver ? (
          <Link
            to={`/dashboard/drivers/${params?.row?.driver?.id}`}
            state={{ prevPage: location.pathname }}
          >
            {params?.row?.driver?.firstName} {params?.row?.driver?.lastName}
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "scheduledFor",
      headerName: "Scheduled For",
      width: 225,
      valueGetter: (params) =>
        params?.row?.scheduledPickUpRequest?.scheduledFor,
      renderCell: (params) => (
        <Typography>
          {moment(params?.row?.scheduledPickUpRequest?.scheduledFor).format(
            "Do MMMM  YYYY, h:mm a"
          )}
        </Typography>
      ),
    },
    {
      field: "pickUpAddress",
      headerName: "Pick Up Address",
      width: 200,
      valueGetter: (params) =>
        params?.row?.pickUpRequestLocationDetails?.originAddress,
    },
    {
      field: "destinationAddress",
      headerName: "Drop Off Address",
      width: 200,
      valueGetter: (params) =>
        params?.row?.pickUpRequestLocationDetails?.destinationAddress,
    },
    {
      field: "pickUpCode",
      headerName: "Pick Up Code",
      width: 100,
      valueGetter: (params) => params.row.pickUpCode ?? "N/A",
    },
    {
      field: "distanceTravelledInMeters",
      headerName: "Total Dist. (KM)",
      // type: "number",
      // width: 200,
      valueGetter: (params) =>
        params.row.distanceTravelledInMeters
          ? roundToPrecision(params.row.distanceTravelledInMeters / 1000, 2)
          : "N/A",
    },
    {
      field: "totalFare",
      headerName: "Total Fare ($)",
      valueGetter: (params) =>
        roundToPrecision(
          (params?.row?.price +
            params?.row?.taxAmountInCents +
            params?.row?.tipAmountInCents) /
            100,
          2
        ),
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Types",
      valueGetter: (params) => {
        // const vehicleTypes = getVehicleTypesForServiceCall(
        //   params.row.pickUpRequestDetails?.requestedVehicleClass,
        //   params.row.pickUpRequestDetails?.requestedVehicleType,
        //   authDetails
        // );

        // return vehicleTypes.length > 0
        //   ? formatVehicleTypesForServiceCall(vehicleTypes)
        //   : "N/A";

        return renderVehicleTypesForServiceCall({
          adminData: authDetails,
          vehicleClassMappingsData,
          requestedVehicleClass:
            params.row.pickUpRequestDetails?.requestedVehicleClass,
          requestedVehicleType:
            params.row.pickUpRequestDetails?.requestedVehicleType,
          organizationId: params?.row?.organizationId,
        });
      },
    },
    {
      field: "stripeReceiptUrl",
      headerName: "Receipt",
      width: 60,
      renderCell: (params: GridCellParams) =>
        params?.row?.PreauthorizedPayment?.stripeReceiptUrl ? (
          <Link to={params?.row?.PreauthorizedPayment?.stripeReceiptUrl}>
            Stripe
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/scheduled-service-calls/${params.row.id}`, {
              state: {
                prevPage: location.pathname,
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  console.log("driversData", serviceCallsData);

  // useEffect(() => {
  //   if (
  //     csvData &&
  //     !csvDownloadLoading &&
  //     csvLinkRef?.current &&
  //     shouldDownload
  //   ) {
  //     csvLinkRef.current.download = `Muve-It-Scheduled-Service-Calls-${moment().valueOf()}`;
  //     csvLinkRef?.current?.click();
  //     setShouldDownload(false);
  //   }
  // }, [csvData, csvDownloadLoading, csvLinkRef]);

  const downloadUpcomingScheduledServiceCallsCSV = async () => {
    setUpcomingScheduledServiceCallsCsvDownloadLoading(true);
    try {
      const result = await getUpcomingScheduledServiceCallsCSV(
        authDetails?.accessToken,
        isCurrentAdminFromRootOrganization(authDetails)
          ? undefined
          : authDetails?.organizationId
      );
      setUpcomingScheduledServiceCallsCsvData(result?.data);
      setUpcomingScheduledServiceCallsCsvFilename(result?.filename);
      console.log("csvLinkRef", upcomingScheduledServiceCallsCsvLinkRef);
      setShouldDownloadUpcomingScheduledServiceCallsCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setUpcomingScheduledServiceCallsCsvDownloadLoading(false);
    }
  };

  const downloadScheduledServiceCallsCSV = async () => {
    setScheduledServiceCallsCsvDownloadLoading(true);
    try {
      const result = await getScheduledServiceCallsCSV(
        authDetails?.accessToken,
        isCurrentAdminFromRootOrganization(authDetails)
          ? undefined
          : authDetails?.organizationId
      );
      setScheduledServiceCallsCsvData(result?.data);
      setScheduledServiceCallsCsvFilename(result?.filename);
      setShouldDownloadScheduledServiceCallsCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setScheduledServiceCallsCsvDownloadLoading(false);
    }
  };

  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/scheduled-service-calls",
            title: "Scheduled Service Calls",
          },
        ]}
        pageTitle="Scheduled Service Calls"
      />
      {defaultOrganizationLocation?.slug &&
        !isCurrentAdminFromRootOrganization(authDetails) && (
          <Grid container item justifyContent={"flex-end"}>
            <Grid item>
              <Button
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_COMPANY_WEBSITE_URL}/organization/${defaultOrganizationLocation?.slug}`
                  );
                }}
                icon={<LocalShippingIcon sx={{ marginRight: 2 }} />}
              >
                Create new Scheduled Service Call
              </Button>
            </Grid>
          </Grid>
        )}
      <Grid container>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching || isVehicleClassMappingsLoading}
          rows={serviceCallsData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          exportButtonLoading={scheduledServiceCallsCsvDownloadLoading}
          onExportButtonClick={downloadScheduledServiceCallsCSV}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={serviceCallsData?.totalCount}
          pageCount={serviceCallsData?.totalPages}
        />
      </Grid>
      <Grid
        container
        justifyContent={"flex-end"}
        paddingTop={2}
        paddingBottom={4}
      >
        <Grid item>
          <Button
            fontSize={14}
            icon={<SaveAltIcon sx={{ marginRight: 2 }} />}
            onClick={(e: any) => {
              downloadUpcomingScheduledServiceCallsCSV();
            }}
            loading={upcomingScheduledServiceCallsCsvDownloadLoading}
          >
            Export Service Calls for next 7 days
          </Button>
          {upcomingScheduledServiceCallsCsvData && (
            <a
              style={{ display: "none" }}
              ref={upcomingScheduledServiceCallsCsvLinkRef}
              href={`data:text/csv;charset=utf-8,${escape(
                upcomingScheduledServiceCallsCsvData
              )}`}
              download="filename.csv"
            >
              download
            </a>
          )}
          {scheduledServiceCallsCsvData && (
            <a
              style={{ display: "none" }}
              ref={scheduledServiceCallsCsvLinkRef}
              href={`data:text/csv;charset=utf-8,${escape(
                scheduledServiceCallsCsvData
              )}`}
              download="filename.csv"
            >
              download
            </a>
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ScheduledServiceCalls;
