import { GridSortModel } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";

const getServiceCallSubmissionAttempts = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string,
  organizationId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/service-call-submission-attempts?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}${
          organizationId ? `&organizationId=${organizationId}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getServiceCallSubmissionAttemptDetails = async (
  accessToken: string,
  serviceCallSubmissionAttemptId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/service-call-submission-attempt/${serviceCallSubmissionAttemptId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return {
        ...data,
        // this is a JSON field in the DB
        formFields: data?.formFields ? JSON.parse(data?.formFields) : null,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getServiceCallSubmissionAttemptsCSV = async (
  accessToken: string,
  organizationId?: string
) => {
  try {
    const { data, headers } = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/admin/service-call-submission-attempts-csv${
        organizationId ? `?organizationId=${organizationId}` : ""
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const useServiceCallSubmissionAttempts = ({
  accessToken,
  page = 0,
  search,
  sort,
  organizationId,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: [
      "service-call-submission-attempts",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
      ...(organizationId ? [organizationId] : []),
    ],
    queryFn: () =>
      getServiceCallSubmissionAttempts(
        accessToken,
        page,
        search,
        sort && sort?.length > 0
          ? qs.stringify({ sortBy: sort[0] })
          : undefined,
        organizationId
      ),
    keepPreviousData: true,
  });
};

const useServiceCallSubmissionAttemptDetails = (
  accessToken: string,
  serviceCallId?: string
) => {
  return useQuery({
    queryKey: ["service-call-submission-attempt", serviceCallId],
    queryFn: () =>
      getServiceCallSubmissionAttemptDetails(accessToken, serviceCallId),
  });
};

export {
  useServiceCallSubmissionAttempts,
  getServiceCallSubmissionAttempts,
  getServiceCallSubmissionAttemptsCSV,
  getServiceCallSubmissionAttemptDetails,
  useServiceCallSubmissionAttemptDetails,
};
