import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  createTheme,
  Box,
} from "@mui/material";
import MuveIt from "../../../assets/images/muveit.png";
import {
  MailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useContext, useState } from "react";
import {
  isAnyKeyValueTrue,
  validateEmail,
  validatePassword,
} from "../../helpers/validationHelpers";
import Button from "../Button/Button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";
import { adminResetPassword } from "../../hooks/useAdminUser";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const accessToken: any = searchParams.get("token");

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");
  //   page 2
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  //   const [page, setPage] = useState(0);

  const handleChangePasswordSubmit = async () => {
    setLoading(true);
    try {
      const data = await adminResetPassword({ newPassword, accessToken });
      console.log("data", data);
      navigate("/login");
    } catch (error: any) {
      console.log("error", error);
      setError(error ?? "Authorization failed");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      maxWidth={380}
      container
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignContent={"center"}
      alignItems={"flex-start"}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setOpenSnackbar(false);
          setError("");
        }}
        message={error}
        action={
          <Box
            onClick={() => {
              setOpenSnackbar(false);
              setError("");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: "#f26822" }}>Dismiss</Typography>
          </Box>
        }
      />
      <ChangePasswordForm
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        newPasswordConfirm={newPasswordConfirm}
        setNewPasswordConfirm={setNewPasswordConfirm}
        loading={loading}
        handleSubmit={handleChangePasswordSubmit}
        submitButtonLabel="Change Password"
      />
    </Grid>
  );
};

export default ResetPasswordForm;
