import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getVehicleClassMappings = async ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/organization-vehicle-class-mappings/${organizationId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getAllVehicleClassMappings = async ({
  accessToken,
}: {
  accessToken: string;
}) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/all-organization-vehicle-class-mappings`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error fetching all vehicle class mappings", error);
  }
};

const updateVehicleClassMappings = async ({
  accessToken,
  vehicleClassMappingId,
  formFields,
}: {
  accessToken: string;
  vehicleClassMappingId: string;
  formFields: {
    name: string;
    enabled: boolean;
    iconUrl?: string;
    rateInPercent: number;
    baseFee: number;
    perKmRate: number;
    perMinuteRate: number;
    driverInitiatedCancellationChargePrePickup: number;
    driverInitiatedCancellationChargePostPickup: number;
    customerInitiatedCancellationChargePrePickup: number;
    customerInitiatedCancellationChargePostPickup: number;
  };
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organization-vehicle-class-mappings/${vehicleClassMappingId}`,
      formFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const useOrganizationVehicleClassMappings = ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: ["vehicle-class-mappings", organizationId],
    queryFn: () => getVehicleClassMappings({ accessToken, organizationId }),
  });
};

const useAllVehicleClassMappings = ({
  accessToken,
}: {
  accessToken: string;
}) => {
  return useQuery({
    queryKey: ["all-vehicle-class-mappings"],
    queryFn: () => getAllVehicleClassMappings({ accessToken }),
  });
};

export {
  updateVehicleClassMappings,
  useOrganizationVehicleClassMappings,
  useAllVehicleClassMappings,
};
