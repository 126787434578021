import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  filterObjectByValues,
  isAnyKeyValueTrue,
  mapObjectValues,
  objectHasKeys,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
  validateStripeApiKey,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCustomerProfile } from "../../../hooks/useCustomers";
import {
  createCompany,
  updateCompanyProfile,
} from "../../../hooks/useCompanies";
import ImageUploadSection from "../../../components/ImageUploadSection/ImageUploadSection";
import { uploadAssetToCloudinary } from "../../../utils/cloudinary";
import {
  createOrganization,
  validateOrganizationCreate,
} from "../../../hooks/useOrganizations";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import PhoneNumberTextField from "../../../components/PhoneNumberTextField/PhoneNumberTextField";
import OrganizationDetailsForm from "./OrganizationDetailsForm";
import InitialAdminUserForm from "./InitialAdminUserForm";

const OrganizationCreateModal = ({ accessToken, open, setOpen }: any) => {
  const queryClient = useQueryClient();

  // shared form state
  const [page, setPage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // company fields
  const [name, setName] = useState("");
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const [detailsSubmitLoading, setDetailSubmitLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [companyLocation, setCompanyLocation] = useState<any>(null);

  // stripe keys
  const [stripeTestPublishableKey, setStripeTestPublishableKey] = useState("");
  const [stripeProdPublishableKey, setStripeProdPublishableKey] = useState("");
  const [stripeTestApiKey, setStripeTestApiKey] = useState("");
  const [stripeProdApiKey, setStripeProdApiKey] = useState("");
  // initial admin fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
  });

  const resetFormFields = () => {
    setName("");
    setLogo("");
    setStripeTestPublishableKey("");
    setStripeProdPublishableKey("");
    setStripeTestApiKey("");
    setStripeProdApiKey("");
    setCompanyLocation(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    resetBlurState();
    setShowError(false);
    setErrorMessage("");
    setShowSuccess(false);
  };

  useEffect(() => {
    if (!open) {
      resetFormFields();
    }
  }, [open]);

  const formErrors = {
    name: {
      error:
        blurredFormFields.name &&
        !validatePresence(name) &&
        "Please enter an organization name",
    },
    firstName: {
      empty:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      empty:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    phoneNumber: {
      empty:
        blurredFormFields?.phoneNumber &&
        !validatePresence(phoneNumber) &&
        "Please enter a phone number",
      invalid:
        blurredFormFields?.phoneNumber &&
        phoneNumber.length > 0 &&
        !validatePhoneNumber(phoneNumber) &&
        "Please enter a valid phone number",
    },
    email: {
      empty:
        blurredFormFields?.email &&
        !validatePresence(email) &&
        "Please enter an email",
      invalid:
        blurredFormFields?.email &&
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email",
    },
  };

  const submitButtonDisabled =
    !validatePresence(name) ||
    !validatePresence(firstName) ||
    !validatePresence(lastName) ||
    !validatePresence(phoneNumber) ||
    !validatePhoneNumber(phoneNumber) ||
    !validatePresence(email) ||
    !validateEmail(email);

  const {
    mutate: handleOrganizationCreate,
    isLoading: isOrganizationCreateLoading,
  } = useMutation({
    mutationFn: createOrganization,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.invalidateQueries({ queryKey: ["organizations"] });
      setShowSuccess(true);
      handleClose();
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  console.log("companyLocation", companyLocation);

  const handleFinalSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleOrganizationCreate({
      accessToken,
      organizationFields: {
        name,
        logo,
        companyAddress: companyLocation?.name,
        companyLocationLatitude: companyLocation?.latitude,
        companyLocationLongitude: companyLocation?.longitude,
        stripeTestPublishableKey,
        stripeProdPublishableKey,
        stripeTestApiKey,
        stripeProdApiKey,
      },
      initialAdminFields: {
        firstName,
        lastName,
        email,
        phoneNumber,
      },
    });
  };

  const handleOrganizationLogoUpload = async (file: any) => {
    setLogoUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "organization-logos");
      setLogo(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLogoUploadLoading(false);
    }
  };

  const nameErrors: any = filterObjectByValues(
    formErrors?.name,
    (key, value) => value
  );

  const firstNameErrors: any = filterObjectByValues(
    formErrors?.firstName,
    (key, value) => value
  );
  const lastNameErrors: any = filterObjectByValues(
    formErrors?.lastName,
    (key, value) => value
  );
  const phoneNumberErrors: any = filterObjectByValues(
    formErrors?.phoneNumber,
    (key, value) => value
  );

  const emailErrors: any = filterObjectByValues(
    formErrors?.email,
    (key, value) => value
  );

  const handleClose = () => {
    setOpen(false);
    setPage(0);
    resetFormFields();
  };

  const handleOrganizationDetailsSubmit = async () => {
    setDetailSubmitLoading(true);
    try {
      const data = await validateOrganizationCreate({
        accessToken,
        organizationFields: {
          name,
          logo,
          companyAddress: companyLocation?.name,
          companyLocationLatitude: companyLocation?.latitude,
          companyLocationLongitude: companyLocation?.longitude,
          stripeTestPublishableKey,
          stripeProdPublishableKey,
          stripeTestApiKey,
          stripeProdApiKey,
        },
      });
      console.log("data", data);

      setPage((prev) => prev + 1);
    } catch (error: any) {
      setShowError(true);
      setErrorMessage(error);
    } finally {
      setDetailSubmitLoading(false);
    }
  };

  const pages = [
    {
      title: "Organization Details",
      component: (
        <OrganizationDetailsForm
          name={name}
          setName={setName}
          logo={logo}
          setLogo={setLogo}
          logoUploadLoading={logoUploadLoading}
          setLogoUploadLoading={setLogoUploadLoading}
          companyLocation={companyLocation}
          setCompanyLocation={setCompanyLocation}
          stripeTestPublishableKey={stripeTestPublishableKey}
          setStripeTestPublishableKey={setStripeTestPublishableKey}
          stripeProdPublishableKey={stripeProdPublishableKey}
          setStripeProdPublishableKey={setStripeProdPublishableKey}
          stripeTestApiKey={stripeTestApiKey}
          setStripeTestApiKey={setStripeTestApiKey}
          stripeProdApiKey={stripeProdApiKey}
          setStripeProdApiKey={setStripeProdApiKey}
          accessToken={accessToken}
        />
      ),
      submitButtonLabel: "Next",
      cancelButtonLabel: "Cancel",
      submitButtonDisabled:
        !validatePresence(name) ||
        !companyLocation ||
        !validateStripeApiKey({
          keyType: "publishable",
          environment: "test",
          apiKey: stripeTestPublishableKey,
        }) ||
        !validateStripeApiKey({
          keyType: "publishable",
          environment: "prod",
          apiKey: stripeProdPublishableKey,
        }) ||
        !validateStripeApiKey({
          keyType: "secret",
          environment: "test",
          apiKey: stripeTestApiKey,
        }) ||
        !validateStripeApiKey({
          keyType: "secret",
          environment: "prod",
          apiKey: stripeProdApiKey,
        }),
      onCancel: handleClose,
      onSubmit: handleOrganizationDetailsSubmit,
    },
    {
      title: "Initial admin user details",
      component: (
        <InitialAdminUserForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      ),
      submitButtonLabel: "Create",
      cancelButtonLabel: "Back",
      submitButtonDisabled:
        !validatePresence(name) ||
        !validatePresence(firstName) ||
        !validatePresence(lastName) ||
        !validatePresence(phoneNumber) ||
        !validatePhoneNumber(phoneNumber) ||
        !validatePresence(email) ||
        !validateEmail(email),
      onCancel: () => {
        setPage((prev) => prev - 1);
      },
      onSubmit: handleFinalSubmit,
    },
  ];

  return (
    <Modal
      open={open}
      loading={isOrganizationCreateLoading}
      onSubmit={pages[page].onSubmit}
      onCancel={pages[page].onCancel}
      handleClose={handleClose}
      cancelButtonLabel={pages[page].cancelButtonLabel}
      submitButtonLabel={pages[page].submitButtonLabel}
      title={pages[page].title}
      submitButtonDisabled={
        pages[page].submitButtonDisabled ||
        logoUploadLoading ||
        detailsSubmitLoading
      }
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            New company successfully created
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowError(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        {pages[page].component}
      </Grid>
    </Modal>
  );
};

export default OrganizationCreateModal;
