import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { IMask } from "react-imask";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import {
  MailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  filterObjectByValues,
  mapObjectValues,
  validatePassword,
  validatePresence,
} from "../../../helpers/validationHelpers";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import { useMutation } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import { useAuth } from "../../../hooks/useAuth";
import { updateAdminPassword } from "../../../hooks/useAdminUser";

const ChangePasswordModal = ({
  open,
  setOpen,
  accessToken,
  setSuccessMessage,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accessToken: string;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
}) => {
  const { setToken } = useAuth();
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  //   const { alert, setAlert } = useContext(AlertBarContext);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    oldPassword,
    newPassword,
    newPasswordConfirm,
  });

  const formErrors = {
    oldPassword: {
      empty:
        blurredFormFields.oldPassword &&
        !validatePresence(oldPassword) &&
        "Please enter a password",
      invalidPassword:
        blurredFormFields.oldPassword &&
        validatePresence(oldPassword) &&
        !validatePassword(oldPassword) &&
        "Password must have 1 uppercase character, 1 lowercase character, 1 digit, and 1 special character and be 8-99 characters long.",
    },
    newPassword: {
      empty:
        blurredFormFields.newPassword &&
        !validatePresence(newPassword) &&
        "Please enter a password",
      invalidPassword:
        blurredFormFields.newPassword &&
        validatePresence(newPassword) &&
        !validatePassword(newPassword) &&
        "Password must have 1 uppercase character, 1 lowercase character, 1 digit, and 1 special character and be 8-99 characters long.",
      notMatching:
        blurredFormFields.newPassword &&
        newPassword !== newPasswordConfirm &&
        "Passwords don't match",
    },
    newPasswordConfirm: {
      empty:
        blurredFormFields.newPasswordConfirm &&
        !validatePresence(newPasswordConfirm) &&
        "Please enter a password",
      invalidPassword:
        blurredFormFields.newPasswordConfirm &&
        validatePresence(newPasswordConfirm) &&
        !validatePassword(newPasswordConfirm) &&
        "Password must have 1 uppercase character, 1 lowercase character, 1 digit, and 1 special character and be 8-99 characters long.",
      notMatching:
        blurredFormFields.newPasswordConfirm &&
        newPassword !== newPasswordConfirm &&
        "Passwords don't match",
    },
  };

  const submitDisabled =
    !validatePresence(oldPassword) ||
    !validatePassword(oldPassword) ||
    !validatePresence(newPassword) ||
    !validatePassword(newPassword) ||
    !validatePresence(newPasswordConfirm) ||
    !validatePassword(newPasswordConfirm) ||
    newPassword !== newPasswordConfirm;

  const oldPasswordErrors: any = filterObjectByValues(
    formErrors?.oldPassword,
    (key, value) => value
  );
  const newPasswordErrors: any = filterObjectByValues(
    formErrors?.newPassword,
    (key, value) => value
  );
  const newPasswordConfirmErrors: any = filterObjectByValues(
    formErrors?.newPasswordConfirm,
    (key, value) => value
  );

  const clearPasswordFields = () => {
    resetBlurState();
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
    setShowNewPassword(false);
    setShowOldPassword(false);
  };

  const { mutate: mutatePassword, isLoading: isPasswordResetLoading } =
    useMutation({
      mutationFn: updateAdminPassword,
      onSuccess: async (data) => {
        setOpen(false);
        setSuccessMessage("Password successfully updated");
        // setAlert({
        //   type: "SUCCESS",
        //   message: "PASSWORD SUCCESSFULLY UPDATED",
        // });

        const tokenData = await setToken(data, false);

        setAuthDetails((prev) => ({
          ...prev,
          ...tokenData,
        }));

        clearPasswordFields();
      },
      onError: async (error: any) => {
        console.log("error", error);
        setSubmitError(error);
        // setAlert({
        //   type: "ERROR",
        //   message: "PASSWORD UPDATE FAILED",
        // });
      },
    });

  const handleSubmit = () => {
    mutatePassword({
      previousPassword: oldPassword,
      proposedPassword: newPassword,
      accessToken,
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);

          setSubmitError("");
        }}
        message={submitError}
        action={
          <Box
            onClick={() => {
              setSubmitError("");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: "#f26822" }}>Dismiss</Typography>
          </Box>
        }
      />
      <Modal
        loading={isPasswordResetLoading}
        open={open}
        onSubmit={handleSubmit}
        onCancel={() => {
          setOpen(false);
        }}
        handleClose={() => {
          setOpen(false);
        }}
        cancelButtonLabel=""
        submitButtonLabel="Confirm"
        title="Update Password"
        submitButtonDisabled={submitDisabled}
        withCancelButton={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={14} fontWeight={400}>
              Create a strong password with at least eight characters, including
              numbers, letters, and symbols.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type={showOldPassword ? "text" : "password"}
              label="Old Password"
              fullWidth
              value={oldPassword}
              onFocus={() => {
                setFormFieldToFocused("oldPassword");
              }}
              onBlur={(e) => {
                setFormFieldToBlurred("oldPassword");
              }}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              // helperText={blurredFormFields.oldPassword && isAnyKeyValueTrue(errors.password)}
              // error={
              //   passwordBlurred && Boolean(isAnyKeyValueTrue(errors.password))
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer", paddingBottom: "4px" }}
                    onClick={() => {
                      setShowOldPassword((prev) => !prev);
                    }}
                  >
                    {showOldPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {mapObjectValues(oldPasswordErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`password-change-modal-old-password-errors-${index}`}
                errorText={oldPasswordErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type={showNewPassword ? "text" : "password"}
              label="New Password"
              fullWidth
              value={newPassword}
              onFocus={() => {
                setFormFieldToFocused("newPassword");
              }}
              onBlur={(e) => {
                setFormFieldToBlurred("newPassword");
              }}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              // helperText={blurredFormFields.oldPassword && isAnyKeyValueTrue(errors.password)}
              // error={
              //   passwordBlurred && Boolean(isAnyKeyValueTrue(errors.password))
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer", paddingBottom: "4px" }}
                    onClick={() => {
                      setShowNewPassword((prev) => !prev);
                    }}
                  >
                    {showNewPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {mapObjectValues(newPasswordErrors, (key: any, index: any) => (
              <ErrorHelperText
                key={`password-change-modal-new-password-errors-${index}`}
                errorText={newPasswordErrors[key]}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type={showNewPassword ? "text" : "password"}
              label="Confirm New Password"
              fullWidth
              value={newPasswordConfirm}
              onFocus={() => {
                setFormFieldToFocused("newPasswordConfirm");
              }}
              onBlur={(e) => {
                setFormFieldToBlurred("newPasswordConfirm");
              }}
              onChange={(e) => {
                setNewPasswordConfirm(e.target.value);
              }}
              // helperText={blurredFormFields.oldPassword && isAnyKeyValueTrue(errors.password)}
              // error={
              //   passwordBlurred && Boolean(isAnyKeyValueTrue(errors.password))
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer", paddingBottom: "4px" }}
                    onClick={() => {
                      setShowNewPassword((prev) => !prev);
                    }}
                  >
                    {showNewPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {mapObjectValues(
              newPasswordConfirmErrors,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`password-change-modal-new-password-confirm-errors-${index}`}
                  errorText={newPasswordConfirmErrors[key]}
                />
              )
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
