import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAdminUserDetails = async (accessToken: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/current-admin`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.log("error", JSON.stringify(error));
  }
};

const adminResetPassword = async ({
  newPassword,
  accessToken,
}: {
  newPassword: string;
  accessToken: string;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/reset-password`,
      { newPassword },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const adminSendPasswordResetEmail = async (email: string) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/send-password-reset-email`,
      { email }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const useAdminDetails = (accessToken: string) => {
  return useQuery({
    queryKey: ["current-admin"],
    queryFn: () => getAdminUserDetails(accessToken),
  });
};

const updateAdminPassword = async ({
  previousPassword,
  proposedPassword,
  accessToken,
}: {
  previousPassword: string;
  proposedPassword: string;
  accessToken: string;
}) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/update-password`,
      { previousPassword, proposedPassword },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

const validateAdminEmailAndPhoneNumber = async ({
  accessToken,
  email,
  phoneNumber,
}: {
  accessToken: string;
  email: string;
  phoneNumber: string;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/validate-email-and-phone-number-update`,
      {
        email,
        phoneNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export {
  getAdminUserDetails,
  useAdminDetails,
  adminResetPassword,
  adminSendPasswordResetEmail,
  updateAdminPassword,
  validateAdminEmailAndPhoneNumber,
};
