import { Box, Grid, Typography } from "@mui/material";

const LeftLoginSection = () => {
  return (
    <Grid
      container
      item
      direction={"column"}
      position={"sticky"}
      bottom={0}
      alignItems={"center"}
      justifyContent={"center"}
      alignSelf={"flex-end"}
      md={7}
      sx={{
        height: "100vh",
        background: "#f8f8f8",
        backgroundImage: `url(${require("../../../assets/images/virtualfleethub-login-banner.png")})`,
        backgroundSize: "100% 70vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <Box maxWidth={560} margin={"0px auto"} padding={3} paddingTop={"14vh"}>
        <Typography
          color={"#242424"}
          variant="h1"
          fontSize={36}
          fontWeight={700}
          mb={3}
        >
          Welcome to VirtualFleethub Admin
        </Typography>
        <Typography color={"#868686"} fontSize={16} lineHeight={"28px"}>
          At VirtualFleethub, our mission is to attract and retain customers by
          providing the best in class on-demand personal cargo transportation
          solution while fostering a profitable, disciplined culture of safety,
          service, and trust.
        </Typography>
      </Box>
    </Grid>
  );
};

export default LeftLoginSection;
