import { Alert, Grid, TextField } from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { Dispatch, SetStateAction } from "react";
import { IMask } from "react-imask";

const AccountDetailsConfirmationModal = ({
  open,
  setOpen,
  loading,
  onSubmit,
  firstName,
  lastName,
  email,
  phoneNumber,
  submitError,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  onSubmit: any;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  submitError: string;
}) => {
  const textFieldStyles = {
    "&.Mui-disabled": {
      "&:before": {
        borderBottomStyle: "solid",
      },
    },
  };
  const maskedPhoneNumber = IMask.pipe(phoneNumber, {
    mask: "{+1} (000)-000-0000",
    definitions: {
      "#": /[1-9]/,
    },
  });

  return (
    <Modal
      loading={loading}
      open={open}
      onSubmit={onSubmit}
      handleClose={() => {
        setOpen(false);
      }}
      onCancel={() => {
        setOpen(false);
      }}
      cancelButtonLabel=""
      submitButtonLabel="Confirm"
      title="Update Account Details"
      withCancelButton={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              sx: textFieldStyles,
            }}
            disabled
            fullWidth
            value={firstName}
            required
            label="First Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              sx: textFieldStyles,
            }}
            disabled
            fullWidth
            value={lastName}
            required
            label="Last Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            InputProps={{
              sx: textFieldStyles,
            }}
            fullWidth
            value={email}
            required
            label="Email"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            InputProps={{
              sx: textFieldStyles,
            }}
            fullWidth
            value={maskedPhoneNumber}
            required
            label="Phone Number"
            variant="outlined"
          />
        </Grid>
        <Grid container item spacing={1}>
          {submitError && (
            <Grid item xs={12}>
              <Alert severity="error">{submitError}</Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AccountDetailsConfirmationModal;
