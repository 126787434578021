import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import BreadcrumbSection from "../../../components/BreadcrumbSection/BreadcrumbSection";
import { useDriverDetails } from "../../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminSuperAdmin,
} from "../../../helpers/authHelpers";
import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ProfilePanel from "../../../components/ProfilePanel/ProfilePanel";
import ProfileListItem from "../../../components/ProfilePanel/ProfileListItem";
import TabSection from "../../../components/TabSection/TabSection";
import { useState } from "react";
import VehiclesTab from "./VehiclesTab";
import BankingTab from "./BankingTab";
import DocumentsTab from "./DocumentsTab";
import ServiceCallsTab from "./ServiceCallsTab";
import EarningsTab from "./EarningsTab";
import Modal from "../../../components/Modal/Modal";
import DriverProfileEditModal from "./DriverProfileEditModal";
import { getDifference } from "../../../helpers/validationHelpers";
import { AuthContext } from "../../../context/authContext";
import { userAuthState } from "../../../atom";
import { useRecoilState } from "recoil";
import useAdminNearbyDriversSocket from "../../../sockets/useAdminNearbyDriversSocket";
const DriverProfile = () => {
  const { userId } = useParams();
  // const accessToken = getAccessToken(user);
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const { nearbyDrivers, isDriverOnline } = useAdminNearbyDriversSocket({
    accessToken: authDetails?.accessToken,
  });

  const { data: driverData, isLoading } = useDriverDetails(
    authDetails?.accessToken,
    userId
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);

  console.log("driverData", driverData);
  const styles = {
    listLabel: {
      color: "#626262",
    },
    listValue: {
      color: "#242424",
    },
    tab: {
      textTransform: "capitalize",
    },
  };

  const primarySectionFields = [
    { label: "Email Address", value: driverData?.email },
    { label: "Phone No.", value: driverData?.phoneNumber },
    {
      label: "Typeform Submitted",
      value: driverData?.driverRegistrationDetail?.driverEvaluationSubmitted
        ? "Submitted"
        : "Pending",
    },
    {
      label: "Banking Details Submitted",
      value:
        driverData?.stripeConnectProfile &&
        driverData?.stripeConnectProfile?.stripeConnectPaymentMethods.length > 0
          ? "Submitted"
          : "Pending",
    },
    {
      label: "Service Tutorial Completed",
      value: driverData?.driverRegistrationDetail?.driverEvaluationCompleted
        ? "Yes"
        : "No",
    },
    {
      label: "Registration Completed",
      value: driverData?.registrationCompleted ? "Yes" : "No",
    },
    {
      label: "Consented to criminal record check",
      value: driverData?.driverRegistrationDetail?.criminalRecordSubmitted
        ? "Yes"
        : "No",
    },
    {
      label: "Criminal record check completed",
      value: driverData?.driverRegistrationDetail?.criminalRecordCheckCompleted
        ? "Yes"
        : "No",
    },
    {
      label: "Driver Cancellation %",
      value: driverData?.driverCancellationRate,
    },
    {
      label: "Service Call Acceptance Percentage",
      value: driverData?.driverAcceptanceRate,
    },
    { label: "Status", value: driverData?.isOnline ? "Active" : "Offline" },
  ];

  const secondarySectionFields = [
    { label: "Address", value: driverData?.driverRegistrationDetail?.address },
    { label: "City", value: driverData?.driverRegistrationDetail?.city },
    {
      label: "Preferred City",
      value: driverData?.driverRegistrationDetail?.preferredCity,
    },
    {
      label: "Province",
      value: driverData?.driverRegistrationDetail?.province,
    },
    {
      label: "Postal Code",
      value: driverData?.driverRegistrationDetail?.postalCode,
    },
    {
      label: "Country",
      value: driverData?.driverRegistrationDetail?.country,
    },
    {
      label: "License Number",
      value: driverData?.driverRegistrationDetail?.drivingLicenseNumber,
    },
  ];

  const tabPages = [
    <VehiclesTab
      organizationId={driverData?.organizationId}
      userId={userId}
      accessToken={authDetails?.accessToken}
      driverVehicleInformation={
        driverData?.driverRegistrationDetail?.driverVehicleInformation
      }
    />,
    ...(isCurrentAdminSuperAdmin(authDetails)
      ? [<BankingTab stripeConnectProfile={driverData?.stripeConnectProfile} />]
      : []),
    <DocumentsTab
      userId={userId}
      accessToken={authDetails?.accessToken}
      driverRegistrationDetail={driverData?.driverRegistrationDetail}
    />,
    <ServiceCallsTab accessToken={authDetails?.accessToken} userId={userId} />,
    ...(isCurrentAdminSuperAdmin(authDetails)
      ? [<EarningsTab accessToken={authDetails?.accessToken} userId={userId} />]
      : []),
  ];

  const getPendingChanges = () => {
    const unapprovedDraft = driverData?.userChangeDrafts.find(
      (draft: any) => !draft.approved
    );
    return unapprovedDraft
      ? getDifference(
          JSON.parse(unapprovedDraft?.prevValues),
          JSON.parse(unapprovedDraft?.newValues)
        )
      : null;
  };

  const pendingChanges = getPendingChanges();

  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/drivers",
            title: "Drivers",
          },
          {
            url: `/dashboard/drivers/${userId}`,
            title: `${driverData?.firstName} ${driverData?.lastName}`,
          },
        ]}
        pageTitle={`Drivers`}
        withBackNav
        backNavLabel={"Return to Listing"}
        backNavUrl="/dashboard/drivers"
      />
      <Grid container mt={1} spacing={3} pb={4}>
        {/* left panel */}
        <DriverProfileEditModal
          open={open}
          accessToken={authDetails?.accessToken}
          userId={userId}
          setOpen={setOpen}
          driverFirstName={driverData?.firstName}
          driverLastName={driverData?.lastName}
          driverPhoneNumber={driverData?.phoneNumber}
          driverEmail={driverData?.email}
          driverServiceTutorialCompleted={
            driverData?.driverRegistrationDetail?.driverEvaluationCompleted
          }
          driverCriminalRecordSubmitted={
            driverData?.driverRegistrationDetail?.criminalRecordSubmitted
          }
          driverCriminalRecordCheckCompleted={
            driverData?.driverRegistrationDetail?.criminalRecordCheckCompleted
          }
          driverIsOnline={isDriverOnline(driverData?.id)}
          driverServiceCallAcceptanceRate={driverData?.driverAcceptanceRate}
          driverServiceCallCancellationRate={driverData?.driverCancellationRate}
          driverAddress={driverData?.driverRegistrationDetail?.address}
          driverCity={driverData?.driverRegistrationDetail?.city}
          driverPreferredCity={
            driverData?.driverRegistrationDetail.preferredCity
          }
          driverProvince={driverData?.driverRegistrationDetail?.province}
          driverPostalCode={driverData?.driverRegistrationDetail?.postalCode}
          driverCountry={driverData?.driverRegistrationDetail?.country}
          driverDrivingLicenseNumber={
            driverData?.driverRegistrationDetail?.drivingLicenseNumber
          }
        />
        <ProfilePanel
          accessToken={authDetails?.accessToken}
          isOnline={isDriverOnline(driverData?.id)}
          userId={driverData?.id}
          rating={driverData?.rating}
          firstName={driverData?.firstName}
          lastName={driverData?.lastName}
          avatar={driverData?.avatar}
          pendingChanges={pendingChanges}
          onEditClick={() => {
            setOpen(true);
          }}
        >
          <>
            <Grid container spacing={2} direction={"column"}>
              {primarySectionFields.map((field) => (
                <ProfileListItem label={field.label} value={field.value} />
              ))}
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Grid container spacing={2} direction={"column"}>
              {secondarySectionFields.map((field) => (
                <ProfileListItem label={field.label} value={field.value} />
              ))}
            </Grid>
          </>
        </ProfilePanel>

        {/* right panel */}
        <Grid item xs={12} lg={8}>
          <Paper>
            <TabSection
              tabIndex={tabIndex}
              tabHeaders={[
                "Vehicles",
                ...(isCurrentAdminSuperAdmin(authDetails) ? ["Banking"] : []),
                "Documents",
                "Service Calls",
                ...(isCurrentAdminSuperAdmin(authDetails) ? ["Earnings"] : []),
              ]}
              handleTabChange={(index) => {
                setTabIndex(index);
              }}
            >
              {tabPages[tabIndex]}
            </TabSection>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default DriverProfile;
