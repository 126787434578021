import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { useDrivers } from "../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminSuperAdmin,
} from "../../helpers/authHelpers";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import {
  PickUpStatus,
  cancelledStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  mappedVehicleTypes,
  pendingStatuses,
} from "../../helpers/validationHelpers";
import { roundToPrecision } from "../../helpers/formatters";
import moment from "moment";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import { AuthContext } from "../../context/authContext";
import { getCompaniesCSV, useCompanies } from "../../hooks/useCompanies";
import Button from "../../components/Button/Button";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import {
  getOrganizationsCSV,
  useOrganizations,
} from "../../hooks/useOrganizations";
import OrganizationCreateModal from "./OrganizationCreateModal/OrganizationCreateModal";

const Organizations = () => {
  const {
    csvDownloadLoading: organizationsCsvDownloadLoading,
    setCsvDownloadLoading: setOrganizationsCsvDownloadLoading,
    csvData: organizationsCsvData,
    setCsvData: setOrganizationsCsvData,
    setCsvFilename: setOrganizationsCsvFilename,
    csvLinkRef: organizationsCsvLinkRef,
    setShouldDownload: setShouldDownloadOrganizationsCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const [showOrganizationCreateModal, setShowOrganizationCreateModal] =
    useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // const accessToken = getAccessToken(user);
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: organizationsData,
    isLoading,
    isFetching,
  } = useOrganizations({
    accessToken: authDetails.accessToken,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Organization ID",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/dashboard/organizations/${params.row.id}`}
          state={{ prevPage: location.pathname }}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Organization Name",
      flex: 1,
      valueGetter: (params) => `${params?.row?.name}`,
      renderCell: (params) =>
        params?.row?.name ? (
          <Typography>{params?.row?.name}</Typography>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/organizations/${params.row.id}`, {
              state: {
                prevPage: location.pathname,
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadOrganizationsCSV = async () => {
    setOrganizationsCsvDownloadLoading(true);
    try {
      const result = await getOrganizationsCSV(authDetails.accessToken);
      setOrganizationsCsvData(result?.data);
      setOrganizationsCsvFilename(result?.filename);
      setShouldDownloadOrganizationsCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setOrganizationsCsvDownloadLoading(false);
    }
  };
  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/organizations",
            title: "Organizations",
          },
        ]}
        pageTitle="Organizations"
      />
      {isCurrentAdminSuperAdmin(authDetails) && (
        <>
          <OrganizationCreateModal
            accessToken={authDetails.accessToken}
            open={showOrganizationCreateModal}
            setOpen={setShowOrganizationCreateModal}
          />
          <Grid container item justifyContent={"flex-end"}>
            <Grid item>
              <Button
                onClick={() => {
                  setShowOrganizationCreateModal(true);
                }}
                icon={<AddBusinessIcon sx={{ marginRight: 2 }} />}
              >
                Create New Organization
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={organizationsData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          exportButtonLoading={organizationsCsvDownloadLoading}
          onExportButtonClick={downloadOrganizationsCSV}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={organizationsData?.totalCount}
          pageCount={organizationsData?.totalPages}
        />
      </Grid>
      {organizationsCsvData && (
        <a
          style={{ display: "none" }}
          ref={organizationsCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(organizationsCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </DashboardLayout>
  );
};

export default Organizations;
