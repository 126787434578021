import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { modifyAdminRole } from "../../../../hooks/useAdmins";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { isCurrentAdminSuperAdmin } from "../../../../helpers/authHelpers";
const RoleSelector = ({
  adminId,
  accessToken,
  adminRole,
}: {
  adminId: string;
  accessToken: string;
  adminRole: string;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const queryClient = useQueryClient();

  const [role, setRole] = useState(adminRole ?? "OPERATOR");
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const isAdminFetching = useIsFetching({ queryKey: ["admin", adminId] });

  useEffect(() => {
    if (adminRole) {
      setRole(adminRole);
    }
  }, [adminRole]);

  const { mutate: changeAdminType, isLoading: isAdminTypeChangeLoading } =
    useMutation({
      mutationFn: modifyAdminRole,
      onSuccess: async (data: any) => {
        console.log("data after submit", data);
        await queryClient.cancelQueries({
          queryKey: ["admin", adminId],
        });

        await queryClient.invalidateQueries({
          queryKey: ["admin", adminId],
        });

        setSuccessMessage("Admin updated");
      },
      onError: async (error: any) => {
        console.log("error", error);
        // setShowError(true);
        // setErrorMessage(error);
        setSubmitError("Failed to update user");
      },
    });

  const handleSubmit = () => {
    changeAdminType({
      accessToken,
      adminId,
      role,
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="role">Role</InputLabel>
            <Select
              disabled={!isCurrentAdminSuperAdmin(authDetails)}
              id="role"
              sx={{
                "&.Mui-disabled": {
                  "&:before": {
                    borderBottomStyle: "solid",
                  },
                },
              }}
              value={role}
              label="Role"
              fullWidth
              onChange={(e: any) => {
                setRole(e.target.value);
              }}
              variant="outlined"
            >
              <MenuItem value={"OPERATOR"}>Operator</MenuItem>
              <MenuItem value={"SUPERADMIN"}>Super Admin</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            loading={isAdminFetching}
            disabled={
              !isCurrentAdminSuperAdmin(authDetails) || role === adminRole
            }
            fullWidth
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default RoleSelector;
