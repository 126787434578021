import {
  Alert,
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ReactElement, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Button from "../../../components/Button/Button";
import QrCodeIcon from "@mui/icons-material/QrCode";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
const OrganizationProfilePanel = ({
  organizationLogo,
  organizationId,
  defaultOrganizationLocationSlug,
  name,
  children,
  withEdit = true,
  onEditClick,
}: {
  organizationLogo: string;
  organizationId: string;
  defaultOrganizationLocationSlug?: string;
  name: string;
  withEdit?: boolean;
  onEditClick: () => void;
  children: ReactElement;
}) => {
  //   const queryClient = useQueryClient();
  //   const [open, setOpen] = useState(false);
  //   const [showSuccess, setShowSuccess] = useState(false);
  //   const [showError, setShowError] = useState(false);
  //   const [errorMessage, setErrorMessage] = useState("");
  console.log(
    "REACT_APP_COMPANY_WEBSITE_URL",
    process.env.REACT_APP_COMPANY_WEBSITE_URL
  );

  return (
    <Grid item xs={12} lg={4}>
      <Paper sx={{ padding: 2, position: "relative" }}>
        {withEdit && (
          <IconButton
            sx={{ position: "absolute", top: "15px", right: "15px" }}
            color="primary"
            onClick={onEditClick}
          >
            <EditOutlinedIcon sx={{ color: "#28a1fa" }} />
          </IconButton>
        )}
        <Grid container spacing={2}>
          {/* company logo, company name and ID */}
          <Grid item>
            <Avatar
              sx={{ width: 80, height: 80 }}
              slotProps={{
                img: {
                  sx: {
                    objectFit: "scale-down",
                    padding: 2,
                  },
                },
              }}
              src={organizationLogo}
            />
          </Grid>
          <Grid container item xs={9} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={700} fontSize={22}>
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#610095",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
              >
                <Typography sx={{ color: "white" }} fontSize={14}>
                  {organizationId}
                </Typography>
              </Box>
            </Grid>
            {defaultOrganizationLocationSlug && (
              <>
                <Grid item xs={12}>
                  <Button
                    icon={
                      <QrCodeIcon
                        sx={{
                          paddingRight: 1,
                        }}
                      />
                    }
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_COMPANY_WEBSITE_URL}/organization/qr-code/${defaultOrganizationLocationSlug}`
                      );
                    }}
                    styleOverrides={{
                      paddingY: 1,
                    }}
                    fontSize={14}
                  >
                    View QR code
                  </Button>
                </Grid>
                <Grid item xs={14}>
                  <Button
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_COMPANY_WEBSITE_URL}/organization/${defaultOrganizationLocationSlug}`
                      );
                    }}
                    icon={<LocalShippingIcon sx={{ marginRight: 2 }} />}
                    styleOverrides={{
                      paddingY: 1,
                    }}
                    fontSize={14}
                  >
                    Create new Service Call
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        {children}
      </Paper>
    </Grid>
  );
};

export default OrganizationProfilePanel;
