import {
  Avatar,
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  toTitleCase,
  VehicleClassTypes,
} from "../../../../../helpers/validationHelpers";
import { convertCentsToDollars } from "../../../../../helpers/formatters";
import Button from "../../../../../components/Button/Button";
import { ReactComponent as CargoVanIcon } from "../../../../../assets/images/cargo-van-icon.svg";
import { ReactComponent as MediumVehicleClassIcon } from "../../../../../assets/images/medium-vehicle-class-icon.svg";
import { ReactComponent as SedanIcon } from "../../../../../assets/images/sedan-icon.svg";
const VehicleClassMappingsListItem = ({
  iconUrl,
  name,
  enabled,
  vehicleClass,
  rateInPercent,
  baseFee,
  perKmRate,
  perMinuteRate,
  driverInitiatedCancellationChargePrePickup,
  driverInitiatedCancellationChargePostPickup,
  customerInitiatedCancellationChargePrePickup,
  customerInitiatedCancellationChargePostPickup,
  onEditClick,
  isRootOrg,
}: {
  iconUrl: string;
  name: string;
  enabled: boolean;
  vehicleClass: VehicleClassTypes;
  rateInPercent: number;
  baseFee: number;
  perKmRate: number;
  perMinuteRate: number;
  driverInitiatedCancellationChargePrePickup: number;
  driverInitiatedCancellationChargePostPickup: number;
  customerInitiatedCancellationChargePrePickup: number;
  customerInitiatedCancellationChargePostPickup: number;
  onEditClick: (vehicleClassMapping: any) => void;
  isRootOrg?: boolean;
}) => {
  const vehicleClassIconDefaults = {
    [VehicleClassTypes.large]: (
      <CargoVanIcon style={{ width: 24, height: 24 }} />
    ),
    [VehicleClassTypes.medium]: (
      <MediumVehicleClassIcon style={{ width: 24, height: 24 }} />
    ),
    [VehicleClassTypes.small]: <SedanIcon style={{ width: 24, height: 24 }} />,
    [VehicleClassTypes.none]: null,
  };
  console.log("iconURl", iconUrl);
  const renderIcon = () => {
    if (!iconUrl && vehicleClass) {
      return (
        <Avatar sx={{ width: 40, height: 40, backgroundColor: "#F75A3B" }}>
          {vehicleClassIconDefaults[vehicleClass]}
        </Avatar>
      );
    } else {
      return (
        <Avatar
          sx={{ width: 40, height: 40, backgroundColor: "#F75A3B" }}
          slotProps={{
            img: {
              sx: {
                objectFit: "scale-down",
                padding: 1,
              },
            },
          }}
          src={iconUrl}
        />
      );
    }
  };
  return (
    <Grid container item xs={12} spacing={2} justifyContent={"center"}>
      <Grid item marginTop={1}>
        {renderIcon()}
      </Grid>
      <Grid container item xs={9} spacing={1}>
        <Grid
          container
          item
          xs={12}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid item paddingRight={1}>
            <Typography fontSize={16} fontWeight={700}>
              {name}
            </Typography>
          </Grid>
          {isRootOrg && (
            <Grid item>
              <Typography
                component="span"
                sx={{
                  backgroundColor: "#610095",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
                fontSize={10}
              >
                Vehicle Class: {toTitleCase(vehicleClass)}
              </Typography>
            </Grid>
          )}
          {enabled && (
            <Grid item>
              <Typography
                component="span"
                sx={{
                  marginLeft: isRootOrg ? 1 : 0,
                  backgroundColor: "green",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
                fontSize={10}
              >
                Enabled
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Grid item>
            <Typography variant="body1">
              Base Fee: ${convertCentsToDollars(baseFee)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Per KM rate: ${convertCentsToDollars(perKmRate)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Per minute rate: ${convertCentsToDollars(perMinuteRate)}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item>
          <Typography variant="body1">
            Pricing rate: {rateInPercent}%
          </Typography>
        </Grid> */}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={2}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={10}>
          <Button
            onClick={onEditClick}
            fullWidth
            styleOverrides={{ paddingY: 1, paddingX: 0 }}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default VehicleClassMappingsListItem;
