import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  filterFormErrorObject,
  filterObjectByValues,
  isAnyKeyValueTrue,
  mapObjectValues,
  objectHasKeys,
  validatePresence,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ImageUploadSection from "../../../components/ImageUploadSection/ImageUploadSection";
import { uploadAssetToCloudinary } from "../../../utils/cloudinary";
import { updateOrganizationProfile } from "../../../hooks/useOrganizations";
import GoogleMapsAutocomplete from "../../../components/GoogleMapsAutoComplete/GoogleMapsAutoComplete";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";

const OrganizationProfileEditModal = ({
  organizationId,
  accessToken,
  open,
  setOpen,
  organizationName,
  organizationLogo,
  organizationAddress,
  organizationLocationData,
}: any) => {
  console.log("organizationAddress", organizationAddress);
  const queryClient = useQueryClient();

  const [name, setName] = useState(organizationName || "");
  const [companyLocationText, setCompanyLocationText] = useState(
    organizationLocationData?.name
  );
  const [companyLocation, setCompanyLocation] = useState<any>(
    organizationLocationData
  );
  const [logo, setLogo] = useState(organizationLogo || "");
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (open) {
      setName(organizationName);
      setLogo(organizationLogo);
      setCompanyLocationText(organizationAddress);
      setCompanyLocation(organizationLocationData);
    }
  }, [open]);

  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      name,
    });

  const formErrors = {
    name: {
      error:
        blurredFormFields.name &&
        !validatePresence(name) &&
        "Please enter an organization name",
    },
    companyLocation: {
      error:
        blurredFormFields.companyLocation &&
        !companyLocation &&
        "Please enter an address for your organization",
    },
  };

  const parsedFormErrors = filterFormErrorObject(formErrors);

  const submitButtonDisabled = [
    formErrors.name,
    formErrors.companyLocation,
  ].some((value) => isAnyKeyValueTrue(value));

  const {
    mutate: handleOrganizationProfileUpdate,
    isLoading: isOrganizationProfileUpdateLoading,
  } = useMutation({
    mutationFn: updateOrganizationProfile,
    onSuccess: async (data) => {
      console.log("data after submit", data);
      await queryClient.cancelQueries({
        queryKey: ["organization", organizationId],
      });
      queryClient.setQueryData(
        ["organization", organizationId],
        (prev: any) => {
          return {
            ...prev,
            ...data,
          };
        }
      );
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error", error);
      setShowError(true);
      setErrorMessage(error);
      // setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    setShowError(false);
    setErrorMessage("");
    handleOrganizationProfileUpdate({
      organizationId,
      accessToken,
      organizationFields: {
        name,
        logo,
        companyLocationLatitude: companyLocation?.latitude,
        companyLocationLongitude: companyLocation?.longitude,
      },
    });
  };

  const handleOrganizationLogoUpload = async (file: any) => {
    setLogoUploadLoading(true);
    try {
      const data = await uploadAssetToCloudinary(file, "organization-logos");
      setLogo(data?.file_url);

      console.log("data", data);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLogoUploadLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      loading={isOrganizationProfileUpdateLoading}
      onSubmit={handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      handleClose={() => {
        if (!isOrganizationProfileUpdateLoading) {
          setOpen(false);
        }
      }}
      submitButtonLabel="Update"
      title="Organization Details"
      submitButtonDisabled={submitButtonDisabled}
    >
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSuccess}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSuccess(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            Organization update successful
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={() => {
            setShowSuccess(false);
            setErrorMessage("");
          }}
        >
          <Alert
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={"Organization Name"}
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onFocus={() => {
                setFormFieldToFocused("name");
              }}
              onBlur={() => {
                setFormFieldToBlurred("name");
              }}
              error={Boolean(isAnyKeyValueTrue(formErrors.name))}
              helperText={isAnyKeyValueTrue(formErrors.name)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <GoogleMapsAutocomplete
              error={objectHasKeys(parsedFormErrors.companyLocation)}
              onFocus={() => {
                setFormFieldToFocused("companyLocation");
              }}
              onBlur={() => {
                setFormFieldToBlurred("companyLocation");
              }}
              inputValue={companyLocationText}
              setInputValue={setCompanyLocationText}
              value={companyLocation}
              setValue={setCompanyLocation}
              accessToken={accessToken}
              label={"Company address"}
            />
            {mapObjectValues(
              parsedFormErrors?.companyLocation,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`organization-create-address-errors-${index}`}
                  errorText={parsedFormErrors?.companyLocation[key]}
                />
              )
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography>Organization Logo:</Typography>
          </Grid>
          <ImageUploadSection
            uploadedFile={logo}
            loading={logoUploadLoading}
            label={"Upload organization logo"}
            handleUpload={handleOrganizationLogoUpload}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default OrganizationProfileEditModal;
