import { useLocation, Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import { isCurrentAdminFromRootOrganization } from "../../helpers/authHelpers";

const RequireRootAdmin = ({ admin = false, children }: any) => {
  const location = useLocation();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  if (!isCurrentAdminFromRootOrganization(authDetails)) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireRootAdmin;
