import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { useDrivers } from "../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminFromRootOrganization,
} from "../../helpers/authHelpers";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import {
  getServiceCallsCSV,
  useServiceCalls,
} from "../../hooks/useServiceCalls";
import {
  PickUpStatus,
  cancelledStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  mappedVehicleTypes,
  pendingStatuses,
} from "../../helpers/validationHelpers";
import { roundToPrecision } from "../../helpers/formatters";
import moment from "moment";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import {
  getServiceCallSubmissionAttemptsCSV,
  useServiceCallSubmissionAttempts,
} from "../../hooks/useServiceCallSubmissionAttempts";
import { AuthContext } from "../../context/authContext";
import { userAuthState } from "../../atom";
import { useRecoilState } from "recoil";

const ServiceCallSubmissionAttempts = () => {
  const {
    csvDownloadLoading: serviceCallsCsvDownloadLoading,
    setCsvDownloadLoading: setServiceCallsCsvDownloadLoading,
    csvData: serviceCallsCsvData,
    setCsvData: setServiceCallsCsvData,
    setCsvFilename: setServiceCallsCsvFilename,
    csvLinkRef: serviceCallsCsvLinkRef,
    setShouldDownload: setShouldDownloadServiceCallsCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);
  const location = useLocation();
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: serviceCallSubmissionAttemptsData,
    isLoading,
    isFetching,
  } = useServiceCallSubmissionAttempts({
    accessToken: authDetails?.accessToken,
    organizationId: isCurrentAdminFromRootOrganization(authDetails)
      ? undefined
      : authDetails?.organizationId,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Service Call ID",
      width: 250,
      renderCell: (params) => (
        <Link
          to={`/dashboard/service-call-submission-attempts/${params.row.id}`}
          state={{ prevPage: location.pathname }}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 125,

      valueGetter: (params) =>
        `${params?.row?.customer?.firstName} ${params?.row?.customer?.lastName}`,
      renderCell: (params) =>
        params.row.customer ? (
          <Link
            to={`/dashboard/customers/${params?.row?.customer?.id}`}
            state={{ prevPage: location.pathname }}
          >
            {params?.row?.customer?.firstName} {params?.row?.customer?.lastName}
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "pickUpRequestSubmissionType",
      headerName: "Service Call Type",
      width: 170,
      renderCell: (params) =>
        params.row.pickUpRequestSubmissionType === "ONDEMAND" ? (
          <Typography>On-Demand</Typography>
        ) : (
          <Typography>Scheduled</Typography>
        ),
    },
    {
      field: "failureReason",
      headerName: "Failure Reason",
      width: 550,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(
              `/dashboard/service-call-submission-attempts/${params.row.id}`, {
                state: {
                  prevPage: location.pathname,
                },
              }
            );
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadServiceCallsCSV = async () => {
    setServiceCallsCsvDownloadLoading(true);
    try {
      const result = await getServiceCallSubmissionAttemptsCSV(
        authDetails?.accessToken,
        isCurrentAdminFromRootOrganization(authDetails)
          ? undefined
          : authDetails?.organizationId
      );
      setServiceCallsCsvData(result?.data);
      setServiceCallsCsvFilename(result?.filename);
      setShouldDownloadServiceCallsCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setServiceCallsCsvDownloadLoading(false);
    }
  };
  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/service-call-submission-attempts",
            title: "Service Calls",
          },
        ]}
        pageTitle="Service Call Submissions"
      />

      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={serviceCallSubmissionAttemptsData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          exportButtonLoading={serviceCallsCsvDownloadLoading}
          onExportButtonClick={downloadServiceCallsCSV}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={serviceCallSubmissionAttemptsData?.totalCount}
          pageCount={serviceCallSubmissionAttemptsData?.totalPages}
        />
      </Grid>
      {serviceCallsCsvData && (
        <a
          style={{ display: "none" }}
          ref={serviceCallsCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(serviceCallsCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </DashboardLayout>
  );
};

export default ServiceCallSubmissionAttempts;
