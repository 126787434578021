import { Grid, TextField, Typography } from "@mui/material";
import {
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
} from "../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import { Dispatch, SetStateAction } from "react";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import PhoneNumberTextField from "../../../components/PhoneNumberTextField/PhoneNumberTextField";

const InitialAdminUserForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
}: {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;

  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
}) => {
  const { blurredFormFields, setFormFieldToBlurred, setFormFieldToFocused } =
    useFormFieldsBlurState({
      firstName,
      lastName,
      email,
      phoneNumber,
    });

  const formErrors = {
    firstName: {
      empty:
        blurredFormFields.firstName &&
        !validatePresence(firstName) &&
        "Please enter a first name",
    },
    lastName: {
      empty:
        blurredFormFields.lastName &&
        !validatePresence(lastName) &&
        "Please enter a last name",
    },
    phoneNumber: {
      empty:
        blurredFormFields?.phoneNumber &&
        !validatePresence(phoneNumber) &&
        "Please enter a phone number",
      invalid:
        blurredFormFields?.phoneNumber &&
        phoneNumber.length > 0 &&
        !validatePhoneNumber(phoneNumber) &&
        "Please enter a valid phone number",
    },
    email: {
      empty:
        blurredFormFields?.email &&
        !validatePresence(email) &&
        "Please enter an email",
      invalid:
        blurredFormFields?.email &&
        email.length > 0 &&
        !validateEmail(email) &&
        "Please enter a valid email",
    },
  };

  const firstNameErrors: any = filterObjectByValues(
    formErrors?.firstName,
    (key, value) => value
  );
  const lastNameErrors: any = filterObjectByValues(
    formErrors?.lastName,
    (key, value) => value
  );
  const phoneNumberErrors: any = filterObjectByValues(
    formErrors?.phoneNumber,
    (key, value) => value
  );

  const emailErrors: any = filterObjectByValues(
    formErrors?.email,
    (key, value) => value
  );

  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label={"First name"}
            fullWidth
            value={firstName}
            error={objectHasKeys(firstNameErrors)}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("firstName");
            }}
            onBlur={() => {
              setFormFieldToBlurred("firstName");
            }}
          />
          {mapObjectValues(firstNameErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`account-details-first-name-errors-${index}`}
              errorText={firstNameErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label={"Last name"}
            error={objectHasKeys(lastNameErrors)}
            fullWidth
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("lastName");
            }}
            onBlur={() => {
              setFormFieldToBlurred("lastName");
            }}
          />
          {mapObjectValues(lastNameErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`account-details-first-name-errors-${index}`}
              errorText={firstNameErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            error={objectHasKeys(emailErrors)}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onFocus={() => {
              setFormFieldToFocused("email");
            }}
            onBlur={() => {
              setFormFieldToBlurred("email");
            }}
            label="Email"
            variant="outlined"
          />
          {mapObjectValues(emailErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`organization-create-emails-errors-${index}`}
              errorText={emailErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <PhoneNumberTextField
            label="Phone Number"
            placeholder="Phone Number"
            error={objectHasKeys(phoneNumberErrors)}
            value={phoneNumber}
            setValue={setPhoneNumber}
            onFocus={() => {
              setFormFieldToFocused("phoneNumber");
            }}
            onBlur={() => {
              setFormFieldToBlurred("phoneNumber");
            }}
          />
          {mapObjectValues(phoneNumberErrors, (key: any, index: any) => (
            <ErrorHelperText
              key={`organization-create-phone-number-errors-${index}`}
              errorText={phoneNumberErrors[key]}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default InitialAdminUserForm;
