import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useFormFieldsBlurState from "../../../hooks/useFormFieldsBlurState";
import {
  CouponDeductionType,
  CouponDurationType,
  filterFormErrorObject,
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
  removeWhitespaces,
  validateCouponDeductionType,
  validateCouponDurationType,
  validateEmail,
  validatePhoneNumber,
  validatePresence,
} from "../../../helpers/validationHelpers";
import ErrorHelperText from "../../../components/ErrorHelperText/ErrorHelperText";
import PhoneNumberTextField from "../../../components/PhoneNumberTextField/PhoneNumberTextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewCompanyStaff } from "../../../hooks/useCompanyUsers";
import { addNewOrganizationAdmin } from "../../../hooks/useAdmins";
import {
  createNewPromoCode,
  formatPromoCodeSubmissionData,
} from "../../../hooks/usePromoCodes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import OrganizationAutoComplete from "../../../components/OrganizationAutoComplete/OrganizationAutoComplete";

const CreatePromoCodeModalWithOrganizationSelector = ({
  open,
  setOpen,
  accessToken,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accessToken: string;
}) => {
  const currentDate = moment(new Date(), true).set({ seconds: 0 });
  const queryClient = useQueryClient();

  const [organizationSearchText, setOrganizationSearchText] = useState("");
  const [organization, setOrganization] = useState<any>(null);

  const [displayCode, setDisplayCode] = useState("");
  const [amount, setAmount] = useState(0);
  const [couponDeductionType, setCouponDeductionType] = useState(
    CouponDeductionType.amountOffInCents
  );
  const [couponDurationType, setCouponDurationType] = useState(
    CouponDurationType.once
  );
  const [maxTotalRedemptions, setMaxTotalRedemptions] = useState(0);
  const [unlimitedTotalRedemptions, setUnlimitedTotalRedemptions] =
    useState(false);
  const [maxRedemptionValue, setMaxRedemptionValue] = useState(0);
  const [unlimitedRedemptionValue, setUnlimitedRedemptionValue] =
    useState(false);
  const [expirationDate, setExpirationDate] = useState(moment());
  const [valid, setValid] = useState(false);
  const [submitErrors, setSubmitErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    displayCode,
    amount,
    couponDeductionType,
    couponDurationType,
    maxTotalRedemptions,
    maxRedemptionValue,
    expirationDate,
  });

  console.log("maxRedemptionValue", maxRedemptionValue);

  const formErrors = {
    organization: {
      empty: !organization?.id && "Please select an organization",
    },
    displayCode: {
      empty:
        blurredFormFields.displayCode &&
        !validatePresence(displayCode) &&
        "Please enter a display code",
    },
    amount: {
      empty:
        blurredFormFields?.amount &&
        !amount &&
        "Please enter an amount for this promo code",
      lessThanOneDollar:
        amount && amount < 1 && "Amount must have a value greater than 1",
      moreThanNinetyFivePercent:
        couponDeductionType === CouponDeductionType.percentOff &&
        amount &&
        amount > 100 &&
        "Amount must be 100% or less",
    },
    couponDeductionType: {
      empty:
        blurredFormFields?.couponCodeDeductionType &&
        !validateCouponDeductionType(couponDeductionType) &&
        "Please select a deduction type",
    },
    couponDurationType: {
      empty:
        blurredFormFields?.couponDurationType &&
        !validateCouponDurationType(couponDurationType) &&
        "Please select a duration type",
    },
    maxRedemptionValue: {
      empty:
        couponDeductionType === CouponDeductionType.percentOff &&
        !unlimitedRedemptionValue &&
        !maxRedemptionValue &&
        `Please enter a max redemption value for this promo code or check "Unlimited" to leave this blank`,
      lessThanOne:
        couponDeductionType === CouponDeductionType.percentOff &&
        !unlimitedRedemptionValue &&
        maxRedemptionValue &&
        maxRedemptionValue < 1 &&
        "Max redemption value must be greater than 1",
    },
    maxTotalRedemptions: {
      empty:
        couponDurationType === CouponDurationType.forever &&
        !unlimitedTotalRedemptions &&
        !maxTotalRedemptions &&
        `Please enter a value for max number of redemptions value for this promo code or check "Unlimited" to leave this blank`,
      lessThanOne:
        couponDurationType === CouponDurationType.forever &&
        !unlimitedTotalRedemptions &&
        maxTotalRedemptions &&
        maxTotalRedemptions < 1 &&
        "Max number of redemptions must have a value greater than 1",
    },
    expirationDate: {
      empty:
        couponDurationType === CouponDurationType.repeating &&
        !expirationDate &&
        "Please an expiration date for this promo code",
      minDate:
        couponDurationType === CouponDurationType.repeating &&
        !moment(expirationDate, true).isSameOrAfter(moment(new Date(), true)) &&
        "Expiration date must be in the future",
    },
  };

  const parsedFormErrors = filterFormErrorObject(formErrors);

  const submitDisabled =
    !organization?.id ||
    !validatePresence(displayCode) ||
    !amount ||
    Boolean(amount && amount < 1) ||
    Boolean(
      couponDeductionType === CouponDeductionType.percentOff &&
        amount &&
        amount > 100
    ) ||
    !validateCouponDeductionType(couponDeductionType) ||
    !validateCouponDurationType(couponDurationType) ||
    (couponDeductionType === CouponDeductionType.percentOff &&
      !unlimitedRedemptionValue &&
      !maxRedemptionValue) ||
    Boolean(
      couponDeductionType === CouponDeductionType.percentOff &&
        !unlimitedRedemptionValue &&
        maxRedemptionValue &&
        maxRedemptionValue < 1
    ) ||
    (couponDurationType === CouponDurationType.forever &&
      !unlimitedTotalRedemptions &&
      !maxTotalRedemptions) ||
    Boolean(
      couponDurationType === CouponDurationType.forever &&
        !unlimitedTotalRedemptions &&
        maxTotalRedemptions &&
        maxTotalRedemptions < 1
    ) ||
    (couponDurationType === CouponDurationType.repeating && !expirationDate) ||
    (couponDurationType === CouponDurationType.repeating &&
      !moment(expirationDate, true).isSameOrAfter(moment(new Date(), true)));
  const resetFormFields = () => {
    resetBlurState();
    setOrganization(null);
    setOrganizationSearchText("");
    setDisplayCode("");
    setAmount(0);
    setCouponDeductionType(CouponDeductionType.amountOffInCents);
    setCouponDurationType(CouponDurationType.once);
    setMaxTotalRedemptions(0);
    setUnlimitedTotalRedemptions(false);
    setMaxRedemptionValue(0);
    setUnlimitedRedemptionValue(false);
    setExpirationDate(moment());
    setValid(false);
    setSubmitErrors("");
  };

  const { mutate: addNewPromoCode, isLoading: isInviteNewUserLoading } =
    useMutation({
      mutationFn: createNewPromoCode,
      onSuccess: async (data: any) => {
        console.log("data after submit", data);
        await queryClient.cancelQueries({
          queryKey: ["promo-codes"],
        });

        await queryClient.invalidateQueries({
          queryKey: ["promo-codes"],
        });

        setOpen(false);
        // setAlert({
        //   type: "SUCCESS",
        //   message: "USER INVITE SENT",
        // });
        setSuccessMessage("New promo code created");
        resetFormFields();
      },
      onError: async (error: any) => {
        console.log("error", error);
        // setShowError(true);
        // setErrorMessage(error);
        setSubmitErrors(error);

        // setAlert({
        //   type: "ERROR",
        //   message: "FAILED TO INVITE USER",
        // });
      },
    });

  const handleSubmit = () => {
    setSubmitErrors("");
    const formattedData = formatPromoCodeSubmissionData({
      organizationId: organization?.id,
      displayCode,
      amount,
      couponDeductionType,
      couponDurationType,
      valid,
      maxRedemptionValue: unlimitedRedemptionValue ? null : maxRedemptionValue,
      maxTotalRedemptions: unlimitedTotalRedemptions
        ? null
        : maxTotalRedemptions,
      expirationDate: expirationDate?.toDate(),
    });
    addNewPromoCode({
      accessToken,
      ...formattedData,
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
    // setSelectedCompanyVoucher(null);
  };

  const handleDecimalInput = (e: any, setValue: any) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setValue(value);
    }
  };
  const handleWholeNumberInput = (e: any, setValue: any) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}$/)) {
      setValue(value);
    }
  };

  const handleAmountChange = (e: any, setValue: any) => {
    switch (couponDeductionType) {
      case CouponDeductionType.amountOffInCents:
        // accept whole numbers and up to 2 decimals
        handleDecimalInput(e, setValue);
        break;
      case CouponDeductionType.percentOff:
        // only accept whole numbers
        handleWholeNumberInput(e, setValue);
    }
  };

  const handleUnlimitedRedemptionValueCheckChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setUnlimitedRedemptionValue(e.target.checked);
    if (e.target.checked) {
      setMaxRedemptionValue(0);
    }
  };

  const handleUnlimitedTotalRedemptionsCheckChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setUnlimitedTotalRedemptions(e.target.checked);
    if (e.target.checked) {
      setMaxTotalRedemptions(0);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitErrors)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
          setSubmitErrors("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitErrors("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitErrors}
        </Alert>
      </Snackbar>
      <Modal
        loading={isInviteNewUserLoading}
        open={open}
        onSubmit={handleSubmit}
        handleClose={handleClose}
        onCancel={handleClose}
        cancelButtonLabel="Cancel"
        submitButtonLabel="Confirm"
        title="Add new Promo Code"
        submitButtonDisabled={submitDisabled}
      >
        <Grid container spacing={3}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <OrganizationAutoComplete
                inputValue={organizationSearchText}
                setInputValue={setOrganizationSearchText}
                value={organization}
                setValue={setOrganization}
                accessToken={accessToken}
              />
            </Grid>
            {mapObjectValues(
              parsedFormErrors.organization,
              (key: any, index: any) => (
                <ErrorHelperText
                  key={`create-promo-code-organization-errors-${index}`}
                  errorText={parsedFormErrors.organization[key]}
                />
              )
            )}
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(parsedFormErrors?.displayCode)}
                fullWidth
                value={displayCode}
                onChange={(e) => {
                  setDisplayCode(
                    removeWhitespaces(e.target.value.toUpperCase())
                  );
                }}
                onFocus={() => {
                  setFormFieldToFocused("displayCode");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("displayCode");
                }}
                required
                label="Display Code"
                variant="outlined"
              />
              {mapObjectValues(
                parsedFormErrors?.displayCode,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-display-code-errors-${index}`}
                    errorText={parsedFormErrors?.displayCode[key]}
                  />
                )
              )}
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TextField
                error={objectHasKeys(parsedFormErrors?.amount)}
                fullWidth
                placeholder="Amount"
                label="Amount"
                value={amount}
                onFocus={() => {
                  setFormFieldToFocused("amount");
                }}
                onChange={(e) => {
                  handleAmountChange(e, setAmount);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("amount");
                  setAmount((prev: any) => Number(prev));
                }}
                InputProps={{
                  ...(couponDeductionType ===
                  CouponDeductionType?.amountOffInCents
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }
                    : {
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }),
                }}
                variant="outlined"
              />
              {mapObjectValues(
                parsedFormErrors?.amount,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-amount-errors-${index}`}
                    errorText={parsedFormErrors?.amount[key]}
                  />
                )
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="deductionType">Deduction Type</InputLabel>
                <Select
                  sx={{
                    "&.Mui-disabled": {
                      "&:before": {
                        borderBottomStyle: "solid",
                      },
                    },
                  }}
                  value={couponDeductionType}
                  label="Deduction Type"
                  onChange={(e: any) => {
                    setCouponDeductionType(e.target.value);
                  }}
                  labelId="deductionType"
                  onFocus={() => {
                    setFormFieldToFocused("couponDeductionType");
                  }}
                  onBlur={() => {
                    setFormFieldToBlurred("couponDeductionType");
                  }}
                  variant="outlined"
                >
                  <MenuItem value={CouponDeductionType.amountOffInCents}>
                    Dollars
                  </MenuItem>
                  <MenuItem value={CouponDeductionType.percentOff}>
                    Percent
                  </MenuItem>
                </Select>
              </FormControl>
              {mapObjectValues(
                parsedFormErrors?.couponDeductionType,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-deduction-type-errors-${index}`}
                    errorText={parsedFormErrors?.couponDeductionType[key]}
                  />
                )
              )}
            </Grid>
          </Grid>
          {couponDeductionType === CouponDeductionType.percentOff && (
            <Grid container item spacing={2} alignItems={"center"}>
              <Grid item xs={9}>
                <TextField
                  disabled={unlimitedRedemptionValue}
                  error={objectHasKeys(parsedFormErrors?.maxRedemptionValue)}
                  fullWidth
                  placeholder="Max Redemption Value (In Dollars)"
                  label="Max Redemption Value (In Dollars)"
                  value={maxRedemptionValue}
                  onFocus={() => {
                    setFormFieldToFocused("maxRedemptionValue");
                  }}
                  onChange={(e) => {
                    handleDecimalInput(e, setMaxRedemptionValue);
                  }}
                  onBlur={() => {
                    setFormFieldToBlurred("maxRedemptionValue");

                    setMaxRedemptionValue((prev: any) => Number(prev));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unlimitedRedemptionValue}
                      value={unlimitedRedemptionValue}
                      onChange={handleUnlimitedRedemptionValueCheckChange}
                    />
                  }
                  sx={{ color: "#868686" }}
                  label="Unlimited"
                />
              </Grid>
              {mapObjectValues(
                parsedFormErrors?.maxRedemptionValue,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-max-redemption-value-errors-${index}`}
                    errorText={parsedFormErrors?.maxRedemptionValue[key]}
                  />
                )
              )}
            </Grid>
          )}
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="deductionType">Duration Type</InputLabel>
                <Select
                  error={objectHasKeys(parsedFormErrors.couponDurationType)}
                  sx={{
                    "&.Mui-disabled": {
                      "&:before": {
                        borderBottomStyle: "solid",
                      },
                    },
                  }}
                  value={couponDurationType}
                  label="Duration Type"
                  onChange={(e: any) => {
                    setCouponDurationType(e.target.value);
                  }}
                  labelId="deductionType"
                  onFocus={() => {
                    setFormFieldToFocused("couponDurationType");
                  }}
                  onBlur={() => {
                    setFormFieldToBlurred("couponDurationType");
                  }}
                  variant="outlined"
                >
                  <MenuItem value={CouponDurationType.once}>
                    Single Time Use
                  </MenuItem>
                  <MenuItem value={CouponDurationType.oncePerCustomer}>
                    Once Per Customer
                  </MenuItem>
                  <MenuItem value={CouponDurationType.forever}>
                    Forever
                  </MenuItem>
                  <MenuItem value={CouponDurationType.repeating}>
                    Repeating
                  </MenuItem>
                </Select>
              </FormControl>
              {mapObjectValues(
                parsedFormErrors?.couponDurationType,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-duration-type-errors-${index}`}
                    errorText={parsedFormErrors?.couponDurationType[key]}
                  />
                )
              )}
            </Grid>
          </Grid>
          {couponDurationType === CouponDurationType.forever && (
            <Grid container item spacing={2} alignItems={"center"}>
              <Grid item xs={9}>
                <TextField
                  disabled={unlimitedTotalRedemptions}
                  error={objectHasKeys(parsedFormErrors?.maxTotalRedemptions)}
                  fullWidth
                  placeholder="Max Number of Redemptions"
                  label="Max Number of Redemptions"
                  value={maxTotalRedemptions}
                  onFocus={() => {
                    setFormFieldToFocused("maxTotalRedemptions");
                  }}
                  onChange={(e) => {
                    handleWholeNumberInput(e, setMaxTotalRedemptions);
                  }}
                  onBlur={() => {
                    setFormFieldToBlurred("maxTotalRedemptions");

                    setMaxRedemptionValue((prev: any) => Number(prev));
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unlimitedTotalRedemptions}
                      value={unlimitedTotalRedemptions}
                      onChange={handleUnlimitedTotalRedemptionsCheckChange}
                    />
                  }
                  sx={{ color: "#868686" }}
                  label="Unlimited"
                />
              </Grid>
              {mapObjectValues(
                parsedFormErrors?.maxTotalRedemptions,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-max-total-redemptions-errors-${index}`}
                    errorText={parsedFormErrors?.maxTotalRedemptions[key]}
                  />
                )
              )}
            </Grid>
          )}
          {couponDurationType === CouponDurationType.repeating && (
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Expiration Date"
                  value={expirationDate}
                  onChange={(newValue: any) => {
                    setExpirationDate(newValue);
                  }}
                  minDate={moment.utc(currentDate, true)}
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: "outlined",
                      onBlur: () => {
                        setFormFieldToBlurred("expirationDate");
                      },
                      onFocus: () => {
                        setFormFieldToFocused("expirationDate");
                      },
                      error: Boolean(
                        objectHasKeys(parsedFormErrors?.expirationDate)
                      ),
                    },
                  }}
                />
              </Grid>
              {mapObjectValues(
                parsedFormErrors?.expirationDate,
                (key: any, index: any) => (
                  <ErrorHelperText
                    key={`create-promo-code-expiration-date-errors-${index}`}
                    errorText={parsedFormErrors?.expirationDate[key]}
                  />
                )
              )}
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Grid item>
              <Typography fontWeight={valid ? 400 : 700}>Disabled</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={valid}
                onChange={(event: any) => {
                  setValid(event.target.checked);
                }}
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={valid ? 700 : 400}>Enabled</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </LocalizationProvider>
  );
};

export default CreatePromoCodeModalWithOrganizationSelector;
