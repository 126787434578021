import { useRecoilState } from "recoil";
import { userAuthState } from "../../../../atom";
import { Grid, IconButton, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  convertCentsToDollars,
  convertToKilometers,
} from "../../../../helpers/formatters";
import { useBillingSettings } from "../../../../hooks/useBillingSettings";
import { useAdditionalPickUpRequestCharges } from "../../../../hooks/useAdditionalPickUpRequestCharges";
import AdditionalChargesList from "./AdditionalChargesList/AdditionalChargesList";
import { isCurrentAdminSuperAdmin } from "../../../../helpers/authHelpers";
import Button from "../../../../components/Button/Button";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AdditionalPickUpRequestChargeModal from "./AdditionalPickUpRequestChargeModal/AdditionalPickUpRequestChargeModal";
import AdditionalPickUpRequestChargeDeleteModal from "./AdditionalPickUpRequestChargeDeleteModal/AdditionalPickUpRequestChargeDeleteModal";
// import VehicleClassMappingsList from "./VehicleClassMappingsList/VehicleClassMappingsList";
// import AdditionalChargesListitem from "./AdditionalChargesListItem/AdditionalChargesListItem";

const AdditionalChargesTab = ({
  organizationId,
}: {
  organizationId?: string;
}) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data: additionalChargesData } = useAdditionalPickUpRequestCharges({
    accessToken: authDetails?.accessToken,
    organizationId,
  });

  const [
    selectedAdditionalPickUpRequestCharge,
    setSelectedAdditionalPickUpRequestCharge,
  ] = useState<any>(null);

  const [showAdditionalPickUpChargeModal, setShowAdditionalPickUpChargeModal] =
    useState(false);
  const [
    showAdditionalPickUpChargeDeleteModal,
    setShowAdditionalPickUpChargeDeleteModal,
  ] = useState(false);

  console.log("additionalCharges", additionalChargesData);

  useEffect(() => {
    const handleDataChange = () => {
      const isAdditionalChargePresentInData = additionalChargesData?.some(
        (additionalCharge: any) =>
          additionalCharge.id === selectedAdditionalPickUpRequestCharge?.id
      );

      if (!isAdditionalChargePresentInData) {
        setShowAdditionalPickUpChargeModal(false);
        setShowAdditionalPickUpChargeDeleteModal(false);
        setSelectedAdditionalPickUpRequestCharge(null);
      } else {
        const voucherInData = additionalChargesData?.find(
          (additionalCharge: any) =>
            additionalCharge.id === selectedAdditionalPickUpRequestCharge?.id
        );
        setSelectedAdditionalPickUpRequestCharge(voucherInData);
      }
    };

    console.log(
      "selected charge changed",
      selectedAdditionalPickUpRequestCharge
    );

    if (
      additionalChargesData &&
      additionalChargesData?.length &&
      selectedAdditionalPickUpRequestCharge
    ) {
      handleDataChange();
    } else if (!additionalChargesData || !additionalChargesData.length) {
      setShowAdditionalPickUpChargeModal(false);
      setShowAdditionalPickUpChargeDeleteModal(false);
      setSelectedAdditionalPickUpRequestCharge(null);
    }
  }, [additionalChargesData, selectedAdditionalPickUpRequestCharge]);

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      rowSpacing={2}
      container
      direction={"column"}
      justifyContent={"space-evenly"}
    >
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Grid container item spacing={1} xs={12}>
          {/* Heading */}
          <Grid item xs={12}>
            <Typography fontSize={21} fontWeight={500}>
              Mandatory Service Charges
            </Typography>
          </Grid>

          {isCurrentAdminSuperAdmin(authDetails) && (
            <>
              <AdditionalPickUpRequestChargeDeleteModal
                accessToken={authDetails?.accessToken}
                open={showAdditionalPickUpChargeDeleteModal}
                setOpen={setShowAdditionalPickUpChargeDeleteModal}
                organizationId={organizationId}
                selectedAdditionalPickUpRequestCharge={
                  selectedAdditionalPickUpRequestCharge
                }
                setSelectedAdditionalPickUpRequestCharge={
                  setSelectedAdditionalPickUpRequestCharge
                }
              />
              <AdditionalPickUpRequestChargeModal
                accessToken={authDetails?.accessToken}
                open={showAdditionalPickUpChargeModal}
                editMode={Boolean(selectedAdditionalPickUpRequestCharge)}
                setOpen={setShowAdditionalPickUpChargeModal}
                organizationId={organizationId}
                selectedAdditionalPickUpRequestCharge={
                  selectedAdditionalPickUpRequestCharge
                }
                setSelectedAdditionalPickUpRequestCharge={
                  setSelectedAdditionalPickUpRequestCharge
                }
              />
              <Grid container item justifyContent={"flex-end"}>
                <Grid item>
                  <Button
                    onClick={() => {
                      setShowAdditionalPickUpChargeModal(true);
                    }}
                    icon={<PriceChangeIcon sx={{ marginRight: 2 }} />}
                  >
                    Create new mandatory serice charge
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <AdditionalChargesList
              additionalPickUpRequestChargesData={additionalChargesData}
              onItemEditClick={(additionalCharge: any) => {
                setSelectedAdditionalPickUpRequestCharge(additionalCharge);
                setShowAdditionalPickUpChargeModal(true);
              }}
              onItemDeleteClick={(additionalCharge: any) => {
                setSelectedAdditionalPickUpRequestCharge(additionalCharge);
                setShowAdditionalPickUpChargeDeleteModal(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalChargesTab;
