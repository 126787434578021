import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../components/BreadcrumbSection/BreadcrumbSection";
import { useDrivers } from "../../hooks/useDrivers";
import {
  getAccessToken,
  isCurrentAdminFromRootOrganization,
  isCurrentAdminSuperAdmin,
} from "../../helpers/authHelpers";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../components/PaginationTable/PaginationTable";
import {
  PickUpStatus,
  cancelledStatuses,
  formatVehicleTypesForServiceCall,
  getVehicleTypesForServiceCall,
  mappedVehicleTypes,
  pendingStatuses,
} from "../../helpers/validationHelpers";
import { roundToPrecision } from "../../helpers/formatters";
import moment from "moment";
import useCSVDownloadState from "../../hooks/useCSVDownloadState";
import { AuthContext } from "../../context/authContext";
import { getCompaniesCSV, useCompanies } from "../../hooks/useCompanies";
import Button from "../../components/Button/Button";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import { getAdminsCSV, useAdmins } from "../../hooks/useAdmins";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddNewAdminModal from "./AddNewAdminModal/AddNewAdminModal";

const Admins = () => {
  const [showInviteNewAdminModal, setShowInviteNewAdminModal] = useState(false);

  const {
    csvDownloadLoading: adminsCsvDownloadLoading,
    setCsvDownloadLoading: setAdminsCsvDownloadLoading,
    csvData: adminsCsvData,
    setCsvData: setAdminsCsvData,
    setCsvFilename: setAdminsCsvFilename,
    csvLinkRef: adminsCsvLinkRef,
    setShouldDownload: setShouldDownloadAdminsCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const {
    data: adminsData,
    isLoading,
    isFetching,
  } = useAdmins({
    accessToken: authDetails.accessToken,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
    organizationId: isCurrentAdminFromRootOrganization(authDetails)
      ? undefined
      : authDetails?.organizationId,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Admin ID",
      width: 300,
      renderCell: (params) => (
        <Link
          to={`/dashboard/admins/${params.row.id}`}
          state={{ prevPage: location.pathname }}
        >
          {params.row.id}
        </Link>
      ),
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 125,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 125,
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      valueGetter: (params: GridCellParams) =>
        `${params.row.role === "SUPERADMIN" ? "Super Admin" : "Operator"}`,
    },
    {
      field: "email",
      headerName: "Email",
      // type: "number",
      width: 150,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
    },
    ...(isCurrentAdminFromRootOrganization(authDetails) &&
    isCurrentAdminSuperAdmin(authDetails)
      ? [
          {
            field: "organizationName",
            headerName: "Organization",
            width: 150,
            valueGetter: (params: GridCellParams) =>
              `${params?.row?.organization?.name}`,
            renderCell: (params: GridCellParams) => (
              <Link
                to={`/dashboard/organizations/${params.row.organizationId}`}
                state={{ prevPage: location.pathname }}
              >
                {params.row.organization.name}
              </Link>
            ),
          },
        ]
      : []),
    {
      field: "createdAt",
      headerName: "Created At",
      width: 225,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("Do MMMM  YYYY, h:mm a")}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/admins/${params.row.id}`, {
              state: {
                prevPage: location.pathname,
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadAdminsCSV = async () => {
    setAdminsCsvDownloadLoading(true);
    try {
      const result = await getAdminsCSV(
        authDetails.accessToken,
        isCurrentAdminFromRootOrganization(authDetails)
          ? undefined
          : authDetails?.organizationId
      );
      setAdminsCsvData(result?.data);
      setAdminsCsvFilename(result?.filename);
      setShouldDownloadAdminsCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setAdminsCsvDownloadLoading(false);
    }
  };
  return (
    <DashboardLayout loading={isLoading}>
      <BreadcrumbSection
        crumbs={[
          {
            url: "/dashboard",
            title: "Dashboard",
          },
          {
            url: "/dashboard/admins",
            title: "Team Management",
          },
        ]}
        pageTitle="Team Management"
      />
      {isCurrentAdminSuperAdmin(authDetails) && (
        <>
          <AddNewAdminModal
            organizationId={
              isCurrentAdminFromRootOrganization(authDetails)
                ? undefined
                : authDetails?.organizationId
            }
            accessToken={authDetails?.accessToken}
            open={showInviteNewAdminModal}
            setOpen={setShowInviteNewAdminModal}
          />
          <Grid container item justifyContent={"flex-end"}>
            <Grid>
              <Button
                onClick={() => {
                  setShowInviteNewAdminModal(true);
                }}
                icon={<GroupAddIcon sx={{ marginRight: 2 }} />}
              >
                Invite New Team Member
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={adminsData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          exportButtonLoading={adminsCsvDownloadLoading}
          onExportButtonClick={downloadAdminsCSV}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={adminsData?.totalCount}
          pageCount={adminsData?.totalPages}
        />
      </Grid>
      {adminsCsvData && (
        <a
          style={{ display: "none" }}
          ref={adminsCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(adminsCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </DashboardLayout>
  );
};

export default Admins;
