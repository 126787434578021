import {
  Alert,
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ReactElement, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
const PromoCodeDetailsPanel = ({
  // the ID of the admin viewing this profile panel
  promoCodeId,
  displayCode,
  children,
  withEdit = true,
  onEditClick,
}: {
  promoCodeId: string;
  displayCode: string;
  withEdit?: boolean;
  onEditClick: () => void;
  children: ReactElement;
}) => {
  return (
    <Grid item xs={12} lg={4}>
      <Paper sx={{ padding: 2, position: "relative" }}>
        {withEdit && (
          <IconButton
            sx={{ position: "absolute", top: "15px", right: "15px" }}
            color="primary"
            onClick={onEditClick}
          >
            <EditOutlinedIcon sx={{ color: "#28a1fa" }} />
          </IconButton>
        )}
        <Grid container spacing={2}>
          {/* avatar and username */}
          <Grid container item xs={9} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={700} fontSize={22}>
                {displayCode}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "#610095",
                  padding: "4px 8px",
                  borderRadius: "3px",
                }}
              >
                <Typography sx={{ color: "white" }} fontSize={14}>
                  {promoCodeId}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        {children}
      </Paper>
    </Grid>
  );
};

export default PromoCodeDetailsPanel;
