import { Typography, Grid, Alert, Snackbar } from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCompanyPickUpRequestVoucherType } from "../../../../../hooks/useCompanyPickUpRequestVoucherTypes";
import { useState } from "react";
import { deleteAdditionalPickUpRequestCharge } from "../../../../../hooks/useAdditionalPickUpRequestCharges";

const AdditionalPickUpRequestChargeDeleteModal = ({
  accessToken,
  organizationId,
  open,
  setOpen,
  selectedAdditionalPickUpRequestCharge,
  setSelectedAdditionalPickUpRequestCharge,
}: {
  accessToken: string;
  organizationId?: string;
  selectedAdditionalPickUpRequestCharge: any;
  setSelectedAdditionalPickUpRequestCharge: any;
  open: boolean;
  setOpen: any;
}) => {
  const queryClient = useQueryClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const {
    mutate: handleDeleteAdditionalPickUpRequestCharge,
    isLoading: isDeleteAdditionalPickUpRequestChargeLoading,
  } = useMutation({
    mutationFn: deleteAdditionalPickUpRequestCharge,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });
      setOpen(false);
      setSelectedAdditionalPickUpRequestCharge(null);
      setShowSuccess(true);
    },
    onError: async (error: any) => {
      console.log("error adding new payment method", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    handleDeleteAdditionalPickUpRequestCharge({
      accessToken,
      additionalChargeId: selectedAdditionalPickUpRequestCharge?.id,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAdditionalPickUpRequestCharge(null);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccess(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Mandatory service call charge deleted
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={isDeleteAdditionalPickUpRequestChargeLoading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        title={"Confirm mandatory service call charge deletion"}
        submitButtonLabel="Delete"
      >
        <Grid item>
          <Typography>
            Are you sure you want to delete this mandatory service call charge?{" "}
            <Typography fontWeight={700} component="span">
              This action is permanent and can result in data loss.
            </Typography>
          </Typography>
        </Grid>
      </Modal>
    </>
  );
};

export default AdditionalPickUpRequestChargeDeleteModal;
