import {
  Accordion,
  AccordionSummary,
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MuveIt from "../../../assets/images/muveit.png";
import NavBarPageButton from "./NavBarPageButton";
import { HomeOutlined, PeopleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAdminDetails } from "../../../hooks/useAdminUser";
import { AuthContext } from "../../../context/authContext";
import BusinessIcon from "@mui/icons-material/Business";
import { useRecoilState } from "recoil";
import { userAuthState } from "../../../atom";
import { useAuth } from "../../../hooks/useAuth";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LogoSection from "./LogoSection";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  isCurrentAdminFromRootOrganization,
  isCurrentAdminSuperAdmin,
} from "../../../helpers/authHelpers";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

const DashboardNavBar = ({ loading }: any) => {
  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const navigation = useNavigate();
  const { logoutAndResetAuthState } = useAuth();

  const { data: adminUserData } = useAdminDetails(authDetails?.accessToken);

  console.log("adminUserData", adminUserData);

  // iconName key refers to icon classes that were grandfathered in from the old Navsoft web panel
  // icon key is just MUI icons
  const pages = [
    {
      name: "Dashboard",
      linkTo: "/dashboard",
      iconName: "home",
      icon: null,
    },
    {
      name: "Customers",
      linkTo: "/dashboard/customers",
      iconName: "users",
      icon: null,
    },
    {
      name: "Drivers",
      linkTo: "/dashboard/drivers",
      iconName: "driver",
      icon: null,
    },
    {
      name: "Service Calls",
      linkTo: "/dashboard/service-calls",
      iconName: "forwaded-call",
      icon: null,
    },
    {
      name: "Scheduled Service Calls",
      linkTo: "/dashboard/scheduled-service-calls",
      iconName: "calendar",
      icon: null,
    },
    {
      name: "SC Submissions",
      linkTo: "/dashboard/service-call-submission-attempts",
      iconName: "missed",
      icon: null,
    },

    ...(isCurrentAdminFromRootOrganization(adminUserData)
      ? [
          ...(isCurrentAdminSuperAdmin(adminUserData)
            ? [
                {
                  name: "BPP",
                  icon: (
                    <BusinessIcon sx={{ fontSize: 16, marginRight: "10px" }} />
                  ),
                  linkTo: "/dashboard/companies",
                  iconName: null,
                },
              ]
            : []),
          {
            name: "Organizations",
            icon: <Diversity3Icon sx={{ fontSize: 16, marginRight: "10px" }} />,
            linkTo: "/dashboard/organizations",
            iconName: null,
          },
        ]
      : [
          {
            name: "Organization",
            icon: <BusinessIcon sx={{ fontSize: 16, marginRight: "10px" }} />,
            linkTo: "/dashboard/current-organization",
            iconName: null,
          },
        ]),
    {
      name: "Team Management",
      icon: <GroupWorkIcon sx={{ fontSize: 16, marginRight: "10px" }} />,
      linkTo: "/dashboard/admins",
      iconName: null,
    },
    {
      name: "Promo Codes",
      icon: <LocalActivityIcon sx={{ fontSize: 16, marginRight: "10px" }} />,
      linkTo: "/dashboard/promo-codes",
      iconName: null,
    },
    // {
    //   name: "Driver Payment",
    //   linkTo: "/driver-payout-list",
    //   iconName: "money",
    //   icon: null,
    // },
    // {
    //   name: "Vehicles",
    //   linkTo: "/vehicle-list",
    //   iconName: "car",
    // },
    // {
    //   name: "Payment",
    //   linkTo: "/payment-list",
    //   iconName: "money",
    // },
    // {
    //   name: "Supports",
    //   linkTo: "/support-list",
    //   iconName: "support",
    //   icon: null,
    // },
  ];
  const settings = ["Logout"];

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(false);
  const handleToggleNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setExpanded((prev) => !prev);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    setAnchorElUser(null);
    await logoutAndResetAuthState();
  };

  const navigateToAccountSettings = async () => {
    setAnchorElUser(null);
    navigation("/dashboard/settings");
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#fff" }} elevation={0}>
      {loading && (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "rgb(242, 104, 34)",
            },
          }}
        />
      )}
      <Container maxWidth={false} disableGutters>
        <Toolbar
          disableGutters
          sx={{
            px: { xs: "20px", md: "40px" },
            borderBottom: { xs: "none", md: "1px solid #c3c3c3" },
            justifyContent: "space-between",
          }}
        >
          <LogoSection
            accessToken={authDetails?.accessToken}
            organizationId={authDetails?.organizationId}
            isRootOrganization={adminUserData?.organization?.isRootOrganization}
          />

          {/* mobile only */}
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#6D6D6D",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}

          {/* always here no matter what */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={`${adminUserData?.firstName} ${adminUserData?.lastName}`}
                  src={adminUserData?.avatar}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <ListItemIcon>
                  <Avatar
                    alt={`${adminUserData?.firstName} ${adminUserData?.lastName}`}
                    src={adminUserData?.avatar}
                    sx={{ width: 30, height: 30 }}
                  />
                </ListItemIcon>
                <ListItemText>
                  {adminUserData?.firstName} {adminUserData?.lastName}
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={navigateToAccountSettings}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText>Account settings</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
          {/* mobile only */}
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "none" },
              paddingLeft: "10px",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleToggleNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
        <Toolbar disableGutters sx={{ px: { xs: 0, md: "40px" } }}>
          {/* nav menu items */}
          <Box
            component={"ul"}
            sx={{
              flexGrow: 1,
              flexDirection: "row",
              listStyle: "none",
              paddingLeft: 0,
              marginTop: 0,
              marginBottom: 0,
              // minHeight: "64px",
              // alignItems: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <NavBarPageButton
                key={`desktop-${page.name}`}
                label={page.name}
                iconName={page.iconName}
                linkTo={page.linkTo}
                icon={page?.icon}
              />
            ))}
          </Box>
          <Accordion
            elevation={0}
            disableGutters
            expanded={expanded}
            component={"ul"}
            sx={{
              width: "100%",
              display: { xs: "flex", md: "none" },
              listStyle: "none",
              paddingLeft: 0,
              marginTop: expanded ? 4 : 0,
              mx: 0,
              marginBottom: 0,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary sx={{ paddingLeft: 0 }}></AccordionSummary>
            {pages.map((page) => (
              <NavBarPageButton
                key={`mobile-${page.name}`}
                label={page.name}
                iconName={page.iconName}
                linkTo={page.linkTo}
                icon={page?.icon}
              />
            ))}
          </Accordion>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default DashboardNavBar;
