import { Badge, Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import DashboardLayout from "../../../../layout/DashboardLayout/DashboardLayout";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbSection from "../../../../components/BreadcrumbSection/BreadcrumbSection";
import {
  getAccessToken,
  isCurrentAdminFromRootOrganization,
  isCurrentAdminSuperAdmin,
} from "../../../../helpers/authHelpers";
import { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { darken, lighten, styled } from "@mui/material/styles";
import PaginationTable from "../../../../components/PaginationTable/PaginationTable";
import moment from "moment";
import {
  mappedCouponDeductionTypes,
  mappedCouponDurationTypes,
  mappedVehicleTypes,
  toTitleCase,
} from "../../../../helpers/validationHelpers";
import useCSVDownloadState from "../../../../hooks/useCSVDownloadState";
import { AuthContext } from "../../../../context/authContext";
import { userAuthState } from "../../../../atom";
import { useRecoilState } from "recoil";
import AddNewDriverModal from "../../../Organizations/OrganizationDetails/OrganizationDriversTab/AddNewDriverModal/AddNewDriverModal";
import Button from "../../../../components/Button/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import useAdminNearbyDriversSocket from "../../../../sockets/useAdminNearbyDriversSocket";
import {
  getPromoCodesCSV,
  usePromoCodes,
} from "../../../../hooks/usePromoCodes";
import AddNewPromoCodeModal from "../../../PromoCodes/AddNewPromoCodeModal/AddNewPromoCodeModal";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

const PromoCodesTab = ({ organizationId }: { organizationId?: string }) => {
  const {
    csvDownloadLoading: promoCodesCsvDownloadLoading,
    setCsvDownloadLoading: setPromoCodesCsvDownloadLoading,
    csvData: promoCodesCsvData,
    setCsvData: setPromoCodesCsvData,
    setCsvFilename: setPromoCodesCsvFilename,
    csvLinkRef: promoCodesCsvLinkRef,
    setShouldDownload: setShouldDownloadPromoCodesCsv,
  } = useCSVDownloadState();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);
  const [showAddPromoCodeModal, setShowAddPromoCodeModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const { nearbyDrivers, isDriverOnline } = useAdminNearbyDriversSocket({
    accessToken: authDetails?.accessToken,
  });

  const {
    data: promoCodesData,
    isLoading,
    isFetching,
  } = usePromoCodes({
    accessToken: authDetails?.accessToken,
    organizationId,
    page: paginationModel.page,
    search: filterModel?.quickFilterValues?.join(" "),
    sort: sortModel,
  });

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const handleFilterModelChange = async (model: GridFilterModel) => {
    setFilterModel(model);
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Promo Code ID",
      width: 150,
      renderCell: (params) => (
        <Link
          to={`/dashboard/promo-codes/${params?.row?.id}`}
          state={{ prevPage: location.pathname }}
        >
          {params?.row?.id}
        </Link>
      ),
    },
    {
      field: "displayCode",
      headerName: "Display Code",
      width: 150,
      editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      editable: true,
    },
    {
      field: "couponDeductionType",
      headerName: "Deduction Type",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        params?.row?.couponDeductionType &&
        mappedCouponDeductionTypes[params?.row?.couponDeductionType]
          ? mappedCouponDeductionTypes[params?.row?.couponDeductionType]
          : params?.row?.couponDeductionType,
    },
    {
      field: "couponDurationType",
      headerName: "Duration Type",
      width: 150,
      editable: true,
      valueGetter: (params) =>
        params?.row?.couponDurationType &&
        mappedCouponDurationTypes[params?.row?.couponDurationType]
          ? mappedCouponDurationTypes[params?.row?.couponDurationType]
          : params?.row?.couponDurationType,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      width: 125,
      editable: true,
      renderCell: (params) =>
        params?.row?.expirationDate ? (
          <Typography>
            {moment(params.row.expirationDate).format("DD/MM/YYYY")}
          </Typography>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "maxTotalRedemptions",
      headerName: "Max # of Redemptions",
      width: 150,
      editable: true,
      valueGetter: (params) => params?.row?.maxTotalRedemptions ?? "Unlimited",
    },
    {
      field: "maxRedemptionValue",
      headerName: "Maximum Redemption Value",
      width: 175,
      editable: true,
      valueGetter: (params) => params?.row?.maxRedemptionValue ?? "Unlimited",
    },
    {
      field: "exclusiveUserName",
      headerName: "Exclusive User",
      width: 125,
      editable: true,
      renderCell: (params) =>
        params?.row?.exclusiveUser ? (
          <Link
            to={`/dashboard/customer/${params?.row?.exclusiveUserId}`}
            state={{ prevPage: location.pathname }}
          >
            {params?.row?.exclusiveUser?.firstName}{" "}
            {params?.row?.exclusiveUser?.lastName}
          </Link>
        ) : (
          <Typography>N/A</Typography>
        ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 100,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/dashboard/promo-codes/${params.row.id}`, {
              state: {
                prevPage: location.pathname,
              },
            });
          }}
          label="View"
        />,
      ],
    },
  ];

  const downloadPromoCodesCsv = async () => {
    setPromoCodesCsvDownloadLoading(true);
    try {
      const result = await getPromoCodesCSV(
        authDetails?.accessToken,
        organizationId
      );
      setPromoCodesCsvData(result?.data);
      setPromoCodesCsvFilename(result?.filename);
      setShouldDownloadPromoCodesCsv(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setPromoCodesCsvDownloadLoading(false);
    }
  };

  return (
    <Grid
      sx={{ padding: 2, position: "relative" }}
      container
      direction={"column"}
    >
      {isCurrentAdminSuperAdmin(authDetails) && (
        <>
          <AddNewPromoCodeModal
            organizationId={organizationId}
            accessToken={authDetails?.accessToken}
            open={showAddPromoCodeModal}
            setOpen={setShowAddPromoCodeModal}
          />
          <Grid container item justifyContent={"flex-end"}>
            <Grid>
              <Button
                onClick={() => {
                  setShowAddPromoCodeModal(true);
                }}
                icon={<PlaylistAddIcon sx={{ marginRight: 2 }} />}
              >
                Add New Promo Code
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container pb={4}>
        <PaginationTable
          withSearchBar
          columns={columns}
          loading={isLoading || isFetching}
          rows={promoCodesData?.data ?? []}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model: any) => {
            handlePaginationModelChange(model);
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(model: any) => {
            handleFilterModelChange(model);
          }}
          exportButtonLoading={promoCodesCsvDownloadLoading}
          onExportButtonClick={downloadPromoCodesCsv}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(model: any) => {
            handleSortModelChange(model);
          }}
          totalCount={promoCodesData?.totalCount}
          pageCount={promoCodesData?.totalPages}
        />
      </Grid>
      {promoCodesCsvData && (
        <a
          style={{ display: "none" }}
          ref={promoCodesCsvLinkRef}
          href={`data:text/csv;charset=utf-8,${escape(promoCodesCsvData)}`}
          download="filename.csv"
        >
          download
        </a>
      )}
    </Grid>
  );
};

export default PromoCodesTab;
