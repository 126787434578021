import {
  Alert,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
} from "../../../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import ErrorHelperText from "../../../../../components/ErrorHelperText/ErrorHelperText";
import {
  convertDollarsToCents,
  convertKilometersToMeters,
  convertToKilometers,
  roundToPrecision,
} from "../../../../../helpers/formatters";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createNewAdditionalPickUpRequestCharge,
  updateAdditionalPickUpRequestCharge,
} from "../../../../../hooks/useAdditionalPickUpRequestCharges";
import {
  createNewPickUpRequestUserSelectableService,
  updatePickUpRequestUserSelectableService,
} from "../../../../../hooks/usePickUpRequestUserSelectableServices";

const UserSelectableServiceModal = ({
  open,
  setOpen,
  editMode = false,
  selectedPickUpRequestUserSelectableService = null,
  setSelectedPickUpRequestUserSelectableService,
  accessToken,
  organizationId,
}: {
  open: any;
  setOpen: any;
  editMode?: boolean;
  selectedPickUpRequestUserSelectableService?: any;
  setSelectedPickUpRequestUserSelectableService: any;
  organizationId?: string;
  accessToken: string;
}) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [priceInDollars, setPriceInDollars] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    name,
    description,
    priceInDollars,
  });

  useEffect(() => {
    if (editMode && selectedPickUpRequestUserSelectableService) {
      setName(selectedPickUpRequestUserSelectableService?.name);
      setDescription(selectedPickUpRequestUserSelectableService?.description);
      setPriceInDollars(
        roundToPrecision(
          Number(selectedPickUpRequestUserSelectableService?.priceInCents) /
            100,
          2
        )
      );
      setEnabled(selectedPickUpRequestUserSelectableService?.enabled);
    } else {
      resetFormFields();
    }
  }, [editMode, selectedPickUpRequestUserSelectableService]);

  const formErrors: any = {
    name: {
      empty:
        blurredFormFields?.name &&
        !name &&
        "Please enter a name for this optional service charge",
    },
    priceInDollars: {
      empty:
        blurredFormFields?.priceInDollars &&
        !priceInDollars &&
        "Please enter a price for this optional service charge",
      lessThanOneDollar:
        blurredFormFields?.priceInDollars &&
        priceInDollars &&
        priceInDollars < 1 &&
        "Price must be at 1 dollar",
    },
  };

  const nameErrors: any = filterObjectByValues(
    formErrors?.name,
    (key, value) => value
  );

  const priceInDollarsErrors: any = filterObjectByValues(
    formErrors?.priceInDollars,
    (key, value) => value
  );

  const submitButtonDisabled =
    !name || !priceInDollars || Boolean(priceInDollars && priceInDollars < 1);

  const handleNumberInputChange = (e: any, setValue: any) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setValue(e.target.value);
    }
  };

  const resetFormFields = () => {
    setName("");
    setDescription("");
    setEnabled(false);
    setPriceInDollars(0);
    setSelectedPickUpRequestUserSelectableService(null);
    resetBlurState();
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
  };

  const {
    mutate: handleAddNewUserSelectableService,
    isLoading: isAddNewUserSelectableServiceLoading,
  } = useMutation({
    mutationFn: createNewPickUpRequestUserSelectableService,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage(
        "New optional service charge successfully created"
      );
    },
    onError: async (error: any) => {
      console.log("error adding new pickup request charge", error);
      setSubmitError(error);
    },
  });

  const {
    mutate: handleUpdateUserSelectableService,
    isLoading: isUpdateUserSelectableServiceLoading,
  } = useMutation({
    mutationFn: updatePickUpRequestUserSelectableService,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["pickup-request-user-selectable-services", organizationId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage("Optional service charge successfully updated");
    },
    onError: async (error: any) => {
      console.log("error updating user selectable service", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    console.log("editMode", editMode);
    console.log(
      "selectedUserSelectableService",
      selectedPickUpRequestUserSelectableService
    );
    if (editMode && selectedPickUpRequestUserSelectableService?.id) {
      // editMode logic
      handleUpdateUserSelectableService({
        accessToken,
        userSelectableServiceId: selectedPickUpRequestUserSelectableService?.id,
        formFields: {
          name,
          description,
          enabled,
          priceInCents: convertDollarsToCents(priceInDollars),
        },
      });
    } else {
      handleAddNewUserSelectableService({
        accessToken,
        organizationId,
        formFields: {
          name,
          description,
          enabled,
          priceInCents: convertDollarsToCents(priceInDollars),
        },
      });
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={
          isAddNewUserSelectableServiceLoading ||
          isUpdateUserSelectableServiceLoading
        }
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        submitButtonLabel={editMode ? "Update" : "Create"}
        title={
          editMode
            ? "Update optional service charge"
            : "Create new optional service charge"
        }
        submitButtonDisabled={submitButtonDisabled}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(nameErrors)}
                fullWidth
                placeholder="Optional service charge name"
                label="Optional service charge name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("name");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("name");
                }}
                variant={"outlined"}
              />
              {mapObjectValues(nameErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`pickup-request-user-selectable-service-name-errors-${index}`}
                  errorText={nameErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Description"
                label="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("description");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("description");
                }}
                variant={"outlined"}
                multiline={true}
                rows={3}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(priceInDollarsErrors)}
                fullWidth
                placeholder="Price (In Dollars)"
                label="Price (In Dollars)"
                value={priceInDollars}
                onFocus={() => {
                  setFormFieldToFocused("priceInDollars");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setPriceInDollars);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("priceInDollars");
                  setPriceInDollars((prev: any) => Number(prev));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(priceInDollarsErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`pickup-request-user-selectable-service-modal-price-in-dollars-errors-${index}`}
                  errorText={priceInDollarsErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Grid item>
              <Typography fontWeight={enabled ? 400 : 700}>Disabled</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={enabled}
                onChange={(event: any) => {
                  setEnabled(event.target.checked);
                }}
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={enabled ? 700 : 400}>Enabled</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default UserSelectableServiceModal;
