import { GridSortModel } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";
const getOrganizations = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/organizations?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const createOrganization = async ({
  accessToken,
  organizationFields,
  initialAdminFields,
}: any) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organization/create-new-organization`,
      {
        organizationFields,
        initialAdminFields,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const validateOrganizationCreate = async ({
  accessToken,
  organizationFields,
}: {
  accessToken: string;
  organizationFields: any;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organization/validate-organization-create`,
      organizationFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const useOrganizations = ({
  accessToken,
  page = 0,
  search,
  sort,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
}) => {
  return useQuery({
    queryKey: [
      "organizations",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getOrganizations(
        accessToken,
        page,
        search,
        sort && sort?.length > 0 ? qs.stringify({ sortBy: sort[0] }) : undefined
      ),
    keepPreviousData: true,
  });
};

const useOrganizationDetails = ({
  accessToken,
  organizationId,
}: {
  accessToken: string;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: ["organization", organizationId],
    queryFn: () => getOrganizationDetails(accessToken, organizationId),
  });
};

const getOrganizationDetails = async (
  accessToken: string,
  organizationId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/organization/${organizationId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getOrganizationsCSV = async (accessToken: string) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organizations-csv`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const updateOrganizationProfile = async ({
  accessToken,
  organizationId,
  organizationFields,
}: any) => {
  try {
    console.log("organizationFields", organizationFields);
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organization/${organizationId}/update-organization-details`,
      organizationFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const validateOrganizationUserSubmission = async ({
  accessToken,
  organizationId,
  email,
  phoneNumber,
  dateOfBirth,
}: {
  accessToken: string;
  organizationId?: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: Date;
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/organization/${organizationId}/validate-organization-user-submission`,
      {
        email,
        phoneNumber,
        dateOfBirth,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

// const useCompanyDetails = (accessToken: string, serviceCallId?: string) => {
//   return useQuery({
//     queryKey: ["company", serviceCallId],
//     queryFn: () => getCompanyDetails(accessToken, serviceCallId),
//   });
// };

export {
  getOrganizations,
  useOrganizations,
  getOrganizationsCSV,
  createOrganization,
  useOrganizationDetails,
  updateOrganizationProfile,
  validateOrganizationUserSubmission,
  validateOrganizationCreate,
};
