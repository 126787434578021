import { Box, Grid, Hidden, Typography } from "@mui/material";
import LeftLoginSection from "../Login/LeftLoginSection/LeftLoginSection";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { userAuthGatewayLoadingState, userAuthState } from "../../atom";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm";
import VirtualFleetHub from "../../assets/images/virtualfleethub-logo.png";
// import ForgotPasswordForm from "./ForgotPasswordForm";
const ResetPassword = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const token: any = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/", { replace: true });
    }
  }, [token]);

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);
  const [authGatewayLoading, setAuthGatewayLoading] = useRecoilState(
    userAuthGatewayLoadingState
  );
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard" || "/";
  useEffect(() => {
    if (authDetails?.accessToken) {
      navigate(from, { replace: true });
    }
  }, [authDetails, navigate, from]);
  return (
    <Grid container height="100vh">
      <LoadingOverlay visible={authGatewayLoading} />
      <LeftLoginSection />
      <Grid
        container
        justifyContent={"flex-start"}
        alignContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        item
        xs={12}
        md={5}
        sx={{ background: "#fff" }}
      >
        <Grid item mt={"13vh"} mb={"14vh"}>
          <img
            src={VirtualFleetHub}
            style={{ maxHeight: "100%", height: "auto", maxWidth: 380 }}
          />
        </Grid>
        <ResetPasswordForm />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
