import {
  Alert,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { useEffect, useState } from "react";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
  filterObjectByValues,
  mapObjectValues,
  objectHasKeys,
} from "../../../../../helpers/validationHelpers";
import useFormFieldsBlurState from "../../../../../hooks/useFormFieldsBlurState";
import ErrorHelperText from "../../../../../components/ErrorHelperText/ErrorHelperText";
import {
  convertDollarsToCents,
  convertKilometersToMeters,
  convertToKilometers,
  roundToPrecision,
} from "../../../../../helpers/formatters";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createNewAdditionalPickUpRequestCharge,
  updateAdditionalPickUpRequestCharge,
} from "../../../../../hooks/useAdditionalPickUpRequestCharges";

const AdditionalPickUpRequestChargeModal = ({
  open,
  setOpen,
  editMode = false,
  selectedAdditionalPickUpRequestCharge = null,
  setSelectedAdditionalPickUpRequestCharge,
  accessToken,
  organizationId,
}: {
  open: any;
  setOpen: any;
  editMode?: boolean;
  selectedAdditionalPickUpRequestCharge?: any;
  setSelectedAdditionalPickUpRequestCharge: any;
  organizationId?: string;
  accessToken: string;
}) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [shouldIgnorePromo, setShouldIgnorePromo] = useState(false);
  const [priceInDollars, setPriceInDollars] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    blurredFormFields,
    setFormFieldToBlurred,
    setFormFieldToFocused,
    resetBlurState,
  } = useFormFieldsBlurState({
    name,
    description,
    priceInDollars,
  });

  useEffect(() => {
    if (editMode && selectedAdditionalPickUpRequestCharge) {
      setName(selectedAdditionalPickUpRequestCharge?.name);
      setDescription(selectedAdditionalPickUpRequestCharge?.description);
      setPriceInDollars(
        roundToPrecision(
          Number(selectedAdditionalPickUpRequestCharge?.priceInCents) / 100,
          2
        )
      );
      setEnabled(selectedAdditionalPickUpRequestCharge?.enabled);
      setShouldIgnorePromo(
        selectedAdditionalPickUpRequestCharge?.shouldIgnorePromo
      );
    } else {
      resetFormFields();
    }
  }, [editMode, selectedAdditionalPickUpRequestCharge]);

  const formErrors: any = {
    name: {
      empty:
        blurredFormFields?.name &&
        !name &&
        "Please enter a name for this mandatory service call charge",
    },
    priceInDollars: {
      empty:
        blurredFormFields?.priceInDollars &&
        !priceInDollars &&
        "Please enter a price for this mandatory service call charge",
      lessThanOneDollar:
        blurredFormFields?.priceInDollars &&
        priceInDollars &&
        priceInDollars < 1 &&
        "Price must be at 1 dollar",
    },
  };

  const nameErrors: any = filterObjectByValues(
    formErrors?.name,
    (key, value) => value
  );

  const priceInDollarsErrors: any = filterObjectByValues(
    formErrors?.priceInDollars,
    (key, value) => value
  );

  const submitButtonDisabled =
    !name || !priceInDollars || Boolean(priceInDollars && priceInDollars < 1);

  const handleNumberInputChange = (e: any, setValue: any) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setValue(e.target.value);
    }
  };

  const resetFormFields = () => {
    setName("");
    setDescription("");
    setEnabled(false);
    setShouldIgnorePromo(false);
    setPriceInDollars(0);
    setSelectedAdditionalPickUpRequestCharge(null);
    resetBlurState();
  };

  const handleClose = () => {
    setOpen(false);
    resetFormFields();
  };

  const {
    mutate: handleAddNewAdditionalPickUpRequestCharge,
    isLoading: isAddNewAdditionalPickUpRequestChargeLoading,
  } = useMutation({
    mutationFn: createNewAdditionalPickUpRequestCharge,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage(
        "New mandatory service call charge successfully created"
      );
    },
    onError: async (error: any) => {
      console.log("error adding new pickup request charge", error);
      setSubmitError(error);
    },
  });

  const {
    mutate: handleUpdateAdditionalPickUpRequestCharge,
    isLoading: isUpdateNewAdditionalPickUpRequestChargeLoading,
  } = useMutation({
    mutationFn: updateAdditionalPickUpRequestCharge,
    onSuccess: async (data) => {
      // resetFields();
      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.cancelQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["additional-pickup-request-charges", organizationId],
      });
      setOpen(false);
      resetFormFields();

      setSuccessMessage("Mandatory service call charge successfully updated");
    },
    onError: async (error: any) => {
      console.log("error adding updating pickup request charge", error);
      setSubmitError(error);
    },
  });

  const handleSubmit = () => {
    console.log("editMode", editMode);
    console.log(
      "selectedPickUpRequestCharge",
      selectedAdditionalPickUpRequestCharge
    );
    if (editMode && selectedAdditionalPickUpRequestCharge?.id) {
      // editMode logic
      handleUpdateAdditionalPickUpRequestCharge({
        accessToken,
        additionalChargeId: selectedAdditionalPickUpRequestCharge?.id,
        formFields: {
          name,
          description,
          enabled,
          shouldIgnorePromo,
          priceInCents: convertDollarsToCents(priceInDollars),
        },
      });
    } else {
      handleAddNewAdditionalPickUpRequestCharge({
        accessToken,
        organizationId,
        formFields: {
          name,
          description,
          enabled,
          shouldIgnorePromo,
          priceInCents: convertDollarsToCents(priceInDollars),
        },
      });
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(submitError)}
        autoHideDuration={6000}
        onClose={(reason) => {
          console.log("reason", reason);
          setSubmitError("");
        }}
      >
        <Alert
          onClose={() => {
            setSubmitError("");
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {submitError}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage("");
        }}
      >
        <Alert
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        loading={
          isAddNewAdditionalPickUpRequestChargeLoading ||
          isUpdateNewAdditionalPickUpRequestChargeLoading
        }
        onSubmit={handleSubmit}
        onCancel={handleClose}
        handleClose={handleClose}
        submitButtonLabel={editMode ? "Update" : "Create"}
        title={
          editMode
            ? "Update mandatory service call charge"
            : "Create mandatory service call charge"
        }
        submitButtonDisabled={submitButtonDisabled}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(nameErrors)}
                fullWidth
                placeholder="Charge name"
                label="Charge name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("name");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("name");
                }}
                variant={"outlined"}
              />
              {mapObjectValues(nameErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`additional-pickup-request-charge-name-errors-${index}`}
                  errorText={nameErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Description"
                label="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                onFocus={() => {
                  setFormFieldToFocused("description");
                }}
                onBlur={() => {
                  setFormFieldToBlurred("description");
                }}
                variant={"outlined"}
                multiline={true}
                rows={3}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={objectHasKeys(priceInDollarsErrors)}
                fullWidth
                placeholder="Price (In Dollars)"
                label="Price (In Dollars)"
                value={priceInDollars}
                onFocus={() => {
                  setFormFieldToFocused("priceInDollars");
                }}
                onChange={(e) => {
                  handleNumberInputChange(e, setPriceInDollars);
                }}
                onBlur={() => {
                  setFormFieldToBlurred("priceInDollars");
                  setPriceInDollars((prev: any) => Number(prev));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {mapObjectValues(priceInDollarsErrors, (key: any, index: any) => (
                <ErrorHelperText
                  key={`additional-pickup-request-charge-modal-price-in-dollars-errors-${index}`}
                  errorText={priceInDollarsErrors[key]}
                />
              ))}
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Grid item xs={12}>
              <Typography>Should apply to promo codes?</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight={shouldIgnorePromo ? 400 : 700}>
                No
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={shouldIgnorePromo}
                onChange={(event: any) => {
                  setShouldIgnorePromo(event.target.checked);
                }}
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={shouldIgnorePromo ? 700 : 400}>
                Yes
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Grid item>
              <Typography fontWeight={enabled ? 400 : 700}>Disabled</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={enabled}
                onChange={(event: any) => {
                  setEnabled(event.target.checked);
                }}
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={enabled ? 700 : 400}>Enabled</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AdditionalPickUpRequestChargeModal;
