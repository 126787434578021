import { GridSortModel } from "@mui/x-data-grid";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import { getCsvFilename } from "../helpers/formatters";
import {
  DriverVehicleInformationVehicleTypes,
  ServiceDriverTypes,
  VehicleClassTypes,
} from "../helpers/validationHelpers";
const getDrivers = async (
  accessToken: string,
  pageParam: number,
  search?: string,
  sort?: string,
  organizationId?: string
) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/drivers?page=${pageParam}${
          search ? `&search=${search}` : ""
        }${sort ? `&${sort}` : ""}${
          organizationId ? `&organizationId=${organizationId}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getDriverDetails = async (accessToken: string, userId?: string) => {
  try {
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${userId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getDriverServiceCallHistory = async ({
  accessToken,
  userId,
  page,
  sort,
}: {
  accessToken?: string;
  userId?: string;
  page: number;
  sort?: string;
}) => {
  try {
    console.log("sort in method", sort);
    if (accessToken || userId) {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/admin/driver/${userId}/service-call-history?page=${page}${
          sort ? `&${sort}` : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const getDriverEarnings = async ({
  accessToken,
  userId,
}: {
  accessToken?: string;
  userId?: string;
}) => {
  try {
    if (!userId) {
      return [];
    }
    if (accessToken) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${userId}/earnings`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.log("error", error);
  }
};

const updateDriverProfile = async ({
  accessToken,
  userId,
  driverFields,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${userId}/update-profile`,
      driverFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const createNewOrganizationDriver = async ({
  accessToken,
  organizationId,
  driverFields,
}: {
  accessToken: string;
  organizationId?: string;
  driverFields: {
    firstName: string;
    lastName: string;
    avatar: string;
    email: string;
    dateOfBirth: Date;
    phoneNumber: string;
    address: string;
    driverType: ServiceDriverTypes;
    city: string;
    preferredCity: string;
    province: string;
    postalCode: string;
    country: string;
    drivingLicenseNumber: string;
    drivingLicenseFrontUpload: string;
    drivingLicenseBackUpload: string;
    vehicleType: DriverVehicleInformationVehicleTypes;
    licensePlateNumber: string;
    makeOfCar: string;
    carColor: string;
    carYear: any;
    vehicleSideUpload: string;
    vehicleCargoBedUpload: string;
    vehicleProofOfOwnershipUpload: string;
    vehicleInsuranceUpload: string;
  };
}) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/create-new-organization-driver/${organizationId}`,
      driverFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const approveDriverProfileChanges = async ({ accessToken, userId }: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${userId}/approve-driver-profile-changes`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const updateDriverVehicleInformation = async ({
  accessToken,
  userId,
  driverFields,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${userId}/update-vehicle-information`,
      driverFields,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const toggleDriverApprovalStatus = async ({
  accessToken,
  userId,
  driverEvaluationCompleted,
}: any) => {
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/admin/driver/${userId}/update-driver-approval-status`,
      { driverEvaluationCompleted },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    console.log("error", error);
    throw error?.response?.data;
  }
};

const useDriverEarnings = ({
  accessToken,
  userId,
}: {
  accessToken?: string;
  userId?: string;
}) => {
  return useQuery({
    queryKey: ["driver-earnings", userId],
    queryFn: () => getDriverEarnings({ accessToken, userId }),
  });
};

// const useDriversInfiniteQuery = (accessToken: string) => {
//   return useInfiniteQuery({
//     queryKey: ["drivers"],
//     queryFn: ({ pageParam = 0 }) => getDrivers(accessToken, pageParam),
//     getNextPageParam: (lastPage, allPages) => lastPage?.nextPage,
//   });
// };

const useDrivers = ({
  accessToken,
  page = 0,
  search,
  sort,
  organizationId,
}: {
  accessToken: string;
  page: number;
  search?: string;
  sort?: GridSortModel;
  organizationId?: string;
}) => {
  return useQuery({
    queryKey: [
      "drivers",
      page,
      ...(search ? [search] : []),
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
      ...(organizationId ? [organizationId] : []),
    ],
    queryFn: () =>
      getDrivers(
        accessToken,
        page,
        search,
        sort && sort?.length > 0
          ? qs.stringify({ sortBy: sort[0] })
          : undefined,
        organizationId
      ),
    keepPreviousData: true,
  });
};

const useDriverServiceCallHistory = ({
  accessToken,
  userId,
  page = 0,
  sort,
}: {
  accessToken?: string;
  userId?: string;
  page: number;
  sort: GridSortModel;
}) => {
  console.log("sort in serviceCallhistory", sort);
  return useQuery({
    queryKey: [
      "driver-service-calls",
      userId,
      page,
      ...(sort && sort?.length > 0 ? [sort[0]] : []),
    ],
    queryFn: () =>
      getDriverServiceCallHistory({
        accessToken,
        userId,
        page,
        sort:
          sort && sort?.length > 0
            ? qs.stringify({ sortBy: sort[0] })
            : undefined,
      }),
  });
};

const getDriversCSV = async (accessToken: string, organizationId?: string) => {
  try {
    const { data, headers } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/drivers-csv${
        organizationId ? `?organizationId=${organizationId}` : ""
      }`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const filename = getCsvFilename(headers);
    return { data, filename };
  } catch (error) {
    console.log("error", error);
  }
};

const useDriverDetails = (accessToken: string, userId?: string) => {
  return useQuery({
    queryKey: ["driver", userId],
    queryFn: () => getDriverDetails(accessToken, userId),
  });
};

export {
  getDrivers,
  useDrivers,
  getDriverDetails,
  useDriverDetails,
  useDriverServiceCallHistory,
  useDriverEarnings,
  updateDriverProfile,
  updateDriverVehicleInformation,
  toggleDriverApprovalStatus,
  approveDriverProfileChanges,
  getDriversCSV,
  createNewOrganizationDriver,
};
