import { AxiosRequestHeaders } from "axios";
import moment from "moment";
import { VehicleClassTypes } from "./validationHelpers";

const roundToPrecision = (value: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
};

const renderDriverPrice = (price: number, tipAmountInCents = 0) => {
  return roundToPrecision((price * 0.75 + tipAmountInCents) / 100, 2);
};

const range = (start: number, stop: number, step: number = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const unCamelCase = (string: string) =>
  string.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

const calculateElapsedTime = (startDatetime: Date, endDatetime: Date) => {
  const start = moment(startDatetime);
  const end = moment(endDatetime);
  const calculatedDiff = end.diff(start, "minute");
  if (calculatedDiff > 0) {
    return `${calculatedDiff} Minutes`;
  } else {
    return "N/A";
  }
};

/**
 * Calculates the haversine distance between point A, and B.
 * @param {number[]} latlngA [lat, lng] point A
 * @param {number[]} latlngB [lat, lng] point B
 * @param {boolean} isMiles If we are using miles, else km.
 */
const calculateDistance = (
  [lat1, lon1]: any[],
  [lat2, lon2]: any[],
  isMiles = false
) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

const calculateTimeToDistanceInMinutes = (distance: number, kmPerHour = 30) => {
  return (distance / kmPerHour) * 60;
};

/**
 * Calculates fare estimate based on distance
 * @param {number} baseFee The base fee IN CENTS
 * @param {number} distance Distance in kilometers
 * @param {number} perKmRate Fee charged per kilometer IN CENTS
 * @param {number} perMinuteRate Fee charged per minute IN CENTS
 * @param {boolean} withTeam Whether or not the user selects a "Team" service call
 * @param {boolean} convertToDollars Converts final return value from cents to dollars
 * @returns {number} Fare estimate in dollars
 */
const calculateFareEstimate = (
  baseFee: number,
  distance: number,
  perKmRate: number,
  timeElapsedInMinutes: number,
  perMinuteRate: number,
  withTeam = false,
  vehicleClass: VehicleClassTypes,
  insideThresholdDelivery: boolean,
  insideThresholdDeliveryFee: number
) => {
  const vehicleClassRates: any = {
    [VehicleClassTypes.large]: 1,
    [VehicleClassTypes.medium]: 0.65,
    [VehicleClassTypes.small]: 0.4,
  };
  const distanceFee = roundToPrecision(distance * perKmRate, 10);
  const timeFee = roundToPrecision(timeElapsedInMinutes * perMinuteRate, 10);

  let initialCalculation = roundToPrecision(
    (baseFee + distanceFee + timeFee) * vehicleClassRates[vehicleClass],
    10
  );

  initialCalculation = insideThresholdDelivery
    ? roundToPrecision(initialCalculation + insideThresholdDeliveryFee, 10)
    : initialCalculation;

  let tax = roundToPrecision(initialCalculation * 0.13, 10);

  let finalCalculation = roundToPrecision(initialCalculation + tax, 10);

  let fare = withTeam
    ? roundToPrecision(finalCalculation * 1.5, 10)
    : finalCalculation;

  return roundToPrecision(fare / 100, 2);
};

const parseRatesForVehicleClass = ({
  vehicleRatesData,
  vehicleClass,
}: {
  vehicleRatesData: any;
  vehicleClass: VehicleClassTypes;
}) => {
  const filteredData = vehicleRatesData?.find(
    (vehicleRate: any) => vehicleRate?.vehicleClass === vehicleClass
  );

  return filteredData;
};

const parseChargesForSelectedPickUpRequestServices = ({
  userSelectableServices,
  selectedPickUpRequestServices,
}: {
  userSelectableServices: any;
  selectedPickUpRequestServices: any[];
}) => {
  if (
    selectedPickUpRequestServices?.length === 0 ||
    userSelectableServices?.length === 0
  ) {
    return 0;
  }

  const selectedServiceIds = selectedPickUpRequestServices?.map(
    (service) => service?.id
  );

  const filteredServices = userSelectableServices?.filter(
    (selectableService: any) =>
      selectedServiceIds.includes(selectableService?.id)
  );

  const totalFees = filteredServices.length
    ? filteredServices
        .map((fee: any) => Number(fee?.priceInCents))
        .reduce((prev: any, current: any) => Number(prev) + Number(current))
    : 0;
  return totalFees;
};

const calculateFareEstimateForVehicleClass = ({
  billingSettings,
  // baseFee,
  distance,
  // perKmRate,
  timeElapsedInMinutes,
  // perMinuteRate,
  withTeam = false,
  vehicleClass,
  insideThresholdDelivery,
  selectedPickUpRequestServices,
}: {
  billingSettings: any;

  distance: number;
  timeElapsedInMinutes: number;
  withTeam?: boolean;
  vehicleClass: VehicleClassTypes;
  insideThresholdDelivery: boolean;
  selectedPickUpRequestServices: {
    id: string;
    name: string;
    description: string;
    priceInCents: string;
  }[];
}) => {
  const vehicleRate = parseRatesForVehicleClass({
    vehicleRatesData: billingSettings?.vehicleRates,
    vehicleClass,
  });

  const selectedServicesFee = parseChargesForSelectedPickUpRequestServices({
    userSelectableServices: billingSettings?.userSelectableServices,
    selectedPickUpRequestServices,
  });

  console.log("vehicleRate", vehicleRate);
  const distanceFee = roundToPrecision(distance * vehicleRate?.perKmRate, 10);
  const timeFee = roundToPrecision(
    timeElapsedInMinutes * vehicleRate?.perMinuteRate,
    10
  );

  let initialCalculation = roundToPrecision(
    ((vehicleRate?.baseFee + distanceFee + timeFee) *
      vehicleRate?.rateInPercent) /
      100,
    10
  );

  // add additional fees if present
  initialCalculation = roundToPrecision(
    initialCalculation + Number(billingSettings?.totalAdditionalCharges),
    10
  );

  // add selected services fee
  initialCalculation = roundToPrecision(
    initialCalculation + Number(selectedServicesFee),
    10
  );

  initialCalculation = insideThresholdDelivery
    ? roundToPrecision(
        initialCalculation + billingSettings?.insideThresholdDeliveryFee,
        10
      )
    : initialCalculation;

  let tax = roundToPrecision(initialCalculation * 0.13, 10);

  let finalCalculation = roundToPrecision(initialCalculation + tax, 10);

  let fare = withTeam
    ? roundToPrecision(finalCalculation * 1.5, 10)
    : finalCalculation;

  return roundToPrecision(fare / 100, 2);
};

const convertToKilometers = (kilometers: any) => {
  return roundToPrecision(Number(kilometers) / 1000, 2);
};

const convertKilometersToMeters = (kilometers: any) => {
  return roundToPrecision(Number(kilometers) * 1000, 2);
};

const convertDollarsToCents = (priceInDollars: any) => {
  return roundToPrecision(Number(priceInDollars) * 100, 0);
};

const convertCentsToDollars = (priceInCents: any) => {
  return roundToPrecision(Number(priceInCents) / 100, 2);
};

const getCsvFilename = (headers: AxiosRequestHeaders) => {
  if (headers) {
    const contentDisposition = headers["content-disposition"];
    // @ts-ignore
    const filename = contentDisposition?.split("filename=")[1].split(".")[0];
    return filename;
  } else {
    return "";
  }
};

export {
  roundToPrecision,
  renderDriverPrice,
  range,
  unCamelCase,
  calculateElapsedTime,
  getCsvFilename,
  convertToKilometers,
  convertKilometersToMeters,
  convertDollarsToCents,
  calculateFareEstimate,
  calculateDistance,
  calculateTimeToDistanceInMinutes,
  convertCentsToDollars,
  calculateFareEstimateForVehicleClass,
};
