import { useEffect, useState } from "react";
import { useSocket } from "./useSocket";
import moment from "moment";
import _ from "lodash";

const useAdminNearbyDriversSocket = ({
  accessToken,
}: {
  accessToken: string;
}) => {
  const [nearbyDrivers, setNearbyDrivers] = useState<any[]>([]);
  const [socketEnabled, setSocketEnabled] = useState(false);

  //   const [mostRecentVoiceCall, setMostRecentVoiceCall] = useRecoilState(
  //     mostRecentVoiceCallState
  //   );
  const {
    socket: serviceCallsSocket,
    isConnected,
    setIsConnected,
    transport,
    setTransport,
    resetConnectionState,
  } = useSocket(`${process.env.REACT_APP_BACKEND_URL}/admin/nearby-drivers`, {
    autoConnect: false,
  });

  useEffect(() => {
    if (accessToken) {
      setSocketEnabled(true);
    } else {
      setSocketEnabled(false);
    }

    return () => {
      setSocketEnabled(false);
    };
  }, [accessToken]);

  useEffect(() => {
    if (socketEnabled) {
      serviceCallsSocket.auth = { accessToken };
      console.log("ATTEMPTING CONNECT --- ADMIN NEARBY DRIVERS SOCKET");
      serviceCallsSocket.connect();
    } else {
      console.log("DISCONNECTING --- ADMIN NEARBY DRIVERS SOCKET");
      serviceCallsSocket.disconnect();
      resetConnectionState();
    }

    return () => {
      console.log(
        "DISCONNECTING (Cleanup function) --- ADMIN NEARBY DRIVERS SOCKET"
      );
      serviceCallsSocket.disconnect();
      resetConnectionState();
    };
  }, [socketEnabled]);

  const sortNearbyDriversData = (data: any) => {
    const dataSorted = data.sort(
      (a: any, b: any) =>
        moment(b.locationLastUpdatedAt).valueOf() -
        moment(a.locationLastUpdatedAt).valueOf()
    );

    const duplicatesFiltered = _.uniqBy(dataSorted, "id");

    return duplicatesFiltered;
  };

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
      setTransport(serviceCallsSocket.io.engine.transport.name);

      serviceCallsSocket.io.engine.on("upgrade", (transport) => {
        setTransport(transport.name);
      });

      console.log("CONNECTION SUCCESSFUL --- ADMIN NEARBY DRIVERS SOCKET");
    };

    if (serviceCallsSocket.connected) {
      onConnect();
    }

    const onDisconnect = () => {
      console.log(
        "DISCONNECTING (onDisconnect) --- ADMIN NEARBY DRIVERS SOCKET"
      );
      setIsConnected(false);
      setTransport("N/A");
    };

    const onAvailableDrivers = (data: any) => {
      console.log("new available-drivers", data);
      const sortedData = sortNearbyDriversData(data);
      setNearbyDrivers(sortedData);
    };

    const onConnectionError = (error: any) => {
      console.log("CONNECTION ERROR --- ADMIN NEARBY DRIVERS SOCKET", error);
    };

    serviceCallsSocket.on("connect", onConnect);
    serviceCallsSocket.on("connect_error", onConnectionError);
    serviceCallsSocket.on("available-drivers", onAvailableDrivers);

    serviceCallsSocket.on("disconnect", onDisconnect);

    return () => {
      serviceCallsSocket.off("connect", onConnect);
      serviceCallsSocket.off("available-drivers", onAvailableDrivers);
      serviceCallsSocket.off("connect_error", onConnectionError);
      serviceCallsSocket.off("disconnect", onDisconnect);
    };
  }, []);

  const isDriverOnline = (driverId: string) => {
    return nearbyDrivers.some((driver) => driver.id === driverId);
  };

  return { nearbyDrivers, isDriverOnline };
};

export default useAdminNearbyDriversSocket;
