import { Grid, Typography } from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import {
  getAccessToken,
  isCurrentAdminFromRootOrganization,
} from "../../helpers/authHelpers";
import { useBillingSettings } from "../../hooks/useBillingSettings";
import DashboardStatCard from "./DashboardStatCard/DashboardStatCard";
import {
  getCumulativeOverviewXlsx,
  useAdminDashboardStats,
} from "../../hooks/useAdminDashboardStats";

import { useRecoilState } from "recoil";
import { userAuthState } from "../../atom";
import Button from "../../components/Button/Button";
import { useState } from "react";
import CumulativeOverviewSpreadsheetDownloadModal from "./CumulativeOverviewSpreadsheetDownloadModal/CumulativeOverviewSpreadsheetDownloadModal";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import useAdminNearbyDriversSocket from "../../sockets/useAdminNearbyDriversSocket";
const Dashboard = () => {
  // const accessToken = getAccessToken(user);
  const [reportDownloadModalOpen, setReportDownloadModalOpen] = useState(false);

  const [authDetails, setAuthDetails] = useRecoilState(userAuthState);

  const { data, isLoading } = useAdminDashboardStats({
    accessToken: authDetails?.accessToken,
    organizationId: isCurrentAdminFromRootOrganization(authDetails)
      ? undefined
      : authDetails?.organizationId,
  });

  const { nearbyDrivers } = useAdminNearbyDriversSocket({
    accessToken: authDetails?.accessToken,
  });

  const handleReportDownloadButtonClick = () => {
    setReportDownloadModalOpen(true);
  };

  return (
    <DashboardLayout loading={isLoading}>
      <CumulativeOverviewSpreadsheetDownloadModal
        open={reportDownloadModalOpen}
        setOpen={setReportDownloadModalOpen}
      />
      <Grid
        container
        flexWrap={"wrap"}
        flexDirection={"row"}
        paddingTop={3}
        columnGap={3}
      >
        <DashboardStatCard
          label={"Online Drivers"}
          statValue={nearbyDrivers?.length}
          borderColor="#FDB75B"
          iconName={"driver-1"}
          iconBackground={
            "transparent linear-gradient(90deg, #FDB75B 0%, #FEDC78 100%)"
          }
        />
        <DashboardStatCard
          label={"Total SCs"}
          statValue={data?.totalServiceCalls}
          borderColor="#40DA92"
          iconName={"contact"}
          iconBackground={
            "transparent linear-gradient(91deg, #40DA92 0%, #4AF393 100%)"
          }
        />
        <DashboardStatCard
          label={"Cancelled SCs"}
          statValue={data?.cancelledServiceCalls}
          borderColor="#FD6B52"
          iconName={"missed"}
          iconBackground={
            "transparent linear-gradient(90deg, #FD6B52 0%, #FEAC6F 100%)"
          }
        />
        <DashboardStatCard
          label={"Ongoing SCs"}
          statValue={data?.ongoingServiceCalls}
          borderColor="#54BBFD"
          iconName={"run"}
          iconBackground={
            "transparent linear-gradient(90deg, #54BBFD 0%, #52D1FD 100%)"
          }
        />
        <DashboardStatCard
          label={"Completed SCs"}
          statValue={data?.completedServiceCalls}
          borderColor="#4E42DE"
          iconName={"comment-1"}
          iconBackground={
            "transparent linear-gradient(90deg, #4E42DE 0%, #7E6EFF 100%)"
          }
        />
      </Grid>
      <Grid item>
        <Button
          icon={<SaveAltIcon sx={{ marginRight: 2 }} />}
          onClick={handleReportDownloadButtonClick}
        >
          Download Report
        </Button>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
